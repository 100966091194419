<template>
  <div class="u-p-lg u-box">
    <div class="u-flex-justify-between">
      <slot name="header" />
    </div>
    <div>
      <slot name="body" />
    </div>
    <div class="u-flex-justify-end u-mt-md">
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseModalComponent',
};
</script>
