import { apiService } from '../api'
import { each, map } from 'lodash';

const ENDPOINTS = {
  TRANSLATION: '/translation',
  CREATE: '/translation/save',
  UPDATE: '/translation/update',
  WORD: '/translation/word/'
}

class TranslationService {
  async getTranslations() {
    return await apiService.get(ENDPOINTS.TRANSLATION);
  }

  async save(newTranslation) {
    return await apiService.post(ENDPOINTS.CREATE, newTranslation);
  }

  async update(newTranslation) {
    return await apiService.post(ENDPOINTS.UPDATE, newTranslation);
  }

  async getByWordId(id) {
    return await apiService.get(ENDPOINTS.WORD + id);
  }

  formatData(word, languages) {
    if (!word) {
      return { translation: [] }
    } 

    const formatted = {
      word: word.key,
      wordId: word.id,
      sectionId: word.section.id,
      sectionTitle: word.section.title,
      section: word.section,
      languagesIds: [],
      translation: []
    }

    each(languages, language => {
      const translationForCurrentLanguage = 
        word.translation.find(translation => translation.language.id === language.id);

      if (translationForCurrentLanguage) {
        formatted.languagesIds.push(language.id);
        formatted.translation.push({
          translationId: translationForCurrentLanguage.id,
          phrase: translationForCurrentLanguage.phrase,
          languageId: language.id
        })
      } else {
        formatted.languagesIds.push(language.id)
        formatted.translation.push({
          translationId : null,
          phrase: '',
          languageId: language.id
        })
      }
    })

    return formatted;
  }

  formatDataForTable(response) {
    return map(response, data => {
      return {
        word: data,
        rowData: {
          section: data.section.title,
          phrase: data.key,
          translation: `${data.section.key}.${data.key}`,
        }
      }
    });
  }
}
export const translationService = new TranslationService()
