<template>
  <div class="c-list-translation-modal">
    <div class="c-list-translation-modal__header">
      <h3 class="c-list__title">
        Language list for key: {{ word.key }}
      </h3>
      <div class="c-close-btn" @click="close()">
        <md-icon>
          clear
        </md-icon>
      </div>
    </div>

    <md-table>
      <md-table-row>
        <md-table-head class="c-table-header-title">
          Language
        </md-table-head>
        <md-table-head class="c-table-header-title">
          Translation
        </md-table-head>
      </md-table-row>
      <md-table-row v-for="translation in translations" :key="translation.id">
        <md-table-cell>{{ translation.language.name }}</md-table-cell>
        <md-table-cell>{{ translation.phrase }}</md-table-cell>
      </md-table-row>
    </md-table>
    <md-divider class="u-width" />
  </div>
</template>

<script>
export default {
  name: "ListTranslationModal",
  props: {
    translations: {
      type: Array,
      required: true,
    },
    word: {
      required: true,
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>
