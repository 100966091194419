import { apiService } from '../api';
import { replacePathParams } from '../../helpers/urlHelpers';

const ENDPOINTS = {
  GET_ONE: '/betshops/:betshopId',
  UPDATE_TICKET_TEMPLATE:
    '/betshops/:betshopId/ticket-templates/:ticketTemplateId',
  GENERATE_LICENCE_KEY: '/licence-key',
  DELETE_LICENCE_KEY: '/licence-key/:id',
  FETCH_APP_LOGS: '/licence-key/betshop-logs/:licenceKeyId',
  GET_LICENCE_KEYS: '/betshops/:betshopId/licence-keys',
  PING_APP: '/licence-key/betshop-ping/:id',
  FORCE_UPDATE: '/licence-key/force-update',
};

class BetshopService {
  async get(betshopId) {
    return await apiService.get(replacePathParams(ENDPOINTS.GET_ONE, { betshopId }));
  }

  async generateNewLicenceKey(betshopId) {
    return await apiService.post(ENDPOINTS.GENERATE_LICENCE_KEY,{ betshopId : betshopId , stickerNo : null });
  }

  async generateNewLicenceKeyWithStickerNo(betshopId , stickerNo) {
    return await apiService.post(ENDPOINTS.GENERATE_LICENCE_KEY,{ betshopId : betshopId , stickerNo : stickerNo});
  }

  async updateStickerNoForLicenseKey(betshopId ,licenceKeyId , stickerNo) {
    return await apiService.put(ENDPOINTS.GENERATE_LICENCE_KEY,{ betshopId : betshopId , licenceKeyId : licenceKeyId , stickerNo : stickerNo});
  }

  async deleteLicenceKey(id) {
    return await apiService.delete(replacePathParams(ENDPOINTS.DELETE_LICENCE_KEY, { id }));
  }

  async getLicenceKeys(betshopId, page = 0, limit = 20, shouldPaginate = true) {
    return await apiService.get(
      replacePathParams(ENDPOINTS.GET_LICENCE_KEYS, { betshopId }) +
      `?page=${page}&limit=${limit}&shouldPaginate=${shouldPaginate}`
    );
  }

  async fetchApplicationLogs(licenceKeyId) {
    return await apiService.put(replacePathParams(ENDPOINTS.FETCH_APP_LOGS, { licenceKeyId }));
  }

  async pingApp(id) {
    return await apiService.put(replacePathParams(ENDPOINTS.PING_APP, { id }));
  }

  async forceUpdate(licenceKey,  betshopId) {
    return await apiService.put(ENDPOINTS.FORCE_UPDATE, { licenceKey,  betshopId });
  }
}

export const betshopService = new BetshopService();
