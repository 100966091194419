import { apiService } from '../api'
import {  map } from 'lodash';

const ENDPOINTS = {
  CREATE: '/configurations/page/save',
  GET: '/configurations/pages',
  UPDATE: '/configurations/page/update',
  DELETE: '/configurations/page'
}

class ConfigurationsPageService {
  async create(data) {
    return await apiService.post(ENDPOINTS.CREATE, data)
  }

  async update(data) {
    return await apiService.put(ENDPOINTS.UPDATE, data)
  }

  async getAll(page, limit, shouldPaginate) {
    return await apiService.get(`${ENDPOINTS.GET}?page=${page}&limit=${limit}&shouldPaginate=${shouldPaginate}`);
  }

  async delete(id) {
    return await apiService.delete(`${ENDPOINTS.DELETE}/${id}`);
  }

  formatDataForTable(response) {
    return map(response, data => {
      return {
        word: {
          key: data.key,
          title: data.title,
        },
        rowData: {
          key: data.key,
          title: data.title,
        }
      }
    });
  }

  formatData(selectedPage) {
    if (selectedPage) {
      return selectedPage;
    }

    return { key: null, title: null };
  }

  prepareFromResponse(items) {
    const pages = [];

    items.forEach(page => {
      pages.push({
        rowData: {
          key: page.key,
          title: page.title,
        },
        word: page,
      });
    });

    return pages;
  }
}

export const configurationsPageService = new ConfigurationsPageService()
