<template>
  <div>
    <div class="c-form-wrapper--small u-overflow-auto">
      <div class="c-form">
        <div class="c-form__row">
          <div class="c-form-group u-flex-row-center">
            <div class="c-form-group__action">
              <span v-if="!allMarketLanguages.length">No languages found for this market.</span>
              <md-field class="c-select-field c-select">
                <label for="languages">Languages</label>
                <md-select
                  id="languages"
                  :value="selectedLanguage"
                  name="languages"
                  :disabled="!allMarketLanguages.length"
                  @md-selected="handleLanguageChange"
                >
                  <md-option
                    v-for="(lang, index) in allMarketLanguages"
                    :key="index"
                    :value="lang"
                  >
                    {{ lang }}
                  </md-option>
                </md-select>
              </md-field>

              <div v-if="selectedLanguage && type === 'betshop'" class="c-form__checkbox">
                <md-checkbox v-model="additionalTemplateDescriptionEnabled" :disabled="!hasWritePermision" class="c-form-checkbox__checkmark" /><span>Enable Configuration</span>
              </div>

              <span v-if="!selectedLanguage">Select languages to start editing.</span>
              <transition v-if="selectedLanguage">
                <div class="c-add-template">
                  <div class="c-add-template__ctn">
                    <div class="c-code-editor-group">
                      <div class="c-code-editor-wrapper u-mt-md">
                        <label>HTML code</label>
                        <prism-editor
                          v-model="template.description"
                          :highlight="highlighter"
                          class="c-code-editor c-code-editor--lg"
                        />
                      </div>
                      <div class="c-code-editor-wrapper u-mt-md">
                        <label>CSS code</label>
                        <prism-editor
                          v-model="template.css"
                          :highlight="highlighter"
                          class="c-code-editor c-code-editor--lg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="u-flex-justify-end u-mr-md u-mb-md">
      <generic-button
        class="u-ml-auto"
        variation="red"
        :disabled="!hasWritePermision || !selectedLanguage"
        @click="updateCurrentAdditionalTemplateFooterDescription"
      >
        Update
      </generic-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { hasWritePermission } from '../../../helpers/permission';
import find from 'lodash/find';
import GenericButton from "../../../shared/buttons/GenericButton";
import ConfirmationModalComponent from '../../../shared/confirmation-modal/ConfirmationModalComponent';
import { highlight, languages } from "prismjs/components/prism-core";
import { PrismEditor } from "vue-prism-editor";

export default {
  name: "AdditionalTemplateFooter",
  components: {
    GenericButton,
    PrismEditor,
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      initAdditionalTemplateFooterDescriptionHtml: null,
      additionalTemplateFooterDescriptionHtml: null,
      allMarketLanguages: [],
      selectedType: "market",
      changedLanguage: null,
      selectedLanguage: '',
      previousLanguage: '',
      canHandleLanguageChange: true,
      initLanguageChanged: false,
      initFooterLanguageChanged: false,
      isFooterLanguageChanged: false,
      previousFooterLanguage: '',
      submitedChanges: false,
      hasWritePermision: false,
      additionalTemplateDescriptionEnabled: false,
      template: {
        description: '',
        css: ''
      },
    };
  },
  computed: {
    ...mapGetters("company", [
      "getDefaultMarketLanguage",
      "getAdditionalMarketLanguages",
      "getAllBetshopAdditionalTemplateDescriptions",
      "getAllMarketAdditionalTemplateDescriptions"
    ]),

    shouldShowEnabledBox() {
      return this.type === 'betshop' && this.selectedLanguage;
    }
  },

  beforeRouteLeave(to, from, next) {
    if (this.checkForChanges()) {
      this.openConfirmationModal(() => { next() }, () => {});
      return;
    }
    return next();
  },

  async created() {
    this.allMarketLanguages = [
      this.getDefaultMarketLanguage,
      ...this.getAdditionalMarketLanguages,
    ];
    this.checkPermissions();
    await this.fetchAllAdditionalTemplateDescriptions();
  },
  methods: {
    ...mapActions("company", [
      "saveMarketAdditionalTemplateFooterDescription",
      "saveBetshopAdditionalTemplateFooterDescription",
      "fetchAllBetshopAdditionalTemplateDescription",
      "fetchAllMarketAdditionalTemplateDescription"
    ]),

    ...mapActions("response", ["handleSuccess", "handleError"]),
    ...mapGetters('account', ['user', 'userHierarchyWriteableSections']),

    async handleLanguageChange(language) {
      if (!this.canHandleLanguageChange && this.previousLanguage !== this.changedLanguage) {
        return this.canHandleLanguageChange = true;
      }

      this.canHandleLanguageChange = false;
      this.changedLanguage = language;
      
      if (!this.initFooterLanguageChanged) {
        return this.handleChangeLanguage();
      }

      this.openConfirmationModal(this.handleChangeLanguage, this.onClose);
    },

    async fetchAllAdditionalTemplateDescriptions() {
      this.type === 'betshop' ? 
      await this.fetchAllBetshopAdditionalTemplateDescription({
        betshopId: this.$route.params.betshopId,
      }) :
      await this.fetchAllMarketAdditionalTemplateDescription({
        marketId: this.$route.params.marketId,
      });
    },

    async updateCurrentAdditionalTemplateFooterDescription() {
      const dataForSubmitting = {
        language: this.selectedLanguage,
        additionalTemplateFooter: this.template.description,
        additionalTemplateFooterCss: this.template.css || null,
      };

      this.type === this.selectedType
        ? await this.saveMarketAdditionalTemplateFooterDescription({
            marketId: this.$route.params.marketId,
            ...dataForSubmitting,
          })
        : await this.saveBetshopAdditionalTemplateFooterDescription({
            betshopId: this.$route.params.betshopId,
            enabled: this.additionalTemplateDescriptionEnabled,
            ...dataForSubmitting,
          });

      this.previousFooterLanguage = '';
      this.initFooterLanguageChanged = false;
      this.$emit('canChangeTab', false);
      await this.fetchAllAdditionalTemplateDescriptions();
    },

    onEditorChange({ html }) {
      this.initFooterLanguageChanged = this.checkForChanges();
      this.previousFooterLanguage = this.selectedLanguage;
      this.$emit('canChangeTab', this.initFooterLanguageChanged);
      this.additionalTemplateFooterDescriptionHtml = html;
    },

    checkForChanges() {
      return (this.previousFooterLanguage === this.selectedLanguage) || (this.initAdditionalTemplateFooterDescriptionHtml === this.additionalTemplateFooterDescriptionHtml);
    },

    handleBetShopLanguageChange(language) {
      this.selectedLanguage = language;

      const findBetshopAdditionalTemplateDescription = find(this.getAllBetshopAdditionalTemplateDescriptions, {
        betshopId: Number(this.$route.params.betshopId),
        language: this.selectedLanguage,
      });

      this.updateCurrentEditor(findBetshopAdditionalTemplateDescription);
    },

    updateCurrentEditor(additionalTemplateSectionData) {
      this.additionalTemplateDescriptionEnabled = additionalTemplateSectionData && additionalTemplateSectionData.enabled;

      if (!additionalTemplateSectionData) {
        this.template.description = "";
        this.template.css = "";

        return;
      }

      this.template.description = additionalTemplateSectionData.additionalTemplateFooter;
      this.template.css = additionalTemplateSectionData.additionalTemplateFooterCss;
    },

    async handleMarketLanguageChange(language) {
      this.selectedLanguage = language;

      const findMarketAdditionalTemplateDescription = find(this.getAllMarketAdditionalTemplateDescriptions, {
        marketId: Number(this.$route.params.marketId),
        language: this.selectedLanguage,
      });

      this.updateCurrentEditor(findMarketAdditionalTemplateDescription);
    },

    async handleChangeLanguage() {
      this.selectedLanguage = this.changedLanguage;
      this.previousLanguage = this.changedLanguage;

      this.type === this.selectedType
        ? await this.handleMarketLanguageChange(this.changedLanguage)
        : await this.handleBetShopLanguageChange(this.changedLanguage);
      
      this.$emit('canChangeTab', true);
    },

    onClose() {
      this.selectedLanguage = this.changedLanguage;
      setTimeout(() => {
        this.selectedLanguage = this.previousLanguage;
      });
    },

    openConfirmationModal(handleChangeLanguage, onClose) {
      this.$modal.show(
        ConfirmationModalComponent,
        {
          confirmationMsg: `
            Your changes will be lost,
            are you sure you want to continue?`,
          onAccept: handleChangeLanguage,
          onClose: onClose,
        },
        { height: "auto", scrollable: false, width: "400px" }
      )
    },

    canShowConfirmationModal() {
      if (!this.initFooterLanguageChanged) {
        return;
      }

      this.openConfirmationModal(this.handleChangeLanguage, this.onClose);
    },

    checkPermissions() {
      this.hasWritePermision = hasWritePermission(
        this.user(),
        this.userHierarchyWriteableSections(),
        `additional-template-footer-${this.type}`
      );
    },

    highlighter(code) {
      return highlight(code, languages.js);
    },
  },
};
</script>
