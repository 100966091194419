import includes from 'lodash/includes';
import each from "lodash/each";

export const hasWritePermission = (user, writeableSections, section) => {
  return user.role === 1 ? 
    true : 
    includes(writeableSections, section);
}

export const isTabOrSubTabAvailable = (tab, userHierarchyAllowedTabs, betshopOrMarket) => {
  const isTabAvailable = includes(userHierarchyAllowedTabs, tab.section + betshopOrMarket);

  if (isTabAvailable) {
    return isTabAvailable;
  }

  let isSabTabAvailable = false;

  if (tab.subItems.length === 0) {
    return isSabTabAvailable;
  }

  each(tab.subItems, subItem => {
    if (includes(userHierarchyAllowedTabs, subItem.tab + betshopOrMarket)) {
      isSabTabAvailable = true;

      return false;
    }
  })

  return isSabTabAvailable;
}