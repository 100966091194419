<template>
  <base-modal-component>
    <template v-slot:header>
      <h4 class="md-title u-typography-color">
        {{ displayUpdateOrCreate }} Language
      </h4>
    </template>
    <template v-slot:body>
      <p
        v-if="error && error.messages"
        class="md-error"
      >
        {{ error.messages }}
      </p>
      <form
        class="u-width"
        novalidate
        @submit.prevent="validateForm"
      >
        <md-field :class="getValidationClass($v.languageData.name)">
          <label for="name">Name</label>
          <md-input
            v-model="languageData.name"
            v-focus
            class="md-input"
            name="name"
            type="name"
          />
          <span
            v-if="!$v.languageData.name.required"
            class="md-error"
          >The name is required</span>
        </md-field>
        <error-response-messages :field-name="'name'" />
        <md-field :class="getValidationClass($v.languageData.code)">
          <label>Code</label>
          <md-input
            v-model="languageData.code"
            class="md-input"
            type="code"
          />
          <span
            v-if="!$v.languageData.code.required"
            class="md-error"
          >The code is required</span>
          <span
            v-if="$v.languageData.code.required && !$v.languageData.code.emptySpace"
            class="md-error"
          >The code cannot have empty spaces</span>
        </md-field>
        <error-response-messages :field-name="'code'" />
      </form>
    </template>
    <template v-slot:footer>
      <div class="u-mr-xs">
        <generic-button
          variation="grey"
          @click="close"
        >
          Close
        </generic-button>
      </div>
      <generic-button
        variation="red"
        @click="validateForm"
      >
        {{ displayUpdateOrCreate }}
      </generic-button>
    </template>
  </base-modal-component>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationBaseMixin } from '../../../../../mixins/ValidationBaseMixin'
import GenericButton from "../../../../../shared/buttons/GenericButton";
import ErrorResponseMessages from "../../../../../shared/error-response-messages/ErrorResponseMessages";
import { languageService } from "../../../../../services/language";
import { responseService } from "../../../../../services/response";
import { checkEmptySpace } from "../../../../../const/validator";
import BaseModalComponent from '../../../../../shared/modal/BaseModalComponent'
import { required } from "vuelidate/lib/validators";

export default {
  name: 'Index',
  components: {
    GenericButton,
    ErrorResponseMessages,
    BaseModalComponent
  },
  mixins: [validationBaseMixin],
  props: {
    fetchCurrentPage: {
      type: Function,
      required: true
    },
    isUpdate: {
      default: false
    },
    languageData: {
      required: true
    },
    currentPage: {
      type: Number,
      required: true
    }
  },
  data() {
    return {
      messages: {
        error: null,
        success: null
      }
    };
  },
  computed: {
    ...mapGetters("response", ["success", "error"]),
    displayUpdateOrCreate() {
      return this.isUpdate ? 'Update' : 'Create';
    }
  },
  validations: {
    languageData: {
      code: {
        required,
        checkEmptySpace
      },
      name: {
        required
      }
    }
  },
  methods: {
    ...mapActions("response", ["handleSuccess", "handleError", "resetError"]),

    async createOrEditLanguage() {
      try {
        await this.createOrUpdate();
        this.resetError();
        this.handleSuccess({ message: `Language ${this.displayUpdateOrCreate}!` });
        this.fetchCurrentPage(this.currentPage);
        this.close();
      } catch (err) {
        this.handleError(responseService.getErrorMessage(err.data.message));
      }
    },

    async createOrUpdate() {
      return await this.isUpdate ?
        languageService.update(this.languageData) :
        languageService.create(this.languageData);
    },

    close() {
      this.$emit("close");
      this.resetError();
    },

    validateForm() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.createOrEditLanguage();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>