import { wingoSettingsService } from '../../services/wingo-settings'

function getInitialState() {
    return {
      wingoApiUrl: '',
      wingoApiKey: '',
    }
  }
  
  const state = getInitialState()
  
  const getters = {
    getApiKey: state =>  state.wingoApiUrl,
    getApiUrl: state =>  state.wingoApiKey,
  }

  const actions = {
    async fetchWingoSettings({ commit, dispatch }) {
      try {
        dispatch('response/setGlobalError', null, { root: true });
        const settings = await wingoSettingsService.fetchWingoSettings()
        commit('SET_WINGO_SETTINGS', settings)
      } catch (error) {
        dispatch('response/setGlobalError', 'Problem with WSBO', { root: true });
      }
    },
  }
  
  const mutations = {
    SET_WINGO_SETTINGS(state, {data}) {
        state.wingoApiUrl = data.wingoApiUrl
        state.wingoApiKey = data.wingoApiKey
      },
  }
  
  export const wingoSettings = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
  }
  