import { apiService } from "../../api";
import { replacePathParams } from "../../../helpers/urlHelpers";

const ENDPOINTS = {
  CREATE: '/shift-template',
  UPDATE: '/shift-template/:id',
  GET_ONE: '/shift-template/:id',
  GET_ALL: '/shift-template?shiftType=:shiftType',
  GET_PAGINATED: '/shift-template?page=:page&limit=:limit&shiftType=:shiftType',
  DELETE: '/shift-template/:id'
};

class ShiftTemplateService {
  async create(data) {
    return await apiService.post(ENDPOINTS.CREATE, data);
  }

  async update(id, data) {
    return await apiService.put(replacePathParams(ENDPOINTS.UPDATE, { id }), data);
  }

  async getShiftTemplate(id) {
    return await apiService.get(replacePathParams(ENDPOINTS.GET_ONE, { id }));
  }

  async getAllShiftTemplates(shiftType) {
    return await apiService.get(replacePathParams(ENDPOINTS.GET_ALL, {shiftType}));
  }

  async getPaginatedShiftTemplates(page, limit, shiftType) {
    return await apiService.get(replacePathParams(ENDPOINTS.GET_PAGINATED, {
      page, limit, shiftType
    }));
  }

  async delete(id) {
    return await apiService.delete(replacePathParams(ENDPOINTS.DELETE, { id }));
  }
}

export const shiftTemplateService = new ShiftTemplateService();
