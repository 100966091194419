<template>
  <md-field class="c-multiselect">
    <label>{{ label }}</label>
    <md-select
      :id="id"
      v-model="selectedData"
      :name="name"
      multiple
    >
      <md-option
        v-for="item in selectionData"
        :key="item.id"
        :disabled="disabled"
        :value="item.key"
      >
        {{ item.title }}
      </md-option>
    </md-select>
  </md-field>
</template>

<script>
  export default {
    name: 'MultipleSelect',

    props: {
      selectionData: {
      required: false,
        default: () => {
          return []
        },
        type: Array
      },
      defaultValue: {
        required: false,
        default: () => {
          return []
        },
        type: Array
      },
      label: {
        required: false,
        default: '',
        type: String
      },
      name: {
        required: false,
        default: '',
        type: String
      },
      id: {
        required: false,
        default: '',
        type: String
      },
      selectedText: {
        required: false,
        default: '',
        type: String
      },
      disabled: {
        required: false,
        default: false,
      }
    },

    data() {
      return {
        selectedData: []
      }
    },

    watch: {
      selectedData() {
        this.$emit('clicked', this.selectedData)
      },
      defaultValue() {
        this.selectedData = this.defaultValue;
      },
    },

    created() {
      this.selectedData = this.defaultValue;
    },
  }
</script>

<style lang="scss" scoped>
  .md-field {
    max-width: 300px;
  }
</style>