import { apiService } from '../api'

const ENDPOINTS = {
  WORDS: '/word',
}

class WordsService {
  async getAll(page, limit, section, term) {
    return await apiService.get(ENDPOINTS.WORDS + `?page=${page}&limit=${limit}&section=${section}&term=${term}`);
  }

  async delete(id) {
    return await apiService.delete(ENDPOINTS.WORDS + `/${id}`);
  }
}
export const wordService = new WordsService()
