<template>
  <div class="l-main">
    <div class="l-main__header u-box">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a
            class="c-breadcrumbs__link"
            @click="goToCompanies"
          >
            <md-icon>home</md-icon>
          </a>
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link">Sections</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <div class="c-table-wrapper">
        <base-table-component
          :prop-data="sections"
        >
          <template v-slot:default="slotProps">
            <span class="c-table__btn-wrap">
              <generic-button
                v-if="userCanEdit"
                icon
                transparent
                text-inherit
                @click="createOrUpdateUser(slotProps.selected.word)"
              >
                <md-tooltip
                  md-direction="top"
                >
                  Edit
                </md-tooltip>
                <md-icon>edit</md-icon>
              </generic-button>
            </span>
            <span class="c-table__btn-wrap">
              <generic-button
                v-if="userCanEdit"
                icon
                transparent
                text-inherit
                @click="openDeleteSectionModal(slotProps.selected.word)"
              >
                <md-tooltip md-direction="top">
                  Delete
                </md-tooltip>
                <md-icon>delete</md-icon>
              </generic-button>
            </span>
          </template>
        </base-table-component>
      </div>
      <div class="add-button u-color-white">
        <generic-button
          v-if="userCanEdit"
          text-inherit
          transparent
          fab
          variation="red"
          @click="createOrUpdateUser()"
        >
          <md-icon>add</md-icon>
        </generic-button>
      </div>
      <paginate
        v-if="shouldShowPagination"
        :page-count="paginationData.pageCount"
        :page-range="limitPerPage"
        :force-page="currentPage"
        :click-handler="fetchSection"
        :container-class="'c-pagination'"
        :prev-link-class="'md-button prev md-elevation-1'"
        :next-link-class="'md-button next md-elevation-1'"
        :page-link-class="'md-button md-elevation-1'"
        :active-class="'active'"
        :disabled-class="'disabled'"
      />
    </div>
  </div>
</template>

<script>
import SectionModal from './components/create-update/index';
import { sectionService } from '../../../services/section/index';
import { paginationService } from '../../../services/pagination/index';
import BaseTableComponent from '../../../shared/table/BaseTableComponent';
import DeleteModalComponent from '../../../shared/delete-modal/DeleteModalComponent';
import GenericButton from "../../../shared/buttons/GenericButton";
import { mapGetters } from 'vuex';

export default {
  components: {
    BaseTableComponent,
    GenericButton
  },
  data() {
    return {
      sections: [],
      paginationData: {},
      limitPerPage: 10,
      currentPage: 1
    }
  },
  computed: {
    ...mapGetters('account', ['userWriteableSections', 'userAllowedRoutes', 'user']),
    shouldShowPagination() {
      return this.paginationData.totalItems > this.limitPerPage
    },
    userCanEdit() {
      return !!this.userWriteableSections.includes('sections');
    }
  },
  created () {
    this.fetchSection();
  },
  methods: {
    fetchSection(currentPage = 1, limit = 10) {
      this.currentPage = currentPage;
      sectionService.getAllSections(currentPage, limit, false).then(response => {
        if (response.data.items.length === 0 && this.currentPage > 1) {
          return this.fetchSection(this.currentPage - 1);
        }

        this.paginationData = paginationService.getPaginationData(response.data);
        this.sections = sectionService.prepareSectionFromResponse(response.data.items);
      });
    },

    goToCompanies() {
      if (this.user.role === 1) {
        return this.$router.push({ name: 'companies' });
      }

      if (this.$route.name === this.userAllowedRoutes[0]) {
        return;
      }
      
      this.userAllowedRoutes.includes('companies') ? this.$router.push({ name: 'companies' }) : this.$router.push({ name: this.userAllowedRoutes[0] });
    },

    openDeleteSectionModal(selected) {
      this.$modal.show(
        DeleteModalComponent,
        {
          deleteMsg: `key "${selected.key}`,
          propData: selected.id,
          deleteFunction: this.deleteSection,
          getDataFunction: this.fetchSection,
          pageData: {currentPage: this.currentPage, limit: this.limitPerPage }
        },
        { height: "auto", scrollable: false, width: "400px" }
      )
    },

    createOrUpdateUser(selected) {
      this.$modal.show(
        SectionModal,
        {
          sectionData: sectionService.formatSectionData(selected),
          currentPage: this.currentPage,
          fetchSection: this.fetchSection
        },
        { height: "auto", scrollable: true }
      );
    },

    deleteSection(id) {
      return sectionService.deleteSection(id);
    }
  }
};
</script>

<style lang="scss" scoped>
</style>