import { replacePathParams } from '../../helpers/urlHelpers';
import { apiService } from '../api'

const ENDPOINTS = {
  CREATE: '/shift-keys/save',
  GET: '/shift-keys',
  UPDATE: '/shift-keys/:id',
  DELETE: '/shift-keys/:id'
}

class ShiftService {
  async create(data) {
    return await apiService.post(ENDPOINTS.CREATE, data)
  }

  async update(data) {
    return await apiService.put(replacePathParams(ENDPOINTS.UPDATE, { id: data.id }), data)
  }

  async fetchAllShiftKeys(page, limit, type) {
    return await apiService.get(`${ENDPOINTS.GET}?page=${page}&limit=${limit}&type=${type}`);
  } 

  async deleteShiftKey(id) {
    return await apiService.delete(replacePathParams(ENDPOINTS.DELETE, { id }))
  }
}

export const shiftService = new ShiftService()
