<template>
  <div class="l-main">
    <div class="l-main__header u-box">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a class="c-breadcrumbs__link">
            <md-icon>home</md-icon>
          </a>
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link">Additional template keys</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <div class="c-table-wrapper">
        <base-table-component :prop-data="keysToShow">
          <template v-slot:default="slotProps">
            <span class="c-table__btn-wrap">
              <generic-button
                v-if="userCanEdit"
                icon
                transparent
                text-inherit
                @click="createOrUpdateShiftKey(slotProps.selected.rowData)"
              >
                <md-tooltip md-direction="top">
                  Edit
                </md-tooltip>
                <md-icon>edit</md-icon>
              </generic-button>
            </span>
            <span class="c-table__btn-wrap">
              <generic-button
                v-if="userCanEdit"
                icon
                transparent
                text-inherit
                @click="openDeleteSectionModal(slotProps.selected.rowData)"
              >
                <md-tooltip md-direction="top">
                  Delete
                </md-tooltip>
                <md-icon>delete</md-icon>
              </generic-button>
            </span>
          </template>
        </base-table-component>
      </div>
      <div class="add-button u-color-white">
        <generic-button
          v-if="userCanEdit"
          text-inherit
          transparent
          fab
          variation="red"
          @click="createOrUpdateShiftKey()"
        >
          <md-icon>add</md-icon>
        </generic-button>
      </div>
      <paginate
        v-if="shouldShowPagination"
        :page-count="getAdditionalTemplateKeysPaginationData.pageCount"
        :page-range="paginationData.limitPerPage"
        :force-page="paginationData.currentPage"
        :click-handler="getDataPerPage"
        :container-class="'c-pagination'"
        :prev-link-class="'md-button prev md-elevation-1'"
        :next-link-class="'md-button next md-elevation-1'"
        :page-link-class="'md-button md-elevation-1'"
        :active-class="'active'"
        :disabled-class="'disabled'"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GenericButton from "../../../shared/buttons/GenericButton";
import BaseTableComponent from "../../../shared/table/BaseTableComponent.vue";
import CreateUpdateModal from "./components/create-update/index";
import DeleteModalComponent from "../../../shared/delete-modal/DeleteModalComponent.vue";
import { PAGE_INFO } from '../../../const/pagination';
import { additionalTemplateKeysService } from '../../../services/additional-template-keys';

export default {
  name: "AdditionalTemplateKeys",
  components: {
    GenericButton,
    BaseTableComponent,
  },

  data() {
    return {
      paginationData: {
        limitPerPage: PAGE_INFO.LIMIT_PER_PAGE,
        currentPage: PAGE_INFO.INITIAL_PAGE,
      },
    };
  },
  
  computed: {
    ...mapGetters("additionalTemplate", ["getAllAdditionalTemplateKeys", "getAdditionalTemplateKeysPaginationData"]),
    ...mapGetters('account', ['userWriteableSections']),
    keysToShow: function () {
      return this.getAllAdditionalTemplateKeys.map(({ id, value }) => ({
        rowData: {
          id,
          name: value || `Additional template template ${id}`,
        },
      }));
    },
    shouldShowPagination() {
      return this.getAdditionalTemplateKeysPaginationData.totalItems > this.paginationData.limitPerPage;
    },

    userCanEdit() {
      return !!this.userWriteableSections.includes('additional-template-keys');
    }
  },

  async created() {
    await this.fetchAdditionalTemplateKeys();
  },

  methods: {
    ...mapActions("additionalTemplate", ["fetchAllAdditionalTemplateKeys"]),

    createOrUpdateShiftKey(data = {}) {
      this.$modal.show(
        CreateUpdateModal,
        {
          data,
          paginationData: this.paginationData
        },
        { height: "auto", scrollable: true }
      );
    },

    openDeleteSectionModal(selected) {
      if (this.getAdditionalTemplateKeysPaginationData.totalItems % 10 === 1) {
        this.paginationData = {
          limitPerPage: 10,
          currentPage: this.paginationData.currentPage - 1 || 0
        }
      }

      this.$modal.show(
        DeleteModalComponent,
        {
          deleteMsg: `key "${selected.name}`,
          deleteMsgAlert: `It will be deleted on all other configurations.`,
          propData: selected.id,
          deleteFunction: this.deleteAdditionalTemplateKey,
          getDataFunction: this.fetchAdditionalTemplateKeys,
          pageData: this.paginationData
        },
        { height: "auto", scrollable: false, width: "400px" }
      )
    },

    deleteAdditionalTemplateKey(id) {
      return additionalTemplateKeysService.deleteAdditionalTemplateKey(id);
    },

    async fetchAdditionalTemplateKeys() {
      await this.fetchAllAdditionalTemplateKeys({
        currentPage: this.paginationData.currentPage,
        limit: this.paginationData.limitPerPage,
      });
    },

    getDataPerPage(
      currentPage = 1,
      limit = PAGE_INFO.LIMIT_PER_PAGE,
    ) {
      this.paginationData.currentPage = currentPage;
      this.fetchAdditionalTemplateKeys(currentPage, limit);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>