import { apiService } from '../api'

const ENDPOINTS = {
  CREATE: '/hq-settings/save',
  GET: '/hq-settings',
}

class HQSettingsService {
  async create(data) {
    return await apiService.post(ENDPOINTS.CREATE, data)
  }

  async fetchHQSettings() {
    return await apiService.get(ENDPOINTS.GET);
  } 
}

export const HQSettings = new HQSettingsService()
