import { HQSettings } from '../../services/hq-settings';

function getInitialState() {
    return {
      hqApiKey: '',
      hqApiUrl: '',
      hqSecretKey: ''
    }
  }
  
  const state = getInitialState()
  
  const getters = {
    getApiKey: state =>  state.hqApiKey,
    getApiUrl: state =>  state.hqApiUrl,
    getSecretKey: state =>  state.hqSecretKey
  }

  const actions = {
    async fetchHQSettings({ commit, dispatch }) {
      try {
        dispatch('response/setGlobalError', null, { root: true });
        const settings = await HQSettings.fetchHQSettings()
        commit('SET_HQ_SETTINGS', settings)
      } catch (error) {
        dispatch('response/setGlobalError', 'Problem with WSBO', { root: true });
      }
    },
  }
  
  const mutations = {
    SET_HQ_SETTINGS(state, {data}) {
        state.hqApiKey = data.hqApiKey
        state.hqApiUrl = data.hqApiUrl
        state.hqSecretKey = data.hqSecretKey
      },
  }
  
  export const hqSettings = {
    namespaced: true,
    state,
    actions,
    mutations,
    getters
  }
  