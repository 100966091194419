import { apiService } from "../../api";
import { replacePathParams } from "../../../helpers/urlHelpers";

const ENDPOINTS = {
  CREATE: '/wingo-coupons-template',
  UPDATE: '/wingo-coupons-template/:id',
  GET_ONE: '/wingo-coupons-template/:id',
  GET_ALL: '/wingo-coupons-template',
  GET_PAGINATED: '/wingo-coupons-template?page=:page&limit=:limit',
  DELETE: '/wingo-coupons-template/:id'
};

const WINGO_ENDPOINTS = {
    GET_MARKET: '/coupons-configuration/market/:id',
    GET_BETSHOP: '/coupons-configuration/market/:id/betshop/:betshopId',
    SAVE_CONFIGURATION: '/coupons-configuration'
   
  };
  

class WingoCouponsTemplateService {
  async create(data) {
    return await apiService.post(ENDPOINTS.CREATE, data);
  }

  async update(id, data) {
    return await apiService.put(replacePathParams(ENDPOINTS.UPDATE, { id }), data);
  }

  async getallAdditionalTemplates() {
    return await apiService.get(ENDPOINTS.GET_ALL);
  }

  async getPaginatedAdditionalTemplates(page, limit) {
    return await apiService.get(replacePathParams(ENDPOINTS.GET_PAGINATED, {
      page, limit
    }));
  }

  async delete(id) {
    return await apiService.delete(replacePathParams(ENDPOINTS.DELETE, { id }));
  }

  async getConfiguration(id) {
    return await apiService.wingoGet(replacePathParams(WINGO_ENDPOINTS.GET_MARKET, { id }));
  }

  async getBetshopConfiguration(id, betshopId) {
    return await apiService.wingoGet(replacePathParams(WINGO_ENDPOINTS.GET_BETSHOP, { id, betshopId }));
  }

  async saveWingoMarketConfiguration(data) {
    return await apiService.wingoPost(WINGO_ENDPOINTS.SAVE_CONFIGURATION, data);
  }

  async getWingoCurrencies() {
    return await apiService.getConfigurationForCurrency();
  }

  async deleteMarketConfiguration(id) {
    return await apiService.wingoDelete(replacePathParams(WINGO_ENDPOINTS.GET_MARKET, { id }));
  }

  async deleteBetshopConfiguration(id, betshopId) {
    return await apiService.wingoDelete(replacePathParams(WINGO_ENDPOINTS.GET_BETSHOP, { id, betshopId }));
  }

  
}

export const wingoCouponsTemplateService = new WingoCouponsTemplateService();
