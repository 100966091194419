<template>
  <div class="l-main">
    <div class="l-main__header u-box">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a class="c-breadcrumbs__link" @click="goToCompanies">
            <md-icon>home</md-icon>
          </a>
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link">Shift Keys</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <md-tabs class="c-tabs c-tabs--large">
        <md-tab
          v-for="tab in shiftKeysTabs"
          :id="tab.tab"
          :key="tab.tab"
          :md-label="tab.label"
          @click="changeActiveTab(tab.tab)"
        />
      </md-tabs>
      <component :is="currentTab" v-if="userCanSee(currentTab)" />
    </div>
  </div>
</template>

<script>
import OpenShiftKeys from "./pages/open-shift-keys";
import CloseShiftKeys from "./pages/close-shift-keys";
import { shiftKeysTabs } from "../../../const/index";
import { mapGetters } from 'vuex';
import each from 'lodash/each';

export default {
  name: "ShiftKeys",
  components: {
    OpenShiftKeys,
    CloseShiftKeys,
  },
  
  data() {
    return {
      currentTab: "open-shift-keys",
    };
  },

  computed: {
    ...mapGetters('account', ['userReadableSections', 'userWriteableSections', 'userAllowedRoutes', 'user']),
    shiftKeysTabs() {
      return shiftKeysTabs.filter(
        tab =>
          this.userCanSee(tab.tab)
      );
    },
  },

  created() {
    each(shiftKeysTabs, tab => {
      if (this.userCanSee(tab.tab)) {
        this.currentTab = tab.tab;
        return false;
      }
    })
  },

  methods: {
    changeActiveTab(tab) {
      this.currentTab = tab;
    },
    goToCompanies() {
      if (this.user.role === 1) {
        return this.$router.push({ name: 'companies' });
      }

      if (this.$route.name === this.userAllowedRoutes[0]) {
        return;
      }
      
      this.userAllowedRoutes.includes('companies') ? this.$router.push({ name: 'companies' }) : this.$router.push({ name: this.userAllowedRoutes[0] });
    },
    userCanSee(tab) {
      return this.userWriteableSections.includes(tab) ||
        this.userReadableSections.includes(tab)
    }
  },
};
</script>

<style lang="scss" scoped>
</style>