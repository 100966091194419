<template>
  <div class="c-table">
    <div v-if="propData && propData[0]" class="c-table__head">
      <template v-for="(key, i) in getKeys(propData[0].rowData)">
        <div v-if="key !== 'permissions'" :key="i" class="c-table__cell">
          {{ key === 'phrase' ? 'key' : key }}
        </div>
      </template>
      <div class="c-table__cell" />
    </div>
    <div class="c-table__body">
      <div v-for="(data, index) in propData" :key="index" class="c-table__row">
        <template v-for="(key, i) in getKeys(data.rowData)">
          <div v-if="key !== 'permissions'" :key="i" class="c-table__cell">
            {{ data.rowData[key] }}
          </div>
        </template>
        <div class="c-table__cell">
          <slot :selected="data" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { keys } from 'lodash';
export default {
  name: 'BaseTableComponent',
  props: {
    propData: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getKeys(data) {
      return keys(data);
    },
  },
};
</script>
