import { apiService } from '../api'

const ENDPOINTS = {
  CREATE: '/wingo-settings/save',
  GET: '/wingo-settings',
}

class WingoSettingsService {
  async create(data) {
    return await apiService.post(ENDPOINTS.CREATE, data)
  }

  async fetchWingoSettings() {
    return await apiService.get(ENDPOINTS.GET);
  } 
}

export const wingoSettingsService = new WingoSettingsService()
