<template>
  <div class="l-main__ctn-inner">
    <generic-collapsible
      v-if="userCanEdit"
      toggle-label="Add new"
      toggle-cancel="cancel"
      :visible="collapsibleVisible"
      @click="toggleTemplateInputField"
    >
      <shift-template
        :selected-template="selectedTemplate"
        @updateTicketTemplate="updateTicketTemplate"
      />
    </generic-collapsible>
    <div class="c-table-wrapper">
      <base-table-component :prop-data="templatesToShow">
        <template v-slot:default="slotProps">
          <span class="c-table__btn-wrap">
            <generic-button
              v-if="userCanEdit"
              icon
              transparent
              text-inherit
              @click="showTemplateInputField(slotProps.selected.rowData.id)"
            >
              <md-tooltip md-direction="top">
                Edit
              </md-tooltip>
              <md-icon>edit</md-icon>
            </generic-button>
          </span>
          <span class="c-table__btn-wrap">
            <generic-button
              v-if="userCanEdit"
              icon
              transparent
              text-inherit
              @click="openModalForDelete(slotProps.selected.rowData)"
            >
              <md-tooltip md-direction="top">
                Delete
              </md-tooltip>
              <md-icon>delete</md-icon>
            </generic-button>
          </span>
        </template>
      </base-table-component>
    </div>

    <paginate
      v-if="shouldShowPagination"
      :page-count="paginationData.pageCount"
      :force-page="currentPage"
      :page-range="limitPerPage"
      :click-handler="fetchCurrentPage"
      :container-class="'c-pagination'"
      :prev-link-class="'md-button prev md-elevation-1'"
      :next-link-class="'md-button next md-elevation-1'"
      :page-link-class="'md-button md-elevation-1'"
      :active-class="'active'"
      :disabled-class="'disabled'"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

import BaseTableComponent from "../../shared/table/BaseTableComponent";
import GenericButton from "../../shared/buttons/GenericButton";
import GenericCollapsible from "../../shared/collapsible/GenericCollapsible";
import ShiftTemplate from "./shift-template";
import DeleteModalComponent from "../../shared/delete-modal/DeleteModalComponent";
import { shiftTypes } from "../../const";

import { shiftTemplateService } from "../../services/betshop/shift-template";

export default {
  name: "CloseShiftTemplates",

  components: {
    BaseTableComponent,
    GenericButton,
    GenericCollapsible,
    ShiftTemplate,
  },

  data() {
    return {
      collapsibleVisible: false,
      currentPage: 1,
      limitPerPage: 10,
      templates: [],
      paginationData: {},
      selectedTemplate: {
        id: null,
        name: "",
        template: "",
        css: "",
      },
    };
  },

  computed: {
    ...mapGetters('account', ['userWriteableSections']),
    templatesToShow: function() {
      return this.templates.map(({ id, name }) => ({
        rowData: {
          id,
          name: name || `Ticket template ${id}`,
        },
      }));
    },
    shouldShowPagination: function() {
      return this.paginationData.totalItems > this.limitPerPage;
    },
    userCanEdit() {
      return !!this.userWriteableSections.includes('open-shift-template');
    }
  },

  created() {
    this.fetchCurrentPage();
  },

  methods: {
    ...mapActions("response", ["handleSuccess", "setGlobalError"]),

    resetSelectedTemplate() {
      this.selectedTemplate = {
        id: null,
        name: "",
        template: "",
      };
      this.$emit('canChangeTab', false);
    },

    toggleTemplateInputField() {
      if (this.collapsibleVisible) {
        this.resetSelectedTemplate();
      }
      this.collapsibleVisible = !this.collapsibleVisible;
      this.$emit('canChangeTab', this.collapsibleVisible);
    },

    showTemplateInputField(id) {
      this.selectedTemplate = this.templates.find(
        (template) => template.id === id
      );
      this.collapsibleVisible = true;
      this.$emit('canChangeTab', this.collapsibleVisible);
    },

    openModalForDelete(row) {
      if (this.paginationData.itemCount === 1) {
        this.currentPage = this.currentPage - 1 || 0;
      }

      this.$modal.show(
        DeleteModalComponent,
        {
          deleteMsg: `"${row.name}`,
          propData: row.id,
          deleteFunction: this.deleteTicketTemplate,
          getDataFunction: this.fetchCurrentPage,
          pageData: { currentPage: this.currentPage, limit: this.limitPerPage },
        },
        { height: "auto", scrollable: false, width: "400px" }
      );
    },

    fetchCurrentPage(page = 1, limit = this.limitPerPage) {
      this.currentPage = page;
      this.fetchShiftTemplates(page, limit);
    },

    async fetchShiftTemplates(page, limit) {
      try {
        const { data } = await shiftTemplateService.getPaginatedShiftTemplates(
          page,
          limit,
          shiftTypes.closeShift
        );

        const { items, itemCount, totalItems, pageCount } = data;
        this.templates = items;
        this.paginationData = {
          itemCount,
          totalItems,
          pageCount: pageCount || 0,
        };
      } catch (err) {
        this.setGlobalError(err.data.message);
      }
    },

    async updateTicketTemplate(template) {
      if(!template.template || !template.name) {
          return this.setGlobalError(
            !template.template && !template.name ? 'Template and name fields is required!' : 
            `${!template.template ? 'Template' : 'Name'} field is required!`);
      }
      
      if(!/\S/.test(template.name) || !/\S/.test(template.template)) {
          return this.setGlobalError(`Fields can't contain only empty spaces!`);
      }

      if(template.name.length > 200) {
          return this.setGlobalError(`Name can't contain more than 200 characters!`);
      }


      template.shiftType = shiftTypes.closeShift;

      try {
        this.selectedTemplate.id
          ? await shiftTemplateService.update(
              this.selectedTemplate.id,
              template
            )
          : await shiftTemplateService.create(template);
        this.fetchShiftTemplates(this.currentPage, this.limitPerPage);
        this.handleSuccess({
          message: `Successfuly ${
            this.selectedTemplate.id ? "updated" : "created"
          } ticket template!`,
        });
        this.toggleTemplateInputField();
      } catch (err) {
        if (
          err.data.statusCode === 400 &&
          typeof err.data.message === "string"
        ) {
          return this.setGlobalError(err.data.message);
        }

        this.setGlobalError("Something went wrong!");
        this.toggleTemplateInputField();
      }
      this.$emit('canChangeTab', false);
    },

    async deleteTicketTemplate(id) {
      try {
        await shiftTemplateService.delete(id);
        this.handleSuccess({ message: "Template successfuly deleted!" });
      } catch (err) {
        this.setGlobalError(err.data.message);
      }
    },
  },
};
</script>
