<template>
  <div>
    <div class="c-form-wrapper u-overflow-auto">
      <form class="c-form">
        <div>
          <div>
            <div
              v-for="configuration in disabledPagesConfiguration"
              :key="configuration.id"
              class="c-form__row"
            >
              <generic-form-group
                v-if="configuration.section === 'betshop' || configuration.section === 'disabledPages'" 
                :disabled="apiSelectedConfigurationsData[findIndexByKey(configuration)] && (type === 'betshop' && !apiSelectedConfigurationsData[findIndexByKey(configuration)].enabled)"
                :label="configuration.label"
              >
                <multiple-selections
                  v-if="configuration.type === 'multiple' && apiSelectedConfigurationsData[findIndexByKey(configuration)]"
                  :id="configuration.label"
                  :label="allPagesEnabled(configuration) ? 'All pages are enabled.' : ''"
                  :name="configuration.label"
                  :disabled="!hasWritePermision"
                  :selected-text="configuration.label"
                  :selection-data="configuration.values"
                  :default-value="apiSelectedConfigurationsData && apiSelectedConfigurationsData[findIndexByKey(configuration)] ? apiSelectedConfigurationsData[findIndexByKey(configuration)].value : configuration.defaultValue"
                  @clicked="(value) => updateArrayConfigValues(apiSelectedConfigurationsData[findIndexByKey(configuration)], value)"
                />
                <md-field
                  v-if="configuration.type === 'input'"
                  class="c-input c-input--icon"
                >
                  <md-input
                    v-if="apiSelectedConfigurationsData[findIndexByKey(configuration)]"
                    v-model="apiSelectedConfigurationsData[findIndexByKey(configuration)].value"
                    :disabled="!hasWritePermision"
                    :name="configuration.label"
                    :type="(typeof configuration.defaultValue)"
                  />
                </md-field>
                <div
                  v-if="configuration.type === 'checkbox'"
                  class="c-input c-input--icon"
                >
                  <md-checkbox 
                    v-model="apiSelectedConfigurationsData[findIndexByKey(configuration)].value" 
                    class="c-form-checkbox__checkmark u-margin-top-20"
                  />
                </div>
              </generic-form-group>
              <div v-if="type === 'betshop'" class="c-form__checkbox">
                <md-checkbox 
                  v-if="apiSelectedConfigurationsData[findIndexByKey(configuration)]" 
                  v-model="apiSelectedConfigurationsData[findIndexByKey(configuration)].enabled" 
                  :disabled="!hasWritePermision" 
                  class="c-form-checkbox__checkmark"
                />
                <span>Enable Configuration </span>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
    <div class="u-flex-justify-end u-mr-md u-mb-md">
      <generic-button
        variation="red"
        :disabled="!hasWritePermision"
        @click="updateSettings"
      >
        Update
      </generic-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import get from 'lodash/get';

import GenericFormGroup from '../../../shared/form-group/GenericFormGroup';
import GenericButton from '../../../shared/buttons/GenericButton';
import MultipleSelections from "../../../shared/multiple-selection/MultipleSelection";
import { configurationsService } from '../../../services/configurations';
import { companyService } from '../../../services/company';
import isEmpty from 'lodash/isEmpty';
import { hasWritePermission } from '../../../helpers/permission';

export default {
  name: 'DisabledPages',

  components: {
    GenericFormGroup,
    GenericButton,
    MultipleSelections,
  },

  props: {
    betshop: {
      type: Object,
      default: () => ({
        id: '',
      })
    },
    apiConfigurationsData: {
      type: Array,
      default: () => ([])
    },
    apiSelectedConfigurationsData: {
      type: Array,
      default: () => ([])
    },
    type: {
      type: String,
      default: ''
    }
  },

  data() {
    return {
      hasWritePermision: false
    };
  },

  computed: {
    disabledPagesConfiguration() {
      return this.apiConfigurationsData.filter(conf => conf.section === 'disabledPages')
    },
  },

  async created() {
    this.checkPermissions();
  },

  methods: {
    ...mapActions('response', ['handleSuccess', 'setGlobalError', 'handleError']),
    ...mapGetters('account', ['user', 'userHierarchyWriteableSections']),
    ...mapActions("company", [
      "updateMarketConfigurations",
      "updateBetshopConfigurations",
    ]),
    updateArrayConfigValues(array, value) {
      array.value = value;
    },

    async updateSettings() {
      let snackMessage = "Successfuly updated configurations! ";
      this.type === 'betshop' ?
       await configurationsService.updateMultipleBetshopConfigurations(
        get(this.$route, 'params.betshopId'),
        this.apiSelectedConfigurationsData.map((config) => {
          return {
            ...config,
            value: JSON.stringify(get(config, 'value')),
          }
        })
      ) :   
      await configurationsService.updateMultipleMarketConfigurations(
        get(this.$route, 'params.marketId'),
        this.apiSelectedConfigurationsData.map((config) => ({
          ...config,
          value: JSON.stringify(get(config, 'value'))
        }))
      );

      this.$emit('canChangeTab', false);
      this.handleSuccess({ message: snackMessage });
    },
    
    async handleBetshopLogoUpload() {
      try {
        this.logoToUpload && await this.uploadBetshopLogo();
        this.getBetshopLogoFromBe(this.$route.params.betshopId);
        this.handleSuccess({ message: `Betshop logo ${this.logoToUpload ? 'save' : 'updated'} successfully.` });
        this.$emit('canChangeTab', false);
      } catch (error) {
        this.setGlobalError(error.data.message);
      }
    },

    async updateBetshopLogo() {
      await companyService.updateBetshopLogo(
        this.$route.params.betshopId,
        this.betshopLogoEnabled,
      );
    },

    async uploadBetshopLogo() {
      const formData = new FormData();
      formData.append('file', this.logoToUpload, this.logoToUpload.name);

      await companyService.saveBetshopLogo(
        this.$route.params.betshopId,
        formData,
        this.betshopLogoEnabled,
      );
    },

    findIndexByKey(configuration) {
      return this.apiSelectedConfigurationsData.findIndex(
        (config) => config.configurationId === configuration.id
      );
    },

    allPagesEnabled(configuration) {
      return isEmpty(this.apiSelectedConfigurationsData[this.findIndexByKey(configuration)].value)
    },

    checkPermissions() {
      this.hasWritePermision = hasWritePermission(
        this.user(),
        this.userHierarchyWriteableSections(),
          `disabledPages-${this.type}`
      );
    }
  },
};
</script>
