import { apiService } from "@/services/api";

const ENDPOINT = '/manage-printers';

class ManagePrintersService {
  prepareDataFromResponse(items) {
    const data = [];

    items.forEach(item => {
      data.push({
        rowData: {
          id: item.id,
          name: item.name,
          pageWidthInCm: item.pageWidthInCm
        },
        id: item.id,
        name: item.name,
        pageWidthInCm: item.pageWidthInCm,
      });
    });

    return data;
  }

  async create(printer) {
    return await apiService.post(ENDPOINT, printer)
  }

  async delete(id) {
    return await apiService.delete(ENDPOINT + `/${id}`);
  }

  async getAllPrinters() {
    return await apiService.get(ENDPOINT);
  }
}
export const managePrintersService = new ManagePrintersService();
