import axios from 'axios'
import assign from 'lodash/assign'
import router from '../../router/router.js'
import { apiService } from '../api'
import { account } from '../../store/modules/account.js'
import { store } from '../../store/store.js'
import each from 'lodash/each';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import compact from 'lodash/compact';
import cloneDeep from 'lodash/cloneDeep';
import has from 'lodash/has';
import findIndex from 'lodash/findIndex';
import { defaultSidebarItems } from '../../const/sidebar-items';
import { defaultWritableSections } from '../../const/writable-sections.js';
import { allBetshopsTabs, betshopTabs } from '../../const';

const ENDPOINTS = {
  LOGIN: '/auth/login',
  LOGOUT:  '/auth/logout',
  CHECK_AUTH: '/auth/check-auth',
  ACTIVATE: '/auth/activate'
}

class AuthService {
  async login(data) {
    try {
      const response = await apiService.post(ENDPOINTS.LOGIN, data);

      if (!response.data) {
        return response;
      }

      this.handleLoginResponse(response)
      return response

    } catch(error) {
      throw error;
    }
  }

  async activate(data) {
    try {
      const response = await apiService.post(ENDPOINTS.ACTIVATE, data);

      if (!response.data) {
        return response;
      }

      this.handleLoginResponse(response)
      return response;
    } catch(error) {
      throw error;
    }
  }

  handleLoginResponse(response) {
    this.preparePermissionsAndRoutes(response.data);

    const firstAvailableRoute = account.state['userAllowedRoutes'][0] || 'home';

    if (this.isAdmin()) {
      router.push({ name: "companies" });
    } else {
      setTimeout(() => {
        router.push({ name: firstAvailableRoute });
      });
    }

    this.createSession(response.data);
  }

  async checkLogin() {
    if (!this.isLoggedIn()) {
      return;
    }

    try {
      const token = localStorage.getItem('token');
      const response = await apiService.post(ENDPOINTS.CHECK_AUTH, { token });
      return response.data;
    } catch(error) {
      this.logoutUserAndRedirectToLogin();
    }
  }

  async logout(email){
    try {
      await apiService.post(ENDPOINTS.LOGOUT, { email });
      this.logoutUserAndRedirectToLogin();
    } catch(error) {
      console.log(error);
    }
  }

  logoutUserAndRedirectToLogin() {
    if (router.currentRoute.name === 'login') {
      return;
    }

    this.setAuthHeaders('');
    localStorage.removeItem('token');
    localStorage.removeItem('role');
    router.push({ name: 'login' })
  }

  createSession(data) {
    localStorage.setItem('role', data.user.role)
    localStorage.setItem('token', data.access_token)

    this.setAuthHeaders(data.access_token)
  }

  setAuthHeaders(token) {
    assign(axios.defaults.headers, {
      'Authorization':  `Bearer ${token}`
    })
  }

  isLoggedIn() {
    return !!localStorage.getItem('token')
  }

  async fetchUserAndSetPermissions() {

    return new Promise(async (resolve, reject) => {
      try {
        const data = await this.checkLogin();
        this.preparePermissionsAndRoutes(data);
        resolve();
      } catch (error) {
        store.dispatch('response/setGlobalError', 'Something went wrong');
        if (router.currentRoute.name === 'login') {
          return;
        }

        router.push({ name: 'login' })

        reject();
      }
    })
  }

  preparePermissionsAndRoutes(data) {
    const userAllowedRoutes = [];
    const userPermissions = JSON.parse(get(data, "user.wsboPermissions"));
    const userHierarchyPermissions = JSON.parse(get(data, "user.hierarchyPermissions"));
    const userRole = get(data, 'user.role');
    let userWriteableSections = [];
    let userReadableSections = [];
    let userAlowedSidebarItems = [];
    let clonedDefaultSidebarItems = cloneDeep(defaultSidebarItems);

    if (userRole !== 1) {
      each(clonedDefaultSidebarItems, (sidebarItem) => {
        if (!has(userPermissions, sidebarItem.key)) {
          return;
        }

        let currentUserPermissions = userPermissions[sidebarItem.key].permissions;
        userAllowedRoutes.push(sidebarItem.pathName);


        const fitleredItems = sidebarItem.subItems.filter((item) => {
          let index = findIndex(currentUserPermissions, { page: item.key });

          if (index !== -1) {
            userAllowedRoutes.push(item.pathName);
          }

          return index !== -1;
        });

        sidebarItem.subItems = fitleredItems;
        userAlowedSidebarItems.push(sidebarItem);
      });

      each(userPermissions, section => {
        each(section.permissions, perm => {
          if (!perm.write && !perm.read) {
            return;
          }

          if (perm.write) {
            userWriteableSections.push(perm.key);
          }

          if (perm.read) {
            userReadableSections.push(perm.key);
          }
        })
      })
      this.checkMarketsAndBetShopsPages(userHierarchyPermissions, userAllowedRoutes, userAlowedSidebarItems);
    } else {
      userAlowedSidebarItems = defaultSidebarItems;
      userWriteableSections = defaultWritableSections;
    }

    const hierarchyPermissionsPages = this.setUserHierarchyTabItems(get(userHierarchyPermissions, 'hierarchy'));

    account.mutations.LOGIN_SUCCESS(account.state, {
      ...data,
      userAllowedRoutes: compact(userAllowedRoutes),
      userAlowedSidebarItems,
      userReadableSections,
      userWriteableSections,
      ...hierarchyPermissionsPages
    });
  }

  isAdmin() {
    return !isEmpty(account.state['user']) ? account.state['user'].role === 1 : null;
  }

  doesUserExist() {
    return !(account.state && account.state['user'].email);
  }

  async hasPermission(to, next) {
    const { name } = to;

    if (this.isAdmin() === null) {
      await this.fetchUserAndSetPermissions();
    }

    if (this.isAdmin()) {
      next();

      return;
    }

    this.checkPermissionForCurrentPage(name) ? next() : next({ name: 'home' });
  }

  checkPermissionForCurrentPage(page) {
    const userAllowedRoutes = store.getters['account/userAllowedRoutes'];

    if (page === 'home') {
      return true;
    }

    return userAllowedRoutes.includes(page);
  }

  checkMarketsAndBetShopsPages(userHierarchyPermissions, userAllowedRoutes, userAlowedSidebarItems) {
    let index = findIndex(userAlowedSidebarItems, { key: 'companies' });

    if (index === -1 || !userHierarchyPermissions || userHierarchyPermissions.marketIds.length === 0) {
      return;
    }
    userAllowedRoutes.push('companies');
    userAllowedRoutes.push('markets');
    userAllowedRoutes.push('betshops');
    userAllowedRoutes.push('betshop');
  }

  setUserHierarchyTabItems(hierarchy) {
    if (!hierarchy) {
      return;
    }

    const userHierarchy = {
      userHierarchyReadableSections: [],
      userHierarchyWriteableSections: [],
      userHierarchyAllowedTabs: []
    }

    this.setMarketPermissions(
      userHierarchy,
      hierarchy
    );


    this.setBetshopPermissions(
      userHierarchy,
      hierarchy
    );

    return userHierarchy;
  }

  setMarketPermissions(userHierarchy, hierarchy) {
    each(allBetshopsTabs, betshop => {
      if (!hierarchy[betshop.section] || !hierarchy[betshop.section].market) {
        return;
      }

      this.setPagePermissions(
        userHierarchy,
        hierarchy,
        betshop,
        'market',
        'marketRead',
        'marketWrite'
      );

      if (hierarchy[betshop.section].permissions.length === 1) {
        return;
      }

      this.setSubTabPermissions(
        userHierarchy,
        hierarchy,
        betshop,
        'market',
        'marketRead',
        'marketWrite'
      );
    });
  }

  setBetshopPermissions(userHierarchy, hierarchy) {
    each(betshopTabs, betshop => {
      if (!hierarchy[betshop.section] || !hierarchy[betshop.section].betshop) {
        return;
      }

      this.setPagePermissions(
        userHierarchy,
        hierarchy,
        betshop,
        'betshop',
        'betshopRead',
        'betshopWrite'
      );

      if (hierarchy[betshop.section].permissions.length === 1) {
        return;
      }

      this.setSubTabPermissions(
        userHierarchy,
        hierarchy,
        betshop,
        'betshop',
        'betshopRead',
        'betshopWrite'
      );
    });
  }

  setPagePermissions(userHierarchy, hierarchy, betshop, type, read, write) {
    let index = findIndex(hierarchy[betshop.section].permissions, { page: betshop.section });

    if (index === -1) {
      return;
    }

    const allowRead = hierarchy[betshop.section].permissions[index][read];
    const allowWrite =  hierarchy[betshop.section].permissions[index][write];

    if (allowRead || allowWrite) {
      userHierarchy.userHierarchyAllowedTabs.push(hierarchy[betshop.section].permissions[index].page + `-${type}`);
    }

    if (allowRead) {
      userHierarchy.userHierarchyReadableSections.push(hierarchy[betshop.section].permissions[index].page + `-${type}`);
    }

    if (allowWrite) {
      userHierarchy.userHierarchyWriteableSections.push(hierarchy[betshop.section].permissions[index].page + `-${type}`);
    }
  }

  setSubTabPermissions(userHierarchy, hierarchy, betshop, type, read, write) {
    each(betshop.subItems, item => {
      let index = findIndex(hierarchy[betshop.section].permissions, { pageTab: item.subSection });

      if (index === -1) {
        return;
      }

      const allowRead = hierarchy[betshop.section].permissions[index][read];
      const allowWrite =  hierarchy[betshop.section].permissions[index][write];

      if (allowRead) {
        userHierarchy.userHierarchyReadableSections.push(hierarchy[betshop.section].permissions[index].pageTab + `-${type}`);
      }

      if (allowWrite) {
        userHierarchy.userHierarchyWriteableSections.push(hierarchy[betshop.section].permissions[index].pageTab + `-${type}`);
      }

      if (allowRead || allowWrite) {
        userHierarchy.userHierarchyAllowedTabs.push(hierarchy[betshop.section].permissions[index].pageTab + `-${type}`);
      }
    });
  }
}

export const authService = new AuthService()
