import { apiService } from "../../api";
import { replacePathParams } from "../../../helpers/urlHelpers";

const ENDPOINTS = {
  CREATE: '/wingo-previous-drawings-template',
  UPDATE: '/wingo-previous-drawings-template/:id',
  GET_ONE: '/wingo-previous-drawings-template/:id',
  GET_ALL: '/wingo-previous-drawings-template',
  GET_PAGINATED: '/wingo-previous-drawings-template?page=:page&limit=:limit',
  DELETE: '/wingo-previous-drawings-template/:id'
};

class WingoPreviousDrawingsTemplateService {
  async create(data) {
    return await apiService.post(ENDPOINTS.CREATE, data);
  }

  async update(id, data) {
    return await apiService.put(replacePathParams(ENDPOINTS.UPDATE, { id }), data);
  }

  async getAllWingoPreviousDrawingsTemplates() {
    return await apiService.get(ENDPOINTS.GET_ALL);
  }

  async getPaginatedWingoPreviousDrawingsTemplates(page, limit) {
    return await apiService.get(replacePathParams(ENDPOINTS.GET_PAGINATED, {
      page, limit
    }));
  }

  async delete(id) {
    return await apiService.delete(replacePathParams(ENDPOINTS.DELETE, { id }));
  }
}

export const wingoPreviousDrawingsTemplateService = new WingoPreviousDrawingsTemplateService();
