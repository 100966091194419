<template>
  <div>
    <div v-if="showTab">
      <md-tabs 
        class="c-tabs c-tabs--small"
        :md-active-tab="selectedTab"
      >
        <md-tab
          v-for="tab in availableTabs"
          :id="tab.tab"
          :key="tab.tab"
          :md-label="tab.label"
          @click="changeActiveTab(tab.tab)"
        />
      </md-tabs>
      <component
        :is="currentTab"
        v-if="availableTabs.length"
        :api-configurations-data="apiConfigurationsData"
        :api-selected-configurations-data="apiSelectedConfigurationsData"
        :type="type"
        @canChangeTab="onCanChangeTab"
      />
    </div>
  </div>
</template>
<script>
import { additionalTemplateTabs } from "../../../const";
import ConfirmationModalComponent from '../../../shared/confirmation-modal/ConfirmationModalComponent';
import AdditionalTemplatePrintSettings from "./additional-template-print-settings";
import AdditionalTemplateFooter from "./additional-template-footer.vue";
import { mapGetters } from "vuex";
import each from 'lodash/each';
import includes from 'lodash/includes';

export default {
  name: "AdditionalTemplateSettings",
  components: {
    AdditionalTemplatePrintSettings,
    AdditionalTemplateFooter
  },

  props: {
    apiConfigurationsData: {
      type: Array,
      default: () => [],
    },
    apiSelectedConfigurationsData: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      required: true,
    }
  },

  data() {
    return {
      currentTab: "additional-template-print-settings",
      canOpenModal: false,
      previousTab: 'additional-template-print-settings',
      selectedTab: 'additional-template-print-settings',
      availableTabs: [],
      showTab: false
    };
  },

  computed: {
    additionalTemplateTabs() {
      return additionalTemplateTabs;
    },
  },

  created() {
    this.setAvailableTab();
  },

  methods: {
    ...mapGetters('account', ['user', 'userHierarchyAllowedTabs']),
    changeActiveTab(tab) {
      this.selectedTab = tab;
      if (this.canOpenModal) {
        return this.openConfirmationModal();
      }
      this.currentTab = tab
      this.previousTab = tab;
    },
    onCanChangeTab(canOpenModal) {
      this.canOpenModal = canOpenModal;
      this.$emit('canChangeTab', canOpenModal);
    },
    onCancel() {
      setTimeout(() => {
        this.selectedTab = this.previousTab;
      });
    },
    onAccept() {
      this.currentTab = this.selectedTab;
      this.previousTab = this.selectedTab;
      this.canOpenModal = false;
    },
    openConfirmationModal() {
      this.$modal.show(
        ConfirmationModalComponent,
        {
          confirmationMsg: `
            Your changes will be lost,
            are you sure you want to continue?`,
          onAccept: this.onAccept,
          onClose: this.onCancel
        },
        { height: "auto", scrollable: false, width: "400px" }
      )
    },
    setAvailableTab() {
      if (this.user().role === 1) {
        this.availableTabs = [...this.additionalTemplateTabs];
        this.setDefaultTab();

        return;
      }

      each(this.additionalTemplateTabs, setting => {
        if (!includes(this.userHierarchyAllowedTabs(), setting.subSection + `-${this.type}`)) {
          return;
        }
        this.availableTabs.push(setting);
      });

      if (this.availableTabs.length === 0) {
        return;
      }

      this.setDefaultTab();
    },

    setDefaultTab() {
      if (this.availableTabs.length === 0) {
        return this.showTab = true;
      }

      const firstAvailableTab = this.availableTabs[0].tab

      this.currentTab = firstAvailableTab;
      this.previousTab = firstAvailableTab;
      this.selectedTab = firstAvailableTab;
      this.showTab = true;
    }
  },
};
</script>
