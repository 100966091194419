<template>
  <div class="l-main">
    <div class="l-main__header u-box">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a class="c-breadcrumbs__link">
            <md-icon>home</md-icon>
          </a>
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link">HQ Settings</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <div class="c-table-wrapper">
        <form
          novalidate
          @submit.prevent="validateForm"
        >
          <md-field :class="getValidationClass($v.hqApiUrl)">
            <label>HQ Url</label>
            <md-input
              v-model="hqApiUrl"
              :disabled="!canUserEdit"
              name="hqApiUrl"
              type="hqApiUrl"
            />
            <span
              v-if="!$v.hqApiUrl.required"
              class="md-error"
            >HQ api url is required</span>
          </md-field>

          <md-field :class="getValidationClass($v.hqApiKey)">
            <label>HQ Api Key</label>
            <md-input
              v-model="hqApiKey"
              :disabled="!canUserEdit"
              name="hqApiKey"
              type="hqApiKey"
            />
            <span
              v-if="!$v.hqApiKey.required"
              class="md-error"
            >HQ Api Key is required</span>
          </md-field>

          <md-field :class="getValidationClass($v.hqSecretKey)">
            <label>HQ Secret Key</label>
            <md-input
              v-model="hqSecretKey"
              :disabled="!canUserEdit"
              name="hqSecretKey"
              type="hqSecretKey"
            />
            <span
              v-if="!$v.hqSecretKey.required"
              class="md-error"
            >HQ Secret Key is required</span>
          </md-field>
        </form>
      </div>

      <div class="u-flex-justify-end u-mr-md u-mb-md">
        <div class="u-mr-md">
          <generic-button
            v-if="canUserEdit"
            variation="red"
            @click="validateForm"
          >
            Save
          </generic-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GenericButton from '../../../shared/buttons/GenericButton';
import { HQSettings } from '../../../services/hq-settings';
import { validationBaseMixin } from '../../../mixins/ValidationBaseMixin';
import { required } from "vuelidate/lib/validators";

export default {
  name: "ConfigurationsHQSettings",
  
  components: {
    GenericButton,
  },

  mixins: [validationBaseMixin],

  data() {
    return {
      hqApiKey: '',
      hqSecretKey: '',
      hqApiUrl: ''
    };
  },

  validations: {
    hqApiKey: {
      required
    },
    hqSecretKey: {
      required,
    },
    hqApiUrl: {
      required
    }
  },

  computed: {
    ...mapGetters('hqSettings', ['getApiKey', 'getApiUrl', 'getSecretKey']),
    ...mapGetters('account', ['userWriteableSections', ]),

    canUserEdit() {
      return this.userWriteableSections.includes('hq-settings');
    }
  },

  created() {
    this.fetchHQSettings().then(() => {
      this.hqApiKey = this.getApiKey;
      this.hqSecretKey = this.getSecretKey;
      this.hqApiUrl = this.getApiUrl;
    });
  },

  methods: {
    ...mapActions("response", ["handleSuccess", "setGlobalError"]),
    ...mapActions("hqSettings", ["fetchHQSettings"]),

    validateForm() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
       this.save();
      }
    },

    async updateOrCreate() {
      return await HQSettings.create({
        hqApiKey: this.hqApiKey,
        hqSecretKey: this.hqSecretKey,
        hqApiUrl: this.hqApiUrl
      });
    },

    async save() {
       try {
        await this.updateOrCreate();
        this.handleSuccess({ message: `HQ settings updated!` });
      } catch (err) {
        this.setGlobalError(err.data.message);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>