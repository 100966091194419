<template>
  <div class="l-main">
    <div class="l-main__header u-box">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a
            class="c-breadcrumbs__link"
            @click="goToCompanies"
          >
            <md-icon>home</md-icon>
          </a>
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link">Users</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <div class="c-table-wrapper">
        <base-table-component
          :prop-data="users"
        >
          <template
            v-slot:default="slotProps"
          >
            <span class="c-table__btn-wrap">
              <router-link :to="{ name: 'users-edit', params: { id: slotProps.selected.rowData.id } }">
                <generic-button
                  v-if="user.id !== slotProps.selected.rowData.id && user.role === 1 && slotProps.selected.rowData.role === userRoles.manager"
                  icon
                  transparent
                  :class="{'edit-admin-btn': user.id == slotProps.selected.id}"
                  text-inherit
                >
                  <md-tooltip
                    md-direction="top"
                  >
                    Edit
                    {{ slotProps.selected.rowData }}
                  </md-tooltip>
                  <md-icon>edit</md-icon>
                </generic-button>
              </router-link>
            </span>
            <span class="c-table__btn-wrap">
              <generic-button
                v-if="user.id !== slotProps.selected.rowData.id && user.role === 1"
                icon
                transparent
                text-inherit
                @click="openDeleteModal(slotProps.selected.rowData)"
              >
                <md-tooltip md-direction="top">
                  Delete
                </md-tooltip>
                <md-icon>delete</md-icon>
              </generic-button>
            </span>
          </template>
        </base-table-component>
      </div>
      <div class="add-button u-color-white">
        <generic-button
          text-inherit
          transparent
          fab
          variation="red"
          @click="createUser()"
        >
          <md-icon>add</md-icon>
        </generic-button>
      </div>
      <paginate
        v-if="shouldShowPagination"
        :page-count="paginationData.pageCount"
        :force-page="currentPage"
        :page-range="limitPerPage"
        :click-handler="getNextPage"
        :container-class="'c-pagination'"
        :prev-link-class="'md-button prev md-elevation-1'"
        :next-link-class="'md-button next md-elevation-1'"
        :page-link-class="'md-button md-elevation-1'"
        :active-class="'active'"
        :disabled-class="'disabled'"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations  } from "vuex";
import CreateUserModal from "./create-update";
import { userService } from '../../services/user';
import { paginationService } from '../../services/pagination/index';
import BaseTableComponent from '../../shared/table/BaseTableComponent';
import DeleteModalComponent from '../../shared/delete-modal/DeleteModalComponent';
import GenericButton from '../../shared/buttons/GenericButton';
import { get } from 'lodash';
import { userRoles } from '../../const/roles';

export default {
  components: {
    BaseTableComponent,
    GenericButton
  },
  data() {
    return {
      users: [],
      paginationData: {},
      limitPerPage: 10,
      currentPage: 1,
      userRoles,
    }
  },

  computed: {
    ...mapGetters("account", ["user"]),

    shouldShowPagination() {
      return this.paginationData.totalItems > this.limitPerPage
    }
  },

  async created() {
    this.getUsers();
  },

  methods: {
    ...mapMutations('user', ['FETCHED_USERS']),
    createUser() {
      this.$modal.show(
        CreateUserModal,
        {
          userData: userService.formatUserDataData(),
          pageCount: this.paginationData.pageCount,
          currentPage: this.currentPage,
          getNextPage: this.getNextPage
        },
        { height: "auto", scrollable: true }
      );
    },

    goToCompanies() {
      this.$router.push({ name: 'companies' });
    },

    async getUsers(currentPage = 1, limit = 10) {
      const response = await userService.getUsers(currentPage, limit);

      if (response.data.items.length === 0) {
        this.getNextPage(this.currentPage - 1);

        return;
      }
      this.FETCHED_USERS(get(response, 'data.items', []));
      this.paginationData = paginationService.getPaginationData(response.data);
      this.users = userService.prepareUsersDataFromResponse(response.data.items);
    },

    getNextPage(page) {
      this.currentPage = page
      this.getUsers(page, this.limitPerPage);
    },

    async deleteUser(id) {
      return await userService.deleteUser(id);
    },

    openDeleteModal(selected) {
      this.$modal.show(
        DeleteModalComponent,
        {
          deleteMsg: `user with email "${selected.email}`,
          propData: selected.id,
          deleteFunction: this.deleteUser,
          getDataFunction: this.getNextPage,
          pageData: {currentPage: this.currentPage, limit: this.limitPerPage }
        },
        { height: "auto", scrollable: false, width: "400px" }
      )
    },
  }
};
</script>

<style lang="scss" scoped>
  .edit-admin-btn {
    margin-right: 52px !important;
  }
</style>