<template>
  <base-modal-component>
    <template v-slot:header>
      <span class="md-subheading u-typography-color">
        Are you sure you want rollback to the version?
      </span>
    </template>

    <template v-slot:footer>
      <div class="u-mr-xs">
        <generic-button
          variation="grey"
          @click="handleClose()"
        >
          Cancel
        </generic-button>
      </div>
      <generic-button
        variation="red"
        @click="handleRollback()"
      >
        Rollback
      </generic-button>
    </template>
  </base-modal-component>
</template>

<script>

import BaseModalComponent from '../../../../shared/modal/BaseModalComponent';
import GenericButton from "../../../../shared/buttons/GenericButton";

export default {
  components: {
    BaseModalComponent,
    GenericButton
  },
  props: {
    onRollback: {
      required: true,
      type: Function
    },
    app: {
      required: true,
      type: Object
    }
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    handleRollback() {
      this.$emit('close');
      this.onRollback(this.app)
    },
  },
};
</script>
