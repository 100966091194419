<template>
  <base-modal-component>
    <template v-slot:header>
      <h4 class="md-title u-typography-color">
        {{ createOrUpdate }} Printer Info
      </h4>
    </template>

    <template v-slot:body>
      <p
        v-if="error && error.messages"
        class="md-error"
      >
        {{ error.messages }}
      </p>
      <form
        novalidate
      >
        <md-field :class="getValidationClass($v.data.name)">
          <label>Name</label>
          <md-input
            v-model="data.name"
            v-focus
            type="name"
          />
          <span
            v-if="!$v.data.name.required"
            class="md-error"
          >The Name is required</span>
          <generic-button
            icon
            transparent
          >
            <md-tooltip
              class="c-form__tooltip"
              md-direction="top"
            >
              Name property is the default printer DESCRIPTION property set by the driver on the machine.<br>You can get description name from "lpoptions" command in the terminal (printer-info).
            </md-tooltip>
            <md-icon>help</md-icon>
          </generic-button>
        </md-field>

        <error-response-messages field-name="name" />
        <generic-form-group
          class="u-align-items-left"
          :label="`Ticket page width in cm`"
        >
          <md-field class="c-select-field c-select" :class="getValidationClass($v.data.pageWidthInCm)">
            <label>Page width in cm</label>
            <md-select
              v-model="data.pageWidthInCm"
            >
              <md-option
                v-for="option in pageWidthInCmValues"
                :key="option"
                :value="option"
              >
                {{ option }}
              </md-option>
            </md-select>
            <span
              v-if="!$v.data.pageWidthInCm.required"
              class="md-error"
            >The ticket page width is required</span>
          </md-field>

          <template v-slot:tooltip>
            <generic-button
              class="c-form-group__action"
              icon
              transparent
              text-inherit
            >
              <md-tooltip
                class="c-form__tooltip"
                md-direction="top"
              >
                Page width property is the size of the paper in centimeters.<br>For narrow print, width is around 7.0cm.<br>For wide print, width is around 10.5cm.
              </md-tooltip>
              <md-icon>help</md-icon>
            </generic-button>
          </template>
        </generic-form-group>
        <error-response-messages field-name="pageWidthInCm" />
      </form>
    </template>
    <template v-slot:footer>
      <div class="u-mr-xs">
        <generic-button
          variation="grey"
          @click="close"
        >
          Close
        </generic-button>
      </div>
      <generic-button
        variation="red"
        @click="validateForm"
      >
        {{ createOrUpdate }}
      </generic-button>
    </template>
  </base-modal-component>
</template>

<script>
import { validationBaseMixin } from '@/mixins/ValidationBaseMixin';
import GenericButton from "../../../../shared/buttons/GenericButton";
import ErrorResponseMessages from "../../../../shared/error-response-messages/ErrorResponseMessages";
import BaseModalComponent from "../../../../shared/modal/BaseModalComponent";
import { required } from "vuelidate/lib/validators";
import GenericFormGroup from "@/shared/form-group/GenericFormGroup";
import {mapActions, mapGetters} from "vuex";
import {responseService} from "@/services/response";
import {managePrintersService} from "@/services/manage-printers";

export default {
  name: 'ManagePrintersCreateOrUpdate',
  components: {
    GenericFormGroup,
    GenericButton,
    ErrorResponseMessages,
    BaseModalComponent
  },
  mixins: [validationBaseMixin],
  props: {
    fetchPrinters: {
      type: Function,
      required: true
    },
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("response", ["success", "error"]),

    createOrUpdate() {
      return this.data.id ? 'Update' : 'Create';
    },
    pageWidthInCmValues() {
      const pageWidthArray = [];
      for (let i = 5.0; i <= 12; i += 0.1) {
        pageWidthArray.push(Math.round(i * 10) / 10);
      }

      return pageWidthArray;
    }
  },
  validations: {
    data: {
      name: {
        required
      },
      pageWidthInCm: {
        required
      }
    },
  },
  methods: {
    ...mapActions("response", ["handleSuccess", "handleError", "resetError"]),

    close() {
      this.$emit("close");
      this.resetError();
    },
    validateForm() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.createOrUpdatePrinter();
      }
    },
    async createPrinter() {
      await managePrintersService.create(this.data);
    },
    async createOrUpdatePrinter() {
      try {
        await this.createPrinter();
        this.resetError();
        this.handleSuccess({message: `Printer ${this.createOrUpdate}d!`});
        this.fetchPrinters()
        this.close();
      } catch (err) {
        this.handleError(responseService.getErrorMessage(err.data.message));
      }
    }
  },

};
</script>

<style lang="scss" scoped>
.c-form__tooltip {
  z-index: 999999;
  height: auto;
  line-height: 2rem;
  display: flex;
}

</style>
