import { replacePathParams } from '../../helpers/urlHelpers';
import { apiService } from '../api'

const ENDPOINTS = {
  CREATE: '/receipt-keys/save',
  GET: '/receipt-keys',
  UPDATE: '/receipt-keys/:id',
  DELETE: '/receipt-keys/:id'
}

class ReceiptService {
  async create(data) {
    return await apiService.post(ENDPOINTS.CREATE, data)
  }

  async update(data) {
    return await apiService.put(replacePathParams(ENDPOINTS.UPDATE, { id: data.id }), data)
  }

  async fetchAllReceiptKeys(page, limit, type) {
    return await apiService.get(`${ENDPOINTS.GET}?page=${page}&limit=${limit}&type=${type}`);
  } 

  async deleteReceiptKey(id) {
    return await apiService.delete(replacePathParams(ENDPOINTS.DELETE, { id }))
  }
}

export const receiptService = new ReceiptService()
