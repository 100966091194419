<template>
  <form class="c-form u-full-height u-overflow-auto">
    <div>
      <div class="c-form__row u-ph-lg">
        <generic-button
          variation="red"
          :disabled="!hasWritePermision"
          @click="generateNewLicenceKey"
        >
          Generate new licence key
        </generic-button>
      </div>

      <div
        v-for="key in getLicenceKeys"
        :key="key.key"
        class="c-form__row"
      >
        <generic-form-group label="computer-01">
          <div class="c-clipboard">
            <div class="c-clipboard__field">
              <md-field class="c-input c-input--icon">
                <md-input :value="key.key" disabled />
              </md-field>
              <generic-button
                class="c-clipboard__field-button"
                icon
                transparent
                md-menu-trigger
                @click="copy(key.key)"
              >
                <md-tooltip>
                  {{ tooltipText }}
                </md-tooltip>
                <md-icon>file_copy</md-icon>
              </generic-button>
            </div>
            <div v-if="isMarketBetshopTrackingEnable" class="c-clipboard__field">
              <md-field class="c-input c-input--icon">
                <md-input :value="key.stickerNo" disabled />
              </md-field>
              <generic-button
                class="c-clipboard__field-button"
                icon
                transparent
                md-menu-trigger
                @click="updateStickerNo(key)"
              >
                <md-tooltip>
                  {{ stickerNoText }}
                </md-tooltip>
                <md-icon>edit</md-icon>
              </generic-button>
            </div>

            <div
              class="c-clipboard__action"
              @mouseleave="resetTooltipText"
            >
              <div
                :class="{ 'is-active': !key.isUsed }"
                class="c-clipboard__action-status"
              >
                {{ key.isUsed ? 'Used' : 'Available' }}
              </div>
              <div class="u-color-warning">
                <generic-button
                  icon
                  transparent
                  text-inherit
                  :disabled="!hasWritePermision"
                  @click="openDeleteModal(key)"
                >
                  <md-tooltip md-direction="top">
                    Delete
                  </md-tooltip>
                  <md-icon>delete</md-icon>
                </generic-button>
              </div>
              <div class="c-form__row u-ph-lg c-form__row-nowrap">
                <generic-button
                  variation="red"
                  :disabled="!key.isUsed"
                  @click="fetchApplicationLogs(key.id)"
                >
                  <md-tooltip v-if="key.logsPathUpdatedAt">
                    Last Fetched: {{ formatDate(key.logsPathUpdatedAt) }}
                  </md-tooltip>
                  Fetch logs
                </generic-button>
                <generic-button
                  variation="red"
                  :disabled="!key.isUsed || !key.logsPath"
                  @click="downloadApplicationLogs(key)"
                >
                  Download logs
                </generic-button>
              </div>
              <div class="c-form__row c-form__ping-btn">
                <generic-button
                  variation="red"
                  :disabled="!key.isUsed"
                  @click="pingApp(key.id)"
                >
                  Ping
                </generic-button>
              </div>
            </div>
          </div>
        </generic-form-group>
      </div>
    </div>
    <paginate
      v-if="shouldShowPagination"
      :page-count="paginationData.pageCount"
      :page-range="limitPerPage"
      :force-page="currentPage"
      :click-handler="fetchKeys"
      :container-class="'c-pagination'"
      :prev-link-class="'md-button prev md-elevation-1'"
      :next-link-class="'md-button next md-elevation-1'"
      :page-link-class="'md-button md-elevation-1'"
      :active-class="'active'"
      :disabled-class="'disabled'"
    />
  </form>
</template>

<script>
import GenericFormGroup from '../../../shared/form-group/GenericFormGroup';
import GenericButton from '../../../shared/buttons/GenericButton';
import DeleteModalComponent from '../../../shared/delete-modal/DeleteModalComponent';
import { betshopService } from '../../../services/betshop';
import get from 'lodash/get';
import { paginationService } from '../../../services/pagination';
import { mapActions, mapGetters } from 'vuex';
import { hasWritePermission } from '../../../helpers/permission';
import config from '../../../../config/local'
import moment from 'moment';
import { socketsService } from '../../../services/sockets';
import { store } from '../../../store/store.js';
import StickerNoModalComponent from '../../../shared/sticker-no-modal/StickerNoModalComponent';

export default {
  name: 'LicenceKeys',
  components: {
    GenericFormGroup,
    GenericButton,
  },

  props: {
    betshop: {
      type: Object,
      default: () => ({
        id: '',
      })
    },
    fetchTrigger: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      tooltipText: 'Copy',
      stickerNoText: 'Edit',
      currentPage: 1,
      paginationData: {},
      limitPerPage: 10,
      hasWritePermision: false,
      isMarketBetshopTrackingEnable : false ,
    }
  },

  computed: {
     ...mapGetters('company', ['isMarketBetshopTrackingEnabled' , 'getLicenceKeys']),
    shouldShowPagination() {
      return this.paginationData.totalItems > this.limitPerPage
    }
  },

  watch:{
    'fetchTrigger'() {
      this.fetchKeys(this.currentPage)
    }
  },

  created() {
    this.fetchKeys();
    this.checkPermissions();
    this.isMarketBetshopTrackingEnable = this.isMarketBetshopTrackingEnabled;
  },

  methods: {
    ...mapActions('response', ['setGlobalError', 'handleSuccess']),
    ...mapActions('company', ['setLicenceKeys']),
    ...mapGetters('account', ['user', 'userHierarchyWriteableSections']),

    async generateNewLicenceKey() {
      if(this.isMarketBetshopTrackingEnable){
        this.openStickerNoModal('Enter new Sticker Number: ' , {} , 'CREATE');
        return;
      }
      this.$emit('generateNewLicenceKey');
    },

    formatDate(date) {
      return moment(date).format('DD/MM/YYYY HH:mm');
    },

    deleteLicenceKey(id) {
      this.$emit('deleteLicenceKey', id);
    },

    openDeleteModal(row) {
      this.$modal.show(
        DeleteModalComponent,
        {
          deleteMsg: `key "${row.key}`,
          propData: row.id,
          deleteFunction: this.deleteLicenceKey,
        },
        { height: "auto", scrollable: false, width: "400px" }
      )
    },

    async fetchApplicationLogs(id) {
      try {
        this.subscribeOnLogsFetchedRoom(id);
        await betshopService.fetchApplicationLogs(id);
        this.handleSuccess({ message: 'Successfully requested Application logs' });
      } catch (error) {
        this.setGlobalError('Error fetching app logs!');
      }
    },

    async downloadApplicationLogs(key) {
      const link = document.createElement('a');
      link.setAttribute('download', 'main.log');
      link.style.display = 'none';
      document.body.appendChild(link);
      link.setAttribute('href', `${ config.BASE_DOWNLOAD_URL }/${ key.logsPath }`);
      link.click();
      link.remove();
    },

    async copy(key) {
      await this.$copyText(key);
      this.tooltipText = 'Copied';
    },

    updateStickerNo(licenceKey){
      this.openStickerNoModal('Enter new Sticker Number: ' , licenceKey , 'UPDATE')
    },

    openStickerNoModal(message , licenceKey , type) {
      this.$modal.show(
          StickerNoModalComponent,
          {
            stickerNoMsg: message,
            licenceKey : licenceKey,
            type: type,
            onClose: () => {
              setTimeout(() => {
                this.selectedTab = this.previousTab
              });
            },
            
          },
          { height: "auto", scrollable: false, width: "400px" }
        );
    },

    resetTooltipText() {
      if (this.tooltipText === 'Copy') {
        return;
      }

      this.tooltipText = 'Copy';
    },

    async fetchKeys(currentPage = 1, limit = 10) {
      this.currentPage = currentPage;
      try { 
        const { data } = await betshopService.getLicenceKeys(this.$route.params.betshopId, currentPage, limit);
        if (get(data, 'items').length === 0 && this.currentPage > 1) {
          return this.fetchKeys(this.currentPage - 1);
        }
        this.paginationData = paginationService.getPaginationData(data);
        this.setLicenceKeys(get(data, 'items', []));
      } catch (error) {
        this.setGlobalError('Error fetching licence keys!');
      }
    },
    checkPermissions() {
      this.hasWritePermision = hasWritePermission(
        this.user(),
        this.userHierarchyWriteableSections(),
        'licenceKeys-betshop'
      );
    },
    async pingApp(id) {
      try {
        this.subscribeOnPingApplicationRoom(id);
        await betshopService.pingApp(id);
        this.handleSuccess({ message: 'Betshop pinged.' });
      } catch (error) {
        this.setGlobalError('Error ping betshop!');
      }
    },
    subscribeOnPingApplicationRoom(id) {
      const room = `betshop-ping-${id}`;
      socketsService.subscribe(room, () => {
        socketsService.unsubscribe(room);
        store.dispatch('response/handleSuccess', { message: "Betshop is ACTIVE." }, { root: true });
      });
    },
    subscribeOnLogsFetchedRoom(id) {
      const room = `betshop-health-check-${id}`;
      socketsService.subscribe(room, () => {
        this.fetchTrigger++;
        socketsService.unsubscribe(room);
        store.dispatch('response/handleSuccess', { message: "Betshop logs are fetched." }, { root: true })
      });
    },
  }
};
</script>

<style lang="scss" scoped>
  .md-field {
    margin: 0;
  }
</style>
