<template>
  <md-snackbar
    :class="['success', { 'error': globalError }]"
    :md-position="position"
    :md-active="isSuccess || !!globalError"
    md-persistent
    @click="$emit('click')"
  >
    {{ message }}
    <generic-button
      transparent
      text-inherit
      @click="reset"
    >
      Hide
    </generic-button>
  </md-snackbar>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex'
import GenericButton from '../../shared/buttons/GenericButton';

export default {
  name: "SnackBar",
  components: {
    GenericButton
  },
  props: ['snackBarAvailable'],
  data () {
    return {
      position: 'center',
      duration: 4000,
      errorDuration: 10000
    }
  },

  computed: {
    ...mapGetters('response', ['success', 'isSuccess']),
    ...mapState('response', ['globalError']),

    message() {
      return this.globalError ? this.globalError : this.success
    }
  },

  watch: {
    isSuccess: function (data) {
      const $this = this;
      if (data) {
        setTimeout(() => {
          $this.resetSuccess();
        }, $this.duration)
      }
    },
    globalError: function (data) {
      const $this = this;
      if (data) {
        setTimeout(() => {
          $this.setGlobalError();
        }, $this.errorDuration)
      }
    }
  },

  methods: {
    ...mapActions('response', ['resetSuccess', 'setGlobalError']),

    reset() {
      this.globalError ? this.setGlobalError() : this.resetSuccess()
    }
  }
}
</script>
<style lang="scss" scoped>
.success {
  background-color: green;
}
.error {
  background-color: red;
}
.button-color {
  color: white
}
</style>