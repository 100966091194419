<template>
  <div class="l-login u-box">
    <div class="md-toolbar md-app-toolbar md-primary md-theme-default md-elevation-4 login-header">
      <div class="l-login__logo-wrapper">
        <img
          class="u-max-width-15"
          src="../../assets/betty-logo.svg"
        >
      </div>
    </div>
    <div class="full-height md-layout md-alignment-top-center ">
      <md-card>
        <md-card-header>
          <div class="md-title">
            Activation page
          </div>
          <p>
            Activation code has been sent to your email address. Please submit it here to activate your account.
          </p>
          <p>
            If you need new activation token, please try to log in again.
          </p>
          <p
            v-if="activationError"
            class="md-error"
          >
            {{ activationError }}
          </p>
        </md-card-header>

        <md-card-content>
          <form
            novalidate
            @submit.prevent="validateForm"
          >
            <div class="l-login-form__row">
              <md-field
                :class="['c-input', getValidationClass($v.activationToken)]"
              >
                <md-input
                  v-model="activationToken"
                  placeholder="Activation code"
                  name="activationToken"
                  type="text"
                  @keyup.enter="validateForm"
                />
                <span
                  v-if="!$v.activationToken.required"
                  class="md-error"
                >Token is required</span>
              </md-field>
            </div>
          </form>
        </md-card-content>


        <md-card-actions layout-align="center">
          <md-button
            class="mat-flat-button"
            @click="goToLogin"
          >
            Cancel
          </md-button>

          <generic-button
            variation="red"
            @click="validateForm"
          >
            Validate
          </generic-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import GenericButton from "../../shared/buttons/GenericButton";
import { validationBaseMixin } from '../../mixins/ValidationBaseMixin'
import {
  required,
} from 'vuelidate/lib/validators'

export default {
  components: {
    GenericButton
  },
  mixins: [validationBaseMixin],
  data: () => {
    return {
      email: null,
      activationToken: null
    }
  },
  validations: {
    activationToken: {
      required,
      minLength: 4,
      maxLength: 4
    }
  },
  computed: {
    ...mapState('account', ['activationError'])
  },
  created() {
    this.email = this.$route.query.email
    this.activationToken = this.$route.query.activationToken
  },
  methods: {
    ...mapActions('account', ['handleActivation']),

    validateForm () {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.activate()
      }
    },

    goToLogin () {
      this.$router.push({ name: 'login' });
    },

    async activate () {
      try {
        await this.handleActivation({ email: this.email, activationToken: this.activationToken })
      } catch (error) {
        console.log('Activation error', error)
      }

    }
  }
}
</script>
<style lang="scss" scoped>
  .login-header {
    margin-bottom: 100px;
  }

  .md-title {
    text-align: center;
  }

  .md-card {
    width: 370px;
    margin-top: 40px;
    padding: 15px;
  }

  .md-card-content {
    padding: 8px;
  }

  .c-input {
     button {
      transform: translate(0px, -50%);
     }
  }

  .md-error {
    color: #ff1744;
    text-align: center;
    margin: 10px 0 0 0;
    font-size: 16px;
  }
</style>
