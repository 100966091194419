import { apiService } from "../../api";
import { replacePathParams } from "../../../helpers/urlHelpers";

const ENDPOINTS = {
  CREATE: '/additional-template',
  UPDATE: '/additional-template/:id',
  GET_ONE: '/additional-template/:id',
  GET_ALL: '/additional-template',
  GET_PAGINATED: '/additional-template?page=:page&limit=:limit',
  DELETE: '/additional-template/:id'
};

class AdditionalTemplateService {
  async create(data) {
    return await apiService.post(ENDPOINTS.CREATE, data);
  }

  async update(id, data) {
    return await apiService.put(replacePathParams(ENDPOINTS.UPDATE, { id }), data);
  }

  async getallAdditionalTemplates() {
    return await apiService.get(ENDPOINTS.GET_ALL);
  }

  async getPaginatedAdditionalTemplates(page, limit) {
    return await apiService.get(replacePathParams(ENDPOINTS.GET_PAGINATED, {
      page, limit
    }));
  }

  async delete(id) {
    return await apiService.delete(replacePathParams(ENDPOINTS.DELETE, { id }));
  }
}

export const additionalTemplateService = new AdditionalTemplateService();
