<template>
  <div>
    <div class="c-form-wrapper--small">
      <div class="c-form">
        <div class="c-form__row">
          <generic-form-group 
            :label="closeShiftTemplateConfiguration.label"
            :disabled="!hasWritePermision || isConfigurationDisabled(closeShiftTemplateConfiguration) && type !== selectedType"  
          >
            <span v-if="!templatesForDropdown.length">No templates found, in order to choose template, first create one.</span>
            <md-field class="c-select-field c-select">
              <label>{{ selectedTemplateId ? '' : closeShiftTemplateConfiguration.label }}</label>
              <md-select
                v-model="selectedTemplateId"
                :disabled="!templatesForDropdown.length"
                @md-selected="changedOption"
              >
                <md-option
                  v-for="shiftTemplate in templatesForDropdown"
                  :key="shiftTemplate.id"
                  :value="shiftTemplate.id"
                >
                  {{ shiftTemplate.name }}
                </md-option>
              </md-select>
            </md-field>
          </generic-form-group>
          <div v-if="type !== selectedType" class="c-form__checkbox">
            <md-checkbox v-model="selectedConfigurationData[findIndexByKey(closeShiftTemplateConfiguration)].enabled" :disabled="!hasWritePermision" class="c-form-checkbox__checkmark" /><span>Enable Configuration </span>
          </div>
        </div>
        <div class="c-form__row">
          <generic-form-group
            class="u-align-items-center"
            :label="closeShiftDisabledKeysConfiguration.label"
            :disabled="!hasWritePermision || (isConfigurationDisabled(closeShiftDisabledKeysConfiguration) && type !== selectedType) || !selectedTemplateId"
          >
            <span v-if="!selectedTemplateId && type !== selectedType">In order to disable keys, choose template first.</span><br>
            <span v-if="getAllShiftKeys.length === 0">No shiftkeys found, first create one.</span>
            <md-field class="c-select-field">
              <label>{{ selectedConfigurationData[findIndexByKey(closeShiftDisabledKeysConfiguration)].value.length === 0 ? 'All sections are enabled.' : '' }}</label>
              <md-select 
                v-model="selectedConfigurationData[findIndexByKey(closeShiftDisabledKeysConfiguration)].value" 
                multiple
                :disabled="!getAllShiftKeys.length"
                @md-selected="changedOption"
              >
                <md-option
                  v-for="shiftKey in getAllShiftKeys"
                  :key="shiftKey.id"
                  :value="shiftKey.value"
                >
                  {{ shiftKey.value }}
                </md-option>
              </md-select>
            </md-field>
          </generic-form-group>
          <div v-if="type !== selectedType" class="c-form__checkbox">
            <md-checkbox v-model="selectedConfigurationData[findIndexByKey(closeShiftDisabledKeysConfiguration)].enabled" :disabled="!hasWritePermision" class="c-form-checkbox__checkmark" /><span>Enable Configuration </span>
          </div>
        </div>
      </div>
    </div>
    <div class="u-flex-justify-end u-mr-md u-mb-md">
      <div class="u-mr-md">
        <generic-button variation="red" :disabled="!hasWritePermision" @click="handleFormSubmit(true)">
          Clear All
        </generic-button>
      </div>
      <generic-button variation="red" :disabled="!hasWritePermision" @click="handleFormSubmit">
        Update
      </generic-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import { shiftTemplateService } from "../../../services/betshop/shift-template";
import { shiftTypes } from "../../../const";
import GenericFormGroup from "../../../shared/form-group/GenericFormGroup";
import map from "lodash/map";
import get from "lodash/get";
import findIndex from 'lodash/findIndex';
import GenericButton from "../../../shared/buttons/GenericButton";
import { PAGE_INFO } from "../../../const/pagination";
import { configurationsService } from "../../../services/configurations";
import { hasWritePermission } from '../../../helpers/permission'

export default {
  name: "CloseShiftTemplateConfiguration",

  components: {
    GenericFormGroup,
    GenericButton,
  },

  props: {
    typeId: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      templatesForDropdown: [],
      selectedTemplateId: null,
      selectedType: "market",
      paginationData: {
        limitPerPage: 999,
        currentPage: PAGE_INFO.INITIAL_PAGE,
      },
      clearAll: false,
      hasWritePermision: false,
      isSaved: false
    };
  },

  computed: {
    ...mapGetters("shift", ["getAllShiftKeys"]),
    ...mapGetters("company", [
      "getApiConfigurationsData",
      "getApiMarketConfigurationsData",
      "getApiBetshopConfigurationsData",
    ]),
    closeShiftTemplateConfiguration() {
      const index = findIndex(this.getApiConfigurationsData, { key: 'close_shift_template' });

      return this.getApiConfigurationsData[index]
    },
    closeShiftDisabledKeysConfiguration() {
      const index = findIndex(this.getApiConfigurationsData, { key: 'close_shift_keys' });

      return this.getApiConfigurationsData[index]
    },
    getCurrentShiftTemplate() {
      return this.templatesForDropdown.find(item => item.id === this.selectedTemplateId) || '';
    },
    selectedConfigurationData() {
      return this.type === this.selectedType ? this.getApiMarketConfigurationsData : this.getApiBetshopConfigurationsData
    },
    getSelectedTemplateId() {
      const index = findIndex(this.selectedConfigurationData, { key: 'close_shift_template'} );

      return this.selectedConfigurationData[index].value;
    }
  },

  async created() {
    this.fetchShiftTemplates();
    this.fetchShiftKeys();
    this.selectedTemplateId = this.getSelectedTemplateId;
    this.checkPermissions();
  },

  methods: {
    ...mapActions("response", ["setGlobalError", "handleSuccess"]),
    ...mapActions("shift", ["fetchAllShiftKeys"]),
    ...mapActions("company", [
      "updateMarketConfigurations",
      "updateBetshopConfigurations",
    ]),
    ...mapGetters('account', ['user', 'userHierarchyWriteableSections']),
    async fetchShiftTemplates() {
      try {
        const { data } = await shiftTemplateService.getAllShiftTemplates(
          shiftTypes.closeShift
        );
        map(data, (singleTemplate) => {
          singleTemplate.label = singleTemplate.name;
          singleTemplate.value = singleTemplate.id;
        });
        this.templatesForDropdown = data;
      } catch (err) {
        this.setGlobalError(err.data.message);
      }
    },

    async handleFormSubmit(clearAll) {
      if (clearAll) {
        this.selectedTemplateId = null;
        this.clearAll = true;
      }

      try {
        this.handleSaveShiftKeys();
        this.handleSuccess({ message: `Successfully updated configuration!` });
      } catch (err) {
        this.setGlobalError(err.data.message);
      }
    },

    async handleSaveShiftKeys() {
      if (this.type === this.selectedType) {
        const { data } = await configurationsService.updateMultipleMarketConfigurations(
          get(this.$route, "params.marketId"),
          this.getApiMarketConfigurationsData.map((config) => ({
            ...config,
            value: this.prepareValues(config)
          }))
        );
        this.updateMarketConfigurations(data);
      } else {
        const { data } = await configurationsService.updateMultipleBetshopConfigurations(
          get(this.$route, "params.betshopId"),
          this.getApiBetshopConfigurationsData.map((config) => ({
            ...config,
            value: this.prepareValues(config)
          }))
        );
        this.updateBetshopConfigurations(data);
      }
      this.$emit('canChangeTab', false);
      this.isSaved = true;
    },

    prepareValues(config) {
      if (config.key === 'close_shift_template' ) {
        return JSON.stringify(this.clearAll ? '' : this.getCurrentShiftTemplate.id);
      }

      if (config.key === 'close_shift_keys' && this.clearAll) {
        return JSON.stringify([]);
      }

      return JSON.stringify(get(config, 'value'))
    },

    fetchShiftKeys() {
      this.fetchAllShiftKeys({
        currentPage: this.paginationData.currentPage,
        limit: this.paginationData.limitPerPage,
        type: "close",
      });
    },

    findIndexByKey(configuration) {
      return this.selectedConfigurationData.findIndex(
        (config) => config.configurationId === configuration.id
      );
    },

    isConfigurationDisabled(configuration) {
      return !get(this.selectedConfigurationData[this.findIndexByKey(configuration)], 'enabled', false)
    },

    checkPermissions() {
      this.hasWritePermision = hasWritePermission(
        this.user(),
        this.userHierarchyWriteableSections(),
        `shiftSettings-${this.type}`
      );
    },
    changedOption() {
      if (this.isSaved) {
        this.isSaved = false;
        return;
      }
      this.$emit('canChangeTab', true);
    }
  },
};
</script>
