<template>
  <base-modal-component>
    <template v-slot:header>
      <h4 class="u-typography-color md-title">
        {{ createOrUpdate }} User
      </h4>
      <generic-button
        icon
        transparent
        @click="close()"
      >
        <md-icon>
          clear
        </md-icon>
      </generic-button>
    </template>

    <template v-slot:body>
      <p
        v-if="error && error.messages"
        class="md-error"
      >
        {{ error.messages }}
      </p>
      <form
        novalidate
        @submit.prevent="validateUser"
      >
        <md-field :class="getValidationClass($v.userData.email)">
          <label for="email">Email</label>
          <md-input
            v-model="userData.email"
            v-focus
            name="email"
            type="email"
            class="md-input"
            autocomplete="off"
          />
          <span
            v-if="!$v.userData.email.required"
            class="md-error"
          >The email is required</span>
          <span
            v-else-if="!$v.userData.email.email"
            class="md-error"
          >Invalid email</span>
        </md-field>
        <error-response-messages :field-name="'email'" />

        <md-field
          v-if="!userData.id"
          :class="getValidationClass($v.userData.password)"
        >
          <label>Password</label>
          <md-input
            v-model="userData.password"
            type="password"
            autocomplete="new-password"
          />
          <span
            v-if="!$v.userData.password.required"
            class="md-error"
          >The password is required</span>
        </md-field>
        <error-response-messages :field-name="'password'" />
        <md-field v-if="!shouldAdminChangeRole">
          <label>Role</label>
          <md-select
            id="role"
            v-model="userData.role"
            name="role"
            md-dense
          >
            <md-option :value="1">
              Admin
            </md-option>
            <md-option :value="2">
              Manager
            </md-option>
          </md-select>
        </md-field>

        <error-response-messages :field-name="'id'" />
        <div class="u-typography-color">
          <span v-if="userData.role === 2">By default Managers don't have any permissions.</span>
          <span v-else>Admins have all permissions.</span>
        </div>
      </form>
    </template>
    <template v-slot:footer>
      <div class="u-mr-xs">
        <generic-button
          variation="grey"
          @click="close"
        >
          Close
        </generic-button>
      </div>
      <div v-if="userData.role === 2" class="u-mr-xs">
        <generic-button
          variation="red"
          @click="validateUser(true)"
        >
          Create and set permissions
        </generic-button>
      </div>
      <generic-button
        variation="red"
        @click="validateUser"
      >
        {{ createOrUpdate }}
      </generic-button>
    </template>
  </base-modal-component>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationBaseMixin } from '../../../mixins/ValidationBaseMixin';
import GenericButton from "../../../shared//buttons/GenericButton";
import ErrorResponseMessages from "../../../shared/error-response-messages/ErrorResponseMessages";
import { userService } from "../../../services/user";
import { responseService } from "../../../services/response";
import BaseModalComponent from "../../../shared/modal/BaseModalComponent";
import { required, email } from "vuelidate/lib/validators";

export default {
  name: 'UserModal',
  components: {
    GenericButton,
    ErrorResponseMessages,
    BaseModalComponent
  },
  mixins: [validationBaseMixin],
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    pageCount: {
      type: Number,
      required: true
    },
    getNextPage: {
      type: Function,
      required: true
    },
    userData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      messages: {
        error: null,
        success: null,
      },
    };
  },
  computed: {
    ...mapGetters("account", ["user"]),

    ...mapGetters("response", ["success", "error"]),

    createOrUpdate() {
      return !this.userData.id ? 'Create' : 'Update';
    },

    shouldAdminChangeRole() {
      return this.user.role == 1 && this.userData.id == this.user.id;
    },
  },

  validations: {
    userData: {
      password: {
        required
      },
      email: {
        required,
        email
      }
    }
  },

  methods: {
    ...mapActions("response", ["handleSuccess", "handleError", "resetError"]),

    async createUser(setPermissions) {
      try {
        this.userData.user_id = this.user.id;
        const { data } = await userService.create(this.userData);
        this.handleSuccess({ message: "User Created!" });
        this.getNextPage(this.currentPage);
        this.close();

        if (setPermissions) {
          this.$router.push({ name: 'users-edit', params: { id: data.id } });
        }
      } catch (err) {
        this.handleError(responseService.getErrorMessage(err.data.message));
      }
    },

    async updateUser() {
      try {
        this.userData.adminId = this.user.id;
        delete this.userData.password;
        await userService.update(this.userData);
        this.handleSuccess({ message: "User Updated!" });
        this.getNextPage(this.currentPage);
        this.close();
      } catch (err) {
        this.handleError(responseService.getErrorMessage(err.data.message));
      }
    },

    close() {
      this.$emit("close");
      this.resetError();
    },

    validateUser(setPermissions = false) {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        !this.userData.id ? this.createUser(setPermissions) : this.updateUser();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>