<template>
  <base-modal-component>
    <template v-slot:header>
      <h4 class="md-title u-typography-color">
        {{ updateOrCreate }} Translation
      </h4>
    </template>
    <template v-slot:body>
      <p
        v-if="error && error.messages"
        class="md-error"
      >
        {{ error.messages }}
      </p>

      <div
        v-if="error && error.sectionId"
      >
        <p
          v-for="(error, index) in error.sectionId"
          :key="index"
          class="md-error"
        >
          {{ error }}
        </p>
      </div>
      <form
        class="u-width"
        novalidate
      >
        <md-field :class="getValidationClass($v.translationData.word)">
          <label>Key</label>
          <md-input
            v-model="translationData.word"
            v-focus
          />
          <span
            v-if="!$v.translationData.word.required"
            class="md-error"
          >Key is required</span>
        </md-field>
        <div class="md-layout-item select-button">
          <div class="md-layout-item">
            <md-field :class="getValidationClass($v.translationData.sectionId)">
              <label for="linuxLink">Choose section</label>
              <md-select
                id="section"
                v-model="translationData.sectionId"
                name="section"
                placeholder="Section"
                :disabled="!sections.length"
              >
                <md-option
                  v-for="section in sections"
                  :key="section.id"
                  :value="section.id"
                >
                  {{ section.title }}
                </md-option>
              </md-select>
              <span
                v-if="!$v.translationData.sectionId.required"
                class="md-error"
              >Section is required</span>
            </md-field>
            <span v-if="!sections.length">No sections available, in order to add traslation, first create section.</span>
          </div>
        </div>
        <md-field
          v-for="(language, index) in languages"
          :key="language.id"
          md-inline
        >
          <label>{{ language.name }}</label>
          <md-input
            v-if="translationData.translation[index]"
            v-model="translationData.translation[index].phrase"
          />
        </md-field>
      </form>
    </template>
    <template v-slot:footer>
      <div class="u-flex-row-center">
        <div class="u-mr-xs">
          <generic-button
            variation="grey"
            @click="close"
          >
            Close
          </generic-button>
        </div>
        <generic-button
          variation="red"
          @click="validateForm"
        >
          {{ updateOrCreate }}
        </generic-button>
      </div>
    </template>
  </base-modal-component>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { translationService } from "../../../../../services/translation";
import BaseModalComponent from "../../../../../shared/modal/BaseModalComponent";
import GenericButton from "../../../../../shared/buttons/GenericButton";
import { responseService } from "../../../../../services/response/index";
import { validationBaseMixin } from '../../../../../mixins/ValidationBaseMixin'
import { required } from "vuelidate/lib/validators";

export default {
  name: 'Index',
  components: {
    BaseModalComponent,
    GenericButton
  },
  mixins: [validationBaseMixin],
  props: {
    sections: {
      type: Array,
      required: true
    },
    languages: {
      type: Array,
      required: true
    },
    fetchCurrentPage: {
      type: Function,
      required: true
    },
    selectedWord: {
      default: null
    },
    translationData: {
      required: true
    }
  },
  data() {
    return {
      showDropdown: false
    };
  },
  computed: {
    ...mapGetters("response", ["success", "error"]),
    updateOrCreate() {
      return this.translationData.wordId ? 'Update' : 'Create';
    },
    selectedSection() {
      return this.translationData.wordId ? this.translationData.sectionTitle : "";
    }
  },
  validations: {
    translationData: {
      word: {
        required,
      },
      sectionId: {
        required
      }
    }
  },
  created () {
    this.setTranslationsForGivenLanguages();
  },
  methods: {
    ...mapActions("response", ["handleSuccess", "handleError", "resetError"]),

    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
    },

    setSectionId(section) {
      this.translationData.sectionId = section.id;
      this.selectedSection = section.title;
      this.toggleDropdown();
    },

    validateForm() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.saveOrUpdateTranslation();
      }
    },

    async saveOrUpdateTranslation() {
      try {
        delete this.translationData.languagesIds;
        await this.saveOrUpdate()
        this.close();
        this.fetchCurrentPage();
        this.handleSuccess({ message: `Translation ${this.updateOrCreate}d!` });
        this.resetError();
      } catch (err) {
        this.handleError(responseService.getErrorMessage(err.data.message));
        const modalElement = document.getElementsByClassName('v--modal-box')[0];

        if (modalElement) {
          modalElement.scrollTop = 0;
        }
      }
    },

    async saveOrUpdate() {
      return this.translationData.wordId ?
        await translationService.update(this.translationData) :
        await translationService.save(this.translationData);
    },

    close() {
      this.$emit('close');
      this.resetError();
    },

    setTranslationsForGivenLanguages() {
      if (this.languages.length === 0) {
        return;
      }


      if (this.translationData.length > 0) {
        return;
      }

      //prepare translatition for create
      this.languages.forEach(language => {
        if (!this.translationData.wordId) {
          return this.translationData.translation.push({ languageId: language.id, phrase: "" });
        }

        if (!this.translationData.languagesIds.includes(language.id)) {
          this.translationData.translation.push({ languageId: language.id, phrase: "" });
        }
      });
    }
  }
};
</script>
