<template>
  <div class="l-manage-drivers u-mt-md c-table-wrapper">
    <base-table-component
      class="u-wordbreak-all"
      :prop-data="printers"
    >
      <template v-slot:default="slotProps">
        <span class="c-table__btn-wrap">
          <generic-button
            v-if="userCanEdit"
            icon
            transparent
            text-inherit
            @click="createOrUpdatePrinter(slotProps.selected)"
          >
            <md-tooltip
              md-direction="top"
            >
              Edit
            </md-tooltip>
            <md-icon>edit</md-icon>
          </generic-button>
        </span>
        <span class="c-table__btn-wrap">
          <generic-button
            v-if="userCanEdit"
            icon
            transparent
            text-inherit
            @click="openDeleteModal(slotProps.selected)"
          >
            <md-tooltip
              md-direction="top"
            >
              Delete
            </md-tooltip>
            <md-icon>delete</md-icon>
          </generic-button>
        </span>
      </template>
    </base-table-component>
    <div class="add-button u-color-white">
      <generic-button
        v-if="userCanEdit"
        text-inherit
        transparent
        fab
        variation="red"
        @click="createOrUpdatePrinter()"
      >
        <md-icon>add</md-icon>
      </generic-button>
    </div>
  </div>
</template>

<script>
import GenericButton from "../../shared/buttons/GenericButton";
import CreateUpdateModal from './components/create-update/index';
import BaseTableComponent from '../../shared/table/BaseTableComponent';
import DeleteModalComponent from '../../shared/delete-modal/DeleteModalComponent';
import { mapGetters } from 'vuex';
import { managePrintersService } from "@/services/manage-printers";

export default {
  name: "ManagePrinters",

  components: {
    GenericButton,
    BaseTableComponent
  },

  data() {
    return {
      printers: [],
      currentTab: 'manage-printers',
    }
  },

  computed: {
    ...mapGetters('account', ['userWriteableSections']),

    userCanEdit() {
      return !!this.userWriteableSections.includes('manage-printers');
    }
  },

  created() {
    this.fetchPrinters()
  },

  methods: {
    fetchPrinters() {
      managePrintersService.getAllPrinters()
      .then(response => {
        this.printers = managePrintersService.prepareDataFromResponse(response.data);
      });
    },

    createOrUpdatePrinter(selected) {
      this.$modal.show(
        CreateUpdateModal,
        {
          data: selected || {},
          fetchPrinters: this.fetchPrinters
        },
        { height: "auto", scrollable: true }
      );
    },

    openDeleteModal(selected) {
      this.$modal.show(
        DeleteModalComponent,
        {
          deleteMsg: `"${selected.rowData.name}`,
          propData: selected.id,
          deleteFunction: this.deleteSelected,
          getDataFunction: this.fetchPrinters,
          pageData: {currentPage: this.currentPage, limit: this.limitPerPage }
        },
        { height: "auto", scrollable: false, width: "400px" }
      )
    },

    deleteSelected(id) {
      return managePrintersService.delete(id);
    },
  }
};

</script>
