export const systems = {
    'mac': {
      file: {},
      os: 'ios',
      name: 'betty-ios',
      error: '',
      fileType: ['zip'],
      refName: 'file',
      placeholder: 'Choose file ios',
      label: 'Mac OS',
      yml: {
        file: {},
        os: 'ios',
        name: 'betty-ios',
        ymlName: 'latest-mac.yml',
        error: '',
        fileType: ['yml',],
        refName: 'ymlFile',
        placeholder: 'Choose yml file',
        label: 'Mac OS',
      }
    },
    'linux': {
      file: {},
      os: 'linux',
      name: 'betty-linux',
      error: '',
      fileType: ['AppImage'],
      refName: 'file',
      placeholder: 'Choose file linux',
      label: 'Linux OS',
      yml: {
        file: {},
        os: 'linux',
        name: 'betty-linux',
        ymlName: 'latest-linux.yml',
        error: '',
        fileType: ['yml'],
        refName: 'ymlFile',
        placeholder: 'Choose yml file',
        label: 'Linux OS',
      }
    },
    'windows': {
      file: {},
      os: 'windows',
      name: 'betty-windows',
      error: '',
      fileType: ['exe'],
      refName: 'file',
      placeholder: 'Choose file windows',
      label: 'Windows OS',
      yml: {
        file: {},
        os: 'windows',
        name: 'betty-windows',
        ymlName: 'latest.yml',
        error: '',
        fileType: ['yml'],
        refName: 'ymlFile',
        placeholder: 'Choose yml file',
        label: 'Windows OS',
      }
    }
  };


export const testFields = [
  'Name',
  'Version',
  'Uploaded',
  'Uploaded By',
  ''
];

export const publishedFields = [
  'Name',
  'Version',
  'Approved',
  'Published By',
  ''
];

export const approvedFields = [
  'Name',
  'Version',
  'Approved',
  'Approved By',
  ''
];
