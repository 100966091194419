import { apiService } from "../../api";
import { replacePathParams } from "../../../helpers/urlHelpers";

const ENDPOINTS = {
  CREATE: '/ticket-template',
  UPDATE: '/ticket-template/:id',
  GET_ONE: '/ticket-template/:id',
  GET_ALL: '/ticket-template',
  GET_PAGINATED: '/ticket-template?page=:page&limit=:limit',
  DELETE: '/ticket-template/:id'
};

class TicketTemplateService {
  async create(data) {
    return await apiService.post(ENDPOINTS.CREATE, data);
  }

  async update(id, data) {
    return await apiService.put(replacePathParams(ENDPOINTS.UPDATE, { id }), data);
  }

  async getTicketTemplate(id) {
    return await apiService.get(replacePathParams(ENDPOINTS.GET_ONE, { id }));
  }

  async getAllTicketTemplates() {
    return await apiService.get(ENDPOINTS.GET_ALL);
  }

  async getPaginatedTicketTemplates(page, limit) {
    return await apiService.get(replacePathParams(ENDPOINTS.GET_PAGINATED, {
      page, limit
    }));
  }

  async delete(id) {
    return await apiService.delete(replacePathParams(ENDPOINTS.DELETE, { id }));
  }
}

export const ticketTemplateService = new TicketTemplateService();
