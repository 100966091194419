import last from 'lodash/last';

export const checkEmptySpace = (value) => {
  return value.indexOf(' ') === -1;
}

export const checkFileExtensions = (value, extensions) => {
  const validationData = value.name.split('.');

  return extensions.indexOf(last(validationData)) === -1;
}