<template>
  <div class="l-main">
    <div class="l-main__header u-box">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a
            class="c-breadcrumbs__link"
            @click="goToCompanies"
          >
            <md-icon>home</md-icon>
          </a>
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link">Manage Versions</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <md-tabs class="c-tabs c-tabs--large">
        <md-tab
          v-for="tab in manageAppTabs"
          :id="tab.tab"
          :key="tab.tab"
          :md-label="tab.label"
          @click="changeActiveTab(tab.tab)"
        />
      </md-tabs>
      <os-page :type="currentTab" />
    </div>
  </div>
</template>

<script>
import OsPage from './components/os-page';

export default {
  components: {
    OsPage,
  },
  data() {
    return {
      currentTab: 'linux',
      manageAppTabs: [
        {
          tab: 'linux',
          label: 'Linux'
        },
        {
          tab: 'windows',
          label: 'Windows'
        },
        {
          tab: 'mac',
          label: 'Mac'
        }
      ]
    };
  },

  methods: {
    changeActiveTab(tab) {
      this.currentTab = tab;
    },

    goToCompanies() {
      if (this.user.role === 1) {
        return this.$router.push({ name: 'companies' });
      }

      if (this.$route.name === this.userAllowedRoutes[0]) {
        return;
      }

      this.userAllowedRoutes.includes('companies') ? this.$router.push({ name: 'companies' }) : this.$router.push({ name: this.userAllowedRoutes[0] });
    },
  },
};
</script>

<style></style>
