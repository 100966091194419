<template>
  <base-modal-component>
    <template v-slot:header />
    <template v-slot:body>
      <md-field class="c-input c-input--icon">
        <md-input v-model="licenceKey.stickerNo" placeholder="Enter Sticker No : " @input="validateInput" />
      </md-field>     
    </template>
    
    <template v-slot:footer>
      <div class="u-mr-xs">
        <generic-button
          variation="grey"
          @click="close()"
        >
          Close
      </generic-button>
      </div>
      <generic-button
        variation="red"
        :disabled="!isStickerNoValid"
        @click="accept()"
      >
        {{ type }}
      </generic-button>
    </template>
  </base-modal-component>
</template>

<script>

import BaseModalComponent from '../modal/BaseModalComponent';
import GenericButton from "../../shared/buttons/GenericButton";
import { mapActions, mapGetters } from "vuex";
import get from 'lodash/get';
import { betshopService } from '../../services/betshop';

export default {
  components: {
    BaseModalComponent,
    GenericButton
  },
  props: {
    licenceKey: {
      type: Object,
      reqired: true,
      default: () => ({})
    },
    onClose: {
      required: true,
      type: Function
    },
    stickerNoMsg: {
      required: true,
      type: String
    },
    type: {
      required: true,
      type: String
    },
  },
  data() {
    return {
      isStickerNoValid: false,
      previousValue : null ,
    }
  },
  computed: {
     ...mapGetters('company', ['getLicenceKeys']),
  },

  created() {
    this.previousValue = this.licenceKey.stickerNo;
  },

  methods: {
    ...mapActions('company', ['setLicenceKeys']),
    ...mapActions('response', ['handleSuccess', 'setGlobalError', 'handleError']),


    close() {
      this.licenceKey.stickerNo = this.previousValue;
      this.onClose();
      this.$emit('close');
    },

    accept() {
      this.type === 'UPDATE' ? this.updateStickerNoForLicenseKey() : this.createStickerNoForLicenseKey();
      this.$emit('close');
    },

    updateStickerNoForLicenseKey(){
      const betshopId = parseInt(get(this.$route, 'params.betshopId'));
      let matchedItem = this.getLicenceKeys.find(item => item.key === this.licenceKey.key);

      betshopService.updateStickerNoForLicenseKey(betshopId , this.licenceKey.key , this.licenceKey.stickerNo).then( () => {
        matchedItem.stickerNo = this.licenceKey.stickerNo;
        this.handleSuccess({message: 'Successfuly updated sticker no!'})
      })
      .catch(error => {
        matchedItem.stickerNo = this.previousValue;
        this.setGlobalError(error.statusText)
      });
    },

    createStickerNoForLicenseKey(){
      const betshopId = parseInt(get(this.$route, 'params.betshopId'));
      betshopService.generateNewLicenceKeyWithStickerNo(betshopId , this.licenceKey.stickerNo).then(response => {
        this.setLicenceKeys(response.data.licenceKeys);
        this.handleSuccess({message: 'Successfuly created licence key!'})
      }).catch(error => {
        this.setGlobalError(error.statusText)
      });
    },

    validateInput() {
      if (this.licenceKey.stickerNo === '' || this.licenceKey.stickerNo.includes(' ') || this.previousValue === this.licenceKey.stickerNo){
        this.isStickerNoValid = false;
        return;
      }
      this.isStickerNoValid = !isNaN(Number(this.licenceKey.stickerNo));
    },

  },
};
</script>