import { replacePathParams } from '../../helpers/urlHelpers';
import { apiService } from '../api'

const ENDPOINTS = {
  CREATE: '/additional-template-keys/save',
  GET: '/additional-template-keys',
  UPDATE: '/additional-template-keys/:id',
  DELETE: '/additional-template-keys/:id'
}

class AdditionalTemplateKeysService {
  async create(data) {
    return await apiService.post(ENDPOINTS.CREATE, data)
  }

  async update(data) {
    return await apiService.put(replacePathParams(ENDPOINTS.UPDATE, { id: data.id }), data)
  }

  async fetchAllAdditionalTemplateKeys(page, limit) {
    return await apiService.get(`${ENDPOINTS.GET}?page=${page}&limit=${limit}`);
  } 

  async deleteAdditionalTemplateKey(id) {
    return await apiService.delete(replacePathParams(ENDPOINTS.DELETE, { id }))
  }
}

export const additionalTemplateKeysService = new AdditionalTemplateKeysService()
