import { additionalTemplateKeysService } from '../../services/additional-template-keys';

function getInitialState() {
  return {
    additionalTemplateKeys: [],
    additionalTemplateKeysPaginationData: {}
  };
}

const state = getInitialState();

const actions = {
  async fetchAllAdditionalTemplateKeys({ commit, dispatch }, { currentPage, limit }) {
    try {
      dispatch('response/setGlobalError', null, { root: true });
      const savedAdditionalTemplateKeys = await additionalTemplateKeysService.fetchAllAdditionalTemplateKeys(currentPage, limit)
      commit('SET_ADDITIONAL_TEMPLATE_KEYS',
      {
        additionalTemplateKeys: savedAdditionalTemplateKeys.data.items,
        paginationData: {
          totalItems: savedAdditionalTemplateKeys.data.totalItems,
          pageCount: savedAdditionalTemplateKeys.data.pageCount
        }
      }
    )
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with WSBO', { root: true });
    }
  },
};

const getters = {
  getAllAdditionalTemplateKeys: state => state.additionalTemplateKeys,
  getAdditionalTemplateKeysPaginationData: state => state.additionalTemplateKeysPaginationData,
};

const mutations = {
  SET_ADDITIONAL_TEMPLATE_KEYS(state, data) {
    state.additionalTemplateKeys = data.additionalTemplateKeys
    state.additionalTemplateKeysPaginationData = data.paginationData
  },
};

export const additionalTemplate = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
