import { ticketService } from '../../services/ticket';

function getInitialState() {
  return {
    ticketKeys: [],
    ticketKeysPaginationData: {}
  };
}

const state = getInitialState();

const actions = {
  async fetchAllTicketKeys({ commit, dispatch }, { currentPage, limit }) {
    try {
      dispatch('response/setGlobalError', null, { root: true });
      const savedTicketKeys = await ticketService.fetchAllTicketKeys(currentPage, limit)
      commit('SET_TICKET_KEYS',
      {
        ticketKeys: savedTicketKeys.data.items,
        paginationData: {
          totalItems: savedTicketKeys.data.totalItems,
          pageCount: savedTicketKeys.data.pageCount
        }
      }
    )
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with WSBO', { root: true });
    }
  },
};

const getters = {
  getAllTicketKeys: state => state.ticketKeys,
  getTicketKeysPaginationData: state => state.ticketKeysPaginationData,
};

const mutations = {
  SET_TICKET_KEYS(state, data) {
    state.ticketKeys = data.ticketKeys
    state.ticketKeysPaginationData = data.paginationData
  },
};

export const ticket = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
