export const defaultWritableSections = [
  "companies",
  "ticket-template",
  "open-shift-template",
  "close-shift-template",
  "deposit-receipt-keys",
  "withdraw-receipt-keys",
  "all-translations",
  "sections",
  "languages",
  "pages",
  "ticket-keys",
  "open-shift-keys",
  "close-shift-keys",
  "drivers",
  "manage-versions",
  "betshop-versions",
  "hq-settings",
  "manage-printers",
  "additional-template",
  "additional-template-keys",
  "wingo-coupons-template",
  "wingo-template",
  "wingo-last-drawings-templates"
];
