<template>
  <div>
    <div class="c-add-template">
      <div class="c-add-template__ctn">
        <div class="c-code-editor-wrapper">
          <label>Name</label>
          <prism-editor
            v-model="template.name"
            :highlight="highlighter"
            class="c-code-editor c-code-editor--alt"
          />
        </div>
        <div class="c-code-editor-group">
          <div class="c-code-editor-wrapper u-mt-md">
            <label>HTML code</label>
            <prism-editor
              v-model="template.template"
              :highlight="highlighter"
              class="c-code-editor c-code-editor--lg"
            />
          </div>
          <div class="c-code-editor-wrapper u-mt-md">
            <label>CSS code</label>
            <prism-editor
              v-model="template.css"
              :highlight="highlighter"
              class="c-code-editor c-code-editor--lg"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="u-flex-justify-end">
      <generic-button
        variation="red"
        no-margin
        @click="updateAdditionalTemplate"
      >
        {{ buttonTitle }}
      </generic-button>
    </div>
  </div>
</template>

<script>
import GenericButton from "../../../shared/buttons/GenericButton";
import { PrismEditor } from "vue-prism-editor";
import "vue-prism-editor/dist/prismeditor.min.css";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";
import "prismjs/themes/prism-tomorrow.css";

export default {
  name: "WingoLastDrawingsTemplate",

  components: {
    GenericButton,
    PrismEditor,
  },

  props: ['selectedTemplate'],

  data() {
    return {
      template: {
        ...this.selectedTemplate
      }
    };
  },

  computed: {
    buttonTitle: function() {
      return this.selectedTemplate.id ? 'Update' : 'Create';
    }
  },

  methods: {
    updateAdditionalTemplate() {
      this.$emit("updateAdditionalTemplate", this.template);
    },
    highlighter(code) {
      return highlight(code, languages.js);
    },
  },
};
</script>

<style lang="scss" scoped>

.c-add-template {
  height: calc(100vh - 325px);
  overflow: auto;
  margin-bottom: 20px;
}
</style>
