<template>
  <div>
    <div class="c-form-wrapper u-overflow-auto">
      <div class="c-form__row u-mb-0">
        <generic-form-group
          class="c-form-group--permissions u-position-relative"
          :label="!marketIsSet ? 'Companies' : 'Selected companies:' "
        >
          <md-field v-if="!marketIsSet" class="c-select-field u-mb-0">
            <label>Companies search term</label>
            <md-input
              :disabled="marketIsSet"
              @click="clickOnCompanies"
              @input="filterCompaniesByTerm"
            />
          </md-field>
          <div :class="!marketIsSet ? 'c-show-more-wrapper ' : 'c-show-more-wrapper c-show-more-wrapper-selected' ">
            <p class="c-show-more-paragraph">
              <template v-if="(companies.length > selectedCompanies.length) || showAllSelectedCompanies">
                <span
                  v-for="(company, index) in showSelectedCompanies"
                  :key="index"
                >
                  {{ getSelectedEntity(company, index, 'showAllSelectedCompanies', 'selectedCompanies') }}
                </span>
              </template>
              <template v-if="(companies.length === selectedCompanies.length) && !showAllSelectedCompanies">
                <span>
                  All companies selected.
                </span>
              </template>
              <button
                v-if="selectedCompanies.length > displaySelectedNumber"
                class="c-show-more-button"
                @click="toggleShowAllSelected('showAllSelectedCompanies')"
              >
                {{ showAllSelectedCompanies ? 'See less' : 'See all' }}
              </button>
            </p>
          </div>
          <div
            v-if="showCompanies"
            v-click-outside="onClickOutside"
          >
            <ul class="c-search-multiselect__dropdown">
              <li>
                <md-checkbox
                  :value="!allSelectedCompanies"
                  class="c-dropdown__checkbox c-dropdown__checkbox--underline"
                  @change="selectOrUnselectAllCompanies"
                >
                  Select All Companies
                </md-checkbox>
              </li>
              <li
                v-for="(company) in filteredCompanies"
                :key="company.id"
              >
                <md-checkbox
                  v-model="selectedCompanies"
                  :value="company"
                  class="c-dropdown__checkbox"
                  @change="onCompanyChange"
                >
                  {{ company.name }}
                </md-checkbox>
              </li>
            </ul>
          </div>
        </generic-form-group>
      </div>
      <div class="c-form__row u-mb-0">
        <generic-form-group
          v-if="marketIsSet"
          class="c-form-group--permissions u-position-relative"
          :label="betshopIsSet ? 'Selected markets: ' : 'Markets'"
        >
          <md-field v-if="!betshopIsSet" class="c-select-field u-mb-0">
            <label>Market search term</label>
            <md-input
              :disabled="betshopIsSet"
              @click="clickOnMarkets"
              @input="filterMarketsByTerm"
            />
          </md-field>
          <div :class="!betshopIsSet ? 'c-show-more-wrapper ' : 'c-show-more-wrapper c-show-more-wrapper-selected' ">
            <p class="c-show-more-paragraph">
              <template v-if="(markets.length > selectedMarkets.length) || showAllSelectedMarkets">
                <span
                  v-for="(market, index) in showSelectedMarkets"
                  :key="index"
                >
                  {{ getSelectedEntity(market, index, 'showAllSelectedMarkets', 'selectedMarkets') }}
                </span>
              </template>
              <template v-if="(markets.length === selectedMarkets.length) && !showAllSelectedMarkets">
                <span>
                  All markets selected.
                </span>
              </template>
              <button
                v-if="selectedMarkets.length > displaySelectedNumber"
                class="c-show-more-button"
                @click="toggleShowAllSelected('showAllSelectedMarkets')"
              >
                {{ showAllSelectedMarkets ? 'See less' : 'See all' }}
              </button>
            </p>
          </div>
          <div
            v-if="showMarkets"
            v-click-outside="onClickOutside"
          >
            <ul class="c-search-multiselect__dropdown">
              <li>
                <md-checkbox
                  :value="!allMarketsSelected"
                  class="c-dropdown__checkbox c-dropdown__checkbox--underline"
                  @change="selectOrUnselectAllMarkets"
                >
                  Select All Markets
                </md-checkbox>
              </li>
              <template v-for="(group, index) in groupedMarkets">
                <p :key="index" class="c-dropdown__label">
                  <md-checkbox
                    class="c-dropdown__checkbox"
                    :value="!isAllCompaniesMarketsSelected(index, group)"
                    @change="selectOrUnselectAllMarketsFromCompany(index, group)"
                  >
                    {{ getCompanyName(index) }}
                  </md-checkbox>
                </p>
                <li
                  v-for="(market) in group"
                  :key="'m' + market.id"
                >
                  <md-checkbox
                    v-model="selectedMarkets"
                    :value="market"
                    class="c-dropdown__checkbox c-dropdown__checkbox--indent"
                    @change="onmarketChange"
                  >
                    {{ market.name }}
                  </md-checkbox>
                </li>
              </template>
            </ul>
          </div>
        </generic-form-group>
      </div>
      <div class="c-form__row u-mb-0">
        <generic-form-group
          v-if="betshopIsSet"
          class="c-form-group--permissions u-position-relative"
          :label="'Bethops'"
        >
          <md-field class="c-select-field u-mb-0">
            <label>Betshops search term</label>
            <md-input
              @click="clickOnBetshops"
              @input="filterBetshopsByTerm"
            />
          </md-field>
          <div class="c-show-more-wrapper">
            <p class="c-show-more-paragraph">
              <template v-if="(betshops.length > selectedBetshops.length) || showAllSelectedBetshops">
                <span
                  v-for="(betshop, index) in showSelectedBetshop"
                  :key="index"
                >
                  {{ getSelectedEntity(betshop, index, 'showAllSelectedBetshops', 'selectedBetshops') }}
                </span>
              </template>
              <template v-if="(betshops.length === selectedBetshops.length) && !showAllSelectedBetshops">
                <span>
                  All betshops selected.
                </span>
              </template>
              <button
                v-if="selectedBetshops.length > displaySelectedNumber"
                class="c-show-more-button"
                @click="toggleShowAllSelected('showAllSelectedBetshops')"
              >
                {{ showAllSelectedBetshops ? 'See less' : 'See all' }}
              </button>
            </p>
          </div>
          <div
            v-if="showBetshops"
            v-click-outside="onClickOutside"
          >
            <ul class="c-search-multiselect__dropdown">
              <li>
                <md-checkbox
                  :value="!allBetshopsSelected"
                  class="c-dropdown__checkbox c-dropdown__checkbox--underline"
                  @change="selectOrUnselectAllBetshops"
                >
                  Select All Betshops
                </md-checkbox>
              </li>
              <template v-if="!selectAllBetshopsChecked">
                <template v-for="(group, index) in groupedBetshops">
                  <p :key="`betshop-group-${index}`" class="c-dropdown__label">
                    <md-checkbox
                      class="c-dropdown__checkbox"
                      :value="!isAllBetshopsFromMarketSelected(index, group)"
                      @change="selectOrUnselectAllBetshopsFromMarket(index, group)"
                    >
                      {{ getMarketName(index) }}
                    </md-checkbox>
                  </p>
                  <template v-if="!selectedBetshopGroups[index]">
                    <li
                      v-for="(betshop) in group"
                      :key="`betshop-${betshop.id}`"
                    >
                      <md-checkbox
                        v-model="selectedBetshops"
                        :value="betshop"
                        class="c-dropdown__checkbox c-dropdown__checkbox--indent"
                      >
                        {{ betshop.name }}
                      </md-checkbox>
                    </li>
                  </template>
                </template>
              </template>
            </ul>
          </div>
        </generic-form-group>
      </div>
      <div v-if="marketIsSet > 0 && showMarketPermissions">
        <div class="c-form__permissions-actions">
          <div class="c-form__permissions-actions--inner">
            <span>Read</span>
            <span>Write</span>
          </div>
        </div>
        <div
          v-for="(permissionSection, index) in defaultUserPermissions.hierarchy"
          :key="permissionSection.sectionName"
          class="c-table c-table--permissions"
        >
          <template v-if="permissionSection.market">
            <div class="c-table__head">
              <div class="c-table__cell c-table__cell--permissions u-text-align-left">
                {{ formatLabel(index) }}
              </div>
            </div>
            <div
              v-for="permission in permissionSection.permissions"
              :key="permission.pageTab"
              class="c-table__body"
            >
              <div v-if="permission.pageTab" class="c-table__row">
                <div class="c-table__cell c-table__cell--permissions u-ph-xl">
                  {{ formatLabel(permission.pageTab) }}
                </div>
                <div class="c-table__permissions-actions">
                  <div class="u-flex-justify-around">
                    <md-checkbox v-model="permission.marketRead" class="u-mv-0" @change="onChangeReadPermission()" />
                    <md-checkbox
                      v-model="permission.marketWrite"
                      class="u-mv-0"
                      @change="onChangeWritePermission($event, permission, index, 'market')"
                    />
                  </div>
                </div>
              </div>
              <div v-else class="c-table__row">
                <div class="c-table__cell c-table__cell--permissions u-ph-xl">
                  {{ formatLabel(permission.page) }}
                </div>
                <div class="c-table__permissions-actions">
                  <div class="u-flex-justify-around">
                    <md-checkbox v-model="permission.marketRead" class="u-mv-0" @change="onChangeReadPermission()" />
                    <md-checkbox
                      v-model="permission.marketWrite"
                      class="u-mv-0"
                      @change="onChangeWritePermission($event, permission, index, 'market')"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
      <div v-if="showBetshopsPermissions && betshopIsSet">
        <div class="c-form__permissions-actions">
          <div class="c-form__permissions-actions--inner">
            <span>Read</span>
            <span>Write</span>
          </div>
        </div>
        <div
          v-for="(permissionSection, index) in defaultUserPermissions.hierarchy"
          :key="permissionSection.sectionName"
          class="c-table c-table--permissions"
        >
          <template v-if="permissionSection.betshop">
            <div class="c-table__head">
              <div class="c-table__cell c-table__cell--permissions u-text-align-left">
                {{ formatLabel(index) }}
              </div>
            </div>
            <div
              v-for="permission in permissionSection.permissions"
              :key="permission.pageTab"
              class="c-table__body"
            >
              <div v-if="permission.pageTab" class="c-table__row">
                <div class="c-table__cell c-table__cell--permissions u-ph-xl">
                  {{ formatLabel(permission.pageTab) }}
                </div>
                <div class="c-table__permissions-actions">
                  <div class="u-flex-justify-around">
                    <md-checkbox v-model="permission.betshopRead" class="u-mv-0" @change="onChangeReadPermission()" />
                    <md-checkbox
                      v-model="permission.betshopWrite"
                      class="u-mv-0"
                      @change="onChangeWritePermission($event, permission, index)"
                    />
                  </div>
                </div>
              </div>
              <div v-else class="c-table__row">
                <div class="c-table__cell c-table__cell--permissions u-ph-xl">
                  {{ formatLabel(permission.page) }}
                </div>
                <div class="c-table__permissions-actions">
                  <div class="u-flex-justify-around">
                    <md-checkbox v-model="permission.betshopRead" class="u-mv-0" @change="onChangeReadPermission()" />
                    <md-checkbox
                      v-model="permission.betshopWrite"
                      class="u-mv-0"
                      @change="onChangeWritePermission($event, permission, index)"
                    />
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <div class="u-flex-justify-end u-mr-md u-mb-md">
      <div class="u-mr-md">
        <generic-button
          variation="red"
          @click="handleBack"
        >
          {{ getBackOrCancelLabel }}
        </generic-button>
      </div>
      <generic-button
        variation="red"
        :disabled="!isDisabled()"
        @click="handleNextStep"
      >
        {{ getNextLabelOrSubmit }}
      </generic-button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import GenericButton from '../../../shared/buttons/GenericButton';
import GenericFormGroup from '../../../shared/form-group/GenericFormGroup';
import filter from 'lodash/filter';
import map from 'lodash/map';
import each from 'lodash/each';
import capitalize from "lodash/capitalize";
import replace from "lodash/replace";
import groupBy from "lodash/groupBy";
import findIndex from "lodash/findIndex";
import { companyService } from '../../../services/company';
import vClickOutside from 'v-click-outside'
import { userService } from '../../../services/user';
import { hierarchyPermission } from '../../../const/permissions';
import { responseService } from '../../../services/response';
import set from "lodash/set";
import get from "lodash/get";
import cloneDeep from "lodash/cloneDeep";

export default {
  name: "HierarchyPermission",
  components: {
    GenericButton,
    GenericFormGroup
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      defaultUserPermissions: {},
      currentUserPermissions: {},
      permissionsToUpdate: {
        companyIds: [],
        marketIds: []
      },
      currentUser: {},
      showCompanies: false,
      companies: [],
      selectedCompanies: [],
      selectedMarkets: [],
      selectedBetshops: [],
      showMarkets: false,
      showBetshops: false,
      filteredCompanies: [],
      markets: [],
      betshops: [],
      groupedMarkets: [],
      filteredMarkets: [],
      companyTerm: '',
      marketTerm: '',
      checkAllMarkets: false,
      groupedBetshops: [],
      betshopsTerm: '',
      showMarketPermissions: false,
      showBetshopsPermissions: false,
      allCategoriesSelected: false,
      companiesIsSet: false,
      marketIsSet: false,
      betshopIsSet: false,
      displaySelectedNumber: 7,
      showAllSelectedCompanies: false,
      showAllSelectedMarkets: false,
      showAllSelectedBetshops: false,
      selectAllBetshopsChecked: false,
      selectedBetshopGroups: {},
    };
  },
  computed: {
    ...mapGetters('company', ['getAllCompanies']),
    ...mapGetters("account", ["user"]),

    allSelectedCompanies() {
      return this.selectedCompanies.length === this.filteredCompanies.length
    },
    allMarketsSelected() {
      return this.selectedMarkets.length === this.markets.length;
    },
    allBetshopsSelected() {
      return this.selectedBetshops.length === this.betshops.length;
    },
    getNextLabelOrSubmit() {
      if (this.isAllPermissionSet) {
        return 'save';
      }

      if (this.isCompaniesAndMarketSet) {
        return 'next to betshops';
      }

      return 'next to markets';
    },
    getBackOrCancelLabel() {
      if (this.isAllPermissionSet) {
        return 'back to markets';
      }

      if (this.isCompaniesAndMarketSet) {
        return 'back to companies';
      }

      return 'cancel';
    },
    isAllPermissionSet() {
      return this.companiesIsSet && this.marketIsSet && this.betshopIsSet;
    },
    isCompaniesAndMarketSet() {
      return this.companiesIsSet && this.marketIsSet;
    },
    showSelectedCompanies() {
      return this.showAllSelectedCompanies ?
        this.selectedCompanies :
        this.selectedCompanies.slice(0, this.displaySelectedNumber);
    },
    showSelectedMarkets() {
      return this.showAllSelectedMarkets ?
        this.selectedMarkets :
        this.selectedMarkets.slice(0, this.displaySelectedNumber);
    },
    showSelectedBetshop() {
      return this.showAllSelectedBetshops ?
        this.selectedBetshops :
        this.selectedBetshops.slice(0, this.displaySelectedNumber);
    },
  },
  async created() {
    await this.fetchCompanies(1, 999999, '');
    this.setUpCurrentUserPermissions();
  },
  methods: {
    ...mapActions('company', ['getCompanies']),
    ...mapActions("response", ["handleSuccess", "handleError"]),
    async fetchCompanies(page, limit, term) {
      await this.getCompanies({ page, limit, term });
      this.companies = [...this.getAllCompanies];
      this.companiesIsSet = true;
    },
    async getMarkets() {
      const companyIds = map(this.selectedCompanies, company => company.id);
      if (companyIds.length === 0) {
        return;
      }
      const response = await companyService.getCompaniesMarkets(JSON.stringify(companyIds));
      this.markets = response.data;
      this.groupedMarkets = groupBy(this.markets, 'companyId');
      this.showBetshopsPermissions = false;
      this.showMarketPermissions = true;
      this.showAllSelectedCompanies = false;
      this.marketIsSet = true;
    },
    async setUpCurrentUserPermissions() {
      const { data } = await userService.getSingleUser(this.$route.params.id);
      this.currentUser = data;
      this.defaultUserPermissions = cloneDeep(hierarchyPermission);
      if (!this.currentUser.hierarchyPermissions) {
        return this.fetchCompaniesMarketsBetshopsFromOldSelected();
      }

      const parsedUserPermissions = JSON.parse(
        this.currentUser.hierarchyPermissions
      );

      this.setDefaultHierarchAndIds(parsedUserPermissions);
      this.fetchCompaniesMarketsBetshopsFromOldSelected();
    },
    async fetchCompaniesMarketsBetshopsFromOldSelected() {
      await this.setSelectedCompanies();
      this.setSelectedMarkets();
      if (get(this.defaultUserPermissions, 'betshopIds.length') === 0) {
        return;
      }
      await this.getBetshops();
    },
    async savePermissions() {
      try {
        this.permissionsToUpdate = {
          hierarchy: {}
        };
        this.setIds();

        each(this.defaultUserPermissions.hierarchy, (section, index) => {
          set(this.permissionsToUpdate.hierarchy, index, section);
        });

        this.currentUser.hierarchyPermissions = JSON.stringify(this.permissionsToUpdate);
        this.currentUser.adminId = this.user.id;
        await userService.update(this.currentUser);
        this.handleSuccess({
          message: "Users permissions updated successfully.",
        });
      } catch (err) {
        this.handleError(responseService.getErrorMessage(err.data.message));
      }
      this.$emit('canChangeTab', false);
    },
    async getBetshops() {
      const marketIds = map(this.selectedMarkets, market => market.id);
      if (marketIds.length === 0) {
        return;
      }
      const response = await companyService.getMarketsBetShops(JSON.stringify(marketIds));
      this.betshops = response.data;
      this.groupedBetshops = groupBy(this.betshops, 'marketId');
      this.showMarketPermissions = false;
      this.showBetshopsPermissions = true;
      this.betshopIsSet = true;
      this.showAllSelectedCompanies = false;
      this.showAllSelectedMarkets = false;

      if (get(this.defaultUserPermissions, 'betshopIds[0]') === 0) {
        return this.selectedBetshops = [...this.betshops];
      }

      this.selectedBetshops = filter(
        this.betshops,
        betshop => this.defaultUserPermissions.betshopIds.includes(betshop.id)
      );
    },
    async setSelectedCompanies() {
      if (!this.defaultUserPermissions.companyIds.length > 0) {
        return;
      }

      this.checkIsAllCompaniesSelected();
      await this.getMarkets();
    },
    onCompanyChange() {
      this.markets = [];
      this.selectedMarkets = [];
      this.betshops = [];
      this.selectedBetshops = [];
    },
    onmarketChange() {
      this.betshops = [];
      this.selectedBetshops = [];
    },
    filterCompaniesByTerm(term) {
      this.companyTerm = term;
      this.filteredCompanies = filter(
        this.companies,
        company => company.name.toLowerCase().includes(term.toLowerCase())
      );
    },
    filterMarketsByTerm(term) {
      this.marketTerm = term;
      this.groupedMarkets = groupBy(
        filter(this.markets, c => c.name.toLowerCase().includes(term.toLowerCase()))
        , 'companyId');
    },
    filterBetshopsByTerm(term) {
      this.betshopsTerm = term;
      this.groupedBetshops = groupBy(
        filter(this.betshops, b => b.name.toLowerCase().includes(term.toLowerCase()))
        , 'marketId');
    },
    clickOnCompanies() {
      this.showMarkets = false;
      this.showCompanies = true;
      this.showBetshops = false;
      if (this.companyTerm) {
        return;
      }
      this.filteredCompanies = [...this.companies];
    },
    clickOnMarkets() {
      this.showCompanies = false;
      this.showMarkets = true;
      this.showBetshops = false;
      if (this.marketTerm) {
        return;
      }
      this.groupedMarkets = groupBy([...this.markets], 'companyId');
    },
    clickOnBetshops() {
      this.showCompanies = false;
      this.showMarkets = false;
      this.showBetshops = true;
      if (this.betshopsTerm) {
        return;
      }
      this.groupedBetshops = groupBy(this.betshops, 'marketId');
    },
    getSelectedCompanyNames() {
      if (this.isAllCompaniesSelected()) {
        return 'All companies selected.';
      }
      return map(this.selectedCompanies, company => ` ${company.name}`).toString();
    },
    getSelectedMarketsNames() {
      if (this.isAllMarketsSelected()) {
        return this.markets.length > 0 ? 'All markets selected.' : 'No markets available!';
      }
      return map(this.selectedMarkets, market => ` ${market.name}`).toString();
    },
    getSelectedBetshopsNames() {
      if (this.isAllBethopsSelected()) {
        return this.betshops.length > 0 ? 'All betshops selected.' : 'No betshops available!';
      }
      return map(this.selectedBetshops, betshop => ` ${betshop.name}`).toString();
    },
    onClickOutside() {
      this.showMarkets = false;
      this.showCompanies = false;
      this.showBetshops = false;
    },
    formatLabel(label) {
      return capitalize(replace(label, new RegExp("-","g")," "));
    },
    setIds() {
      this.permissionsToUpdate.companyIds = [];
      this.permissionsToUpdate.marketIds = [];
      this.permissionsToUpdate.betshopIds = [];
      this.setCompaniesIds();
      this.setMarketIds();
      this.setBetshopIds();
    },
    isAllCompaniesSelected() {
      return this.selectedCompanies.length === this.companies.length;
    },
    isAllMarketsSelected() {
      return this.selectedMarkets.length === this.markets.length;
    },
    isAllBethopsSelected() {
      return this.selectedBetshops.length === this.betshops.length;
    },
    getCompanyName(id) {
      const index = findIndex(this.companies, { id: parseInt(id, 10) });

      return index >= 0 ? this.companies[index].name : '';
    },
    getMarketName(id) {
      const index = findIndex(this.markets, { id: parseInt(id, 10) });

      return index >= 0 ? this.markets[index].name : '';
    },
    goToUsers() {
      this.$router.push({ name: 'users' });
    },
    setCompaniesIds() {
      if (!this.permissionsToUpdate.companyIds) {
        return;
      }

      if (!this.isAllCompaniesSelected()) {
        return this.permissionsToUpdate.companyIds = map(this.selectedCompanies, c => c.id);
      }

      this.permissionsToUpdate.companyIds.push(0);
    },
    setMarketIds() {
      if (!this.permissionsToUpdate.marketIds) {
        return;
      }

      if (!this.isAllMarketsSelected()) {
        return this.permissionsToUpdate.marketIds = map(this.selectedMarkets, m => m.id);
      }

      this.permissionsToUpdate.marketIds.push(0);
    },
    setBetshopIds() {
      if (this.selectedBetshops.length === 0) {
        return;
      }

      if (!this.isAllBethopsSelected()) {
        return this.permissionsToUpdate.betshopIds = map(this.selectedBetshops, b => b.id);
      }

      this.permissionsToUpdate.betshopIds.push(0);
    },
    checkIsAllCompaniesSelected() {
      if (this.defaultUserPermissions.companyIds[0] === 0) {
        return this.selectedCompanies = [...this.companies];
      }

      this.selectedCompanies = filter(this.companies, company => this.defaultUserPermissions.companyIds.includes(company.id));
    },
    setSelectedMarkets() {
      if (this.defaultUserPermissions.marketIds.length === 0) {
        return;
      }

      if (this.defaultUserPermissions.marketIds[0] === 0) {
        return this.selectedMarkets = [...this.markets];
      }
      this.selectedMarkets = filter(
        this.markets,
        market => this.defaultUserPermissions.marketIds.includes(market.id)
      );
    },
    setDefaultHierarchAndIds(parsedUserPermissions) {
      const defauleHierarchy = cloneDeep(hierarchyPermission);
      each(this.defaultUserPermissions.hierarchy, (section, index) => {
        if (parsedUserPermissions.hierarchy[index]) {
          return set(
            this.defaultUserPermissions.hierarchy,
            index,
            get(parsedUserPermissions.hierarchy, index)
          );
        }

        this.defaultUserPermissions.hierarchy[index] = get(defauleHierarchy.hierarchy, index);
      });

      this.defaultUserPermissions.companyIds = parsedUserPermissions.companyIds;
      this.defaultUserPermissions.marketIds = parsedUserPermissions.marketIds;
      this.defaultUserPermissions.betshopIds = parsedUserPermissions.betshopIds;
    },
    onChangeWritePermission(value, field, section, key = 'betshop') {
      if (!value) {
        return;
      }

      each(this.defaultUserPermissions.hierarchy[section].permissions, permission => {
        if (permission.pageTab === field.pageTab) {
          permission[`${key}Write`] = value;
          permission[`${key}Read`] = value;

          return false;
        }
      });
      this.$emit('canChangeTab', true);
    },
    onChangeReadPermission() {
      this.$emit('canChangeTab', true);
    },
    selectOrUnselectAllCompanies() {
      if(this.allSelectedCompanies) {
        this.selectedCompanies = [];
      } else {
        this.selectedCompanies = [...this.filteredCompanies]
      }

      this.onCompanyChange();
    },

    selectOrUnselectAllMarkets() {
      if(this.allMarketsSelected) {
        this.selectedMarkets = [];
      } else {
        this.selectedMarkets = []
        Object.keys(this.groupedMarkets).forEach((key) => {
          this.selectedMarkets.push(...this.groupedMarkets[key])
        })

      }

      this.onmarketChange();
    },

    selectOrUnselectAllBetshops() {
      if(this.allBetshopsSelected) {
        this.selectedBetshops = [];
        this.selectAllBetshopsChecked = false;
      } else {
        this.selectAllBetshopsChecked = true;
        this.selectedBetshops = []
        Object.keys(this.groupedBetshops).forEach((key) => {
          this.selectedBetshops.push(...this.groupedBetshops[key])
        })
      }
      this.$emit('canChangeTab', true);
    },

    isAllCompaniesMarketsSelected(id, group) {
      const filteredMarkets = this.selectedMarkets.filter((market) => market.companyId ===  parseInt(id, 10))

      return filteredMarkets.length === group.length
    },

    selectOrUnselectAllMarketsFromCompany(id, group) {
       if(this.isAllCompaniesMarketsSelected(id, group)) {
          this.selectedMarkets = this.selectedMarkets.filter((market) => market.companyId !==  parseInt(id, 10))
        } else {
          this.selectedMarkets = this.selectedMarkets.filter((market) => market.companyId !==  parseInt(id, 10))
          this.selectedMarkets.push(...group)
      }
      this.onmarketChange();
      this.$emit('canChangeTab', true);
    },

    isAllBetshopsFromMarketSelected(id, group) {
      const filteredBetshops = this.selectedBetshops.filter((betshop) => betshop.marketId ===  parseInt(id, 10))

      return filteredBetshops.length === group.length
    },

    selectOrUnselectAllBetshopsFromMarket(id, group) {
      if(this.isAllBetshopsFromMarketSelected(id, group)) {
        this.selectedBetshopGroups = {...this.selectedBetshopGroups, [id]: false}
        this.selectedBetshops = this.selectedBetshops.filter((betshop) => betshop.marketId !==  parseInt(id, 10))
      } else {
        this.selectedBetshopGroups = {...this.selectedBetshopGroups, [id]: true}
        this.selectedBetshops = this.selectedBetshops.filter((betshop) => betshop.marketId !==  parseInt(id, 10))
        this.selectedBetshops.push(...group)
      }

      this.$emit('canChangeTab', true);
    },

    handleNextStep() {
      if (this.isAllPermissionSet) {
        return this.savePermissions();
      }

      if (this.isCompaniesAndMarketSet) {
        return this.getBetshops();
      }

      if (this.companiesIsSet) {
        return this.getMarkets();
      }
    },

    handleBack() {
      this.showAllSelectedBetshops = false;
      this.showAllSelectedMarkets = false;
      this.showAllSelectedCompanies = false;

      if (this.isAllPermissionSet) {
        return this.handleBackOnBetshops();
      }

      if (this.isCompaniesAndMarketSet) {
        return this.handleBackOnMarkets();
      }

      this.goToUsers();
    },

    handleBackOnBetshops() {
      this.groupedBetshops = [];
      this.selectedBetshops = [];
      this.showMarketPermissions = true;
      this.showBetshopsPermissions = false;
      this.betshopIsSet = false;
    },

    handleBackOnMarkets() {
      this.markets = [];
      this.groupedMarkets = [];
      this.showBetshopsPermissions = false;
      this.showMarketPermissions = false;
      this.marketIsSet = false;
    },

    isDisabled() {
      if (this.isAllPermissionSet) {
        return this.selectedBetshops.length > 0;
      }

      if (this.isCompaniesAndMarketSet) {
        return this.selectedMarkets.length > 0;
      }

      if (this.companiesIsSet) {
        return this.selectedCompanies.length > 0;
      }
    },

    canShowBetshopsTable() {
      return this.showBetshopsPermissions && this.betshopIsSet;
    },

    getSelectedEntity(entitiy, index, showAllSelected, selectedEntity) {
      const length = this[showAllSelected] ?
        this[selectedEntity].length :
        this[selectedEntity].slice(0, this.displaySelectedNumber).length;

      const lastName = this[selectedEntity].length > length ?
        `${entitiy.name} ...` :
        entitiy.name;

      return length === (index +1) ? lastName : `${entitiy.name}, `;
    },
    toggleShowAllSelected(showAllType) {
      this[showAllType] = !this[showAllType];
    }
  }
};
</script>
