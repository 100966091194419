export const defaultSidebarItems = [
  {
    key: "users",
    pathName: "users",
    icon: "accessibility",
    text: "Users",
    subItems: [],
  },
  {
    key: "companies",
    pathName: "companies",
    icon: "account_balance",
    text: "Companies",
    subItems: [],
  },
  {
    key: "templates",
    pathName: "templates",
    icon: "insert_drive_file",
    text: "Templates",
    subItems: [],
  },
  {
    key: "translations",
    pathName: null,
    icon: "dehaze",
    text: "Translations",
    expandedSync: false,
    subItems: [
      {
        key: "all-translations",
        pathName: "all-translations",
        icon: "assignment",
        text: "All translations",
        subItems: [],
      },
      {
        key: "sections",
        pathName: "sections",
        icon: "folder",
        text: "Sections",
        subItems: [],
      },
      {
        key: "languages",
        pathName: "languages",
        icon: "language",
        text: "Languages",
        subItems: [],
      },
    ],
  },
  {
    key: "configurations",
    pathName: null,
    icon: "settings",
    text: "Configurations",
    expandedSync: false,
    subItems: [
      {
        key: "pages",
        pathName: "pages",
        icon: "description",
        text: "Pages",
        subItems: [],
      },
      {
        key: "shift-keys",
        pathName: "shift-keys",
        icon: "description",
        text: "Shift template keys",
        subItems: [],
      },
      {
        key: "receipt-keys",
        pathName: "receipt-keys",
        icon: "description",
        text: "Receipt template keys",
        subItems: [],
      },
      {
        key: "ticket-keys",
        pathName: "ticket-keys",
        icon: "description",
        text: "Ticket template keys",
        subItems: [],
      },
      {
        key: "hq-settings",
        pathName: "hq-settings",
        icon: "description",
        text: "HQ Settings",
        subItems: [],
      },
      {
        key: "wingo-settings",
        pathName: "wingo-settings",
        icon: "description",
        text: "Wingo Settings",
        subItems: [],
      },
      {
        key: "additional-template-keys",
        pathName: "additional-template-keys",
        icon: "description",
        text: "Additional template keys",
        subItems: [],
      },
    ],
  },
  {
    key: "manage-printers",
    pathName: "manage-printers",
    icon: "print",
    text: "Manage Printers",
    subItems: [],
  },
  {
    key: "manage-drivers",
    pathName: "manage-drivers",
    icon: "build",
    text: "Manage Drivers",
    subItems: [],
  },
  {
    key: "manage-versions",
    pathName: "manage-versions",
    icon: "build",
    text: "Manage Versions",
    subItems: [],
  },
  {
    key: "betshop-versions",
    pathName: "betshop-versions",
    icon: "build",
    text: "Betshop Versions",
    subItems: []
  }
];
