<template>
  <base-modal-component>
    <template v-slot:header>
      <span class="md-subheading u-typography-color">
        {{ confirmationMsg }}
      </span>
    </template>

    <template v-slot:footer>
      <div class="u-mr-xs">
        <generic-button
          variation="grey"
          @click="close()"
        >
          Close
        </generic-button>
      </div>
      <generic-button
        variation="red"
        @click="accept()"
      >
        Accept
      </generic-button>
    </template>
  </base-modal-component>
</template>

<script>

import BaseModalComponent from '../modal/BaseModalComponent';
import GenericButton from "../../shared/buttons/GenericButton";
import { mapActions } from "vuex";

export default {
  components: {
    BaseModalComponent,
    GenericButton
  },
  props: {
    propData: {
      type: Number,
      reqired: true,
      default: 1
    },
    onAccept: {
      required: true,
      type: Function
    },
    onClose: {
      required: true,
      type: Function
    },
    pageData: {
      required: false,
      type: Object,
      default: null
    },
    getDataFunction: {
      required: false,
      type: Function,
      default: () => {}
    },
    confirmationMsg: {
      required: true,
      type: String
    }
  },
  methods: {
    ...mapActions("response", ["handleSuccess"]),

    close() {
      this.onClose();
      this.$emit('close');
    },

    accept() {
      this.onAccept();
      this.$emit('close');
    },
  },
};
</script>
