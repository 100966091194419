import { apiService } from '../api'
import { store } from '../../store/store'

const ENDPOINTS = {
  CREATE: '/translation/export/'
}

class ExportService {
  async downloadFile(lang, code) {
    apiService.exportCsv(ENDPOINTS.CREATE + lang)
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `language-${code}-translations.csv`)
        document.body.appendChild(link)
        link.click()
      })
      .catch(() => store.dispatch('response/setGlobalError', 'There is no available translations for given language!'));
  }
}

export const exportService = new ExportService()
