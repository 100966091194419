<template>
  <div v-if="isDataFetch">
    <div class="c-form-wrapper--small-wingo-coupons u-overflow-auto">
      <div v-if="type === 'betshop'" class="c-form__checkbox">
        <md-checkbox
          v-model="betshopWingoCouponsEnabled"
          :disabled="!hasWritePermision"
          class="c-form-checkbox__checkmark"
        /><span>Enable Configuration</span>
      </div>
      <div class="c-form">
        <md-field>
          <label for="Daily limit">Daily limit</label>
          <md-input
            v-model="wingoCnfiguration.dailyLimit"
            :disabled="!hasWritePermision || !betshopWingoCouponsEnabled"
            name="Daily limit"
            type="number"
            onkeydown="if(event.key==='.' || event.key===',' || event.key==='e'){event.preventDefault();}" 
            @change="onChangeSectionOrTamplate"
          />
        </md-field>
        <md-field ca>
          <label for="Weekly limit">Weekly limit</label>
          <md-input
            v-model="wingoCnfiguration.weeklyLimit"
            :disabled="!hasWritePermision || !betshopWingoCouponsEnabled"
            name="Weekly limit"
            type="number"
            onkeydown="if(event.key==='.' || event.key===',' || event.key==='e'){event.preventDefault();}" 
            @change="onChangeSectionOrTamplate"
          />
        </md-field>
        <md-field>
          <label for="Monthly limit">Monthly limit</label>
          <md-input
            v-model="wingoCnfiguration.monthlyLimit"
            :disabled="!hasWritePermision || !betshopWingoCouponsEnabled"
            name="Monthly limit"
            type="number"
            onkeydown="if(event.key==='.' || event.key===',' || event.key==='e'){event.preventDefault();}" 
            @change="onChangeSectionOrTamplate"
          />
        </md-field>
        <md-field>
          <label for="Min value of coupon">Min value of coupon</label>
          <md-input
            v-model="wingoCnfiguration.minValue"
            :disabled="!hasWritePermision || !betshopWingoCouponsEnabled"
            name="Min value of coupon"
            type="number"
            @change="onChangeSectionOrTamplate"
          />
        </md-field>
        <md-field>
          <label for="Max value of coupon">Max value of coupon</label>
          <md-input
            v-model="wingoCnfiguration.maxValue"
            :disabled="!hasWritePermision || !betshopWingoCouponsEnabled"
            name="Max value of coupon"
            type="number"
            @change="onChangeSectionOrTamplate"
          />
        </md-field>
        <md-field class="c-select-field c-select">
          <label for="Currency of coupon">Currency of coupon</label>
          <label>{{ wingoCnfiguration.currency ? '' : wingoCnfiguration.currency }}</label>
          <md-select
            v-model="wingoCnfiguration.currency"
            @md-selected="onChangeSectionOrTamplate"
          >
            <md-option
              v-for="(singleCurrency) in currency"
              :key="singleCurrency.code"
              :value="singleCurrency.code"
            >
              {{ singleCurrency.code }}
            </md-option>
          </md-select>
        </md-field>
      </div>
    </div>
    <div class="u-flex-justify-end u-mr-md u-mb-md">
      <div class="u-mr-md">
        <generic-button
          variation="red"
          :disabled="!hasWritePermision"
          @click="clearSettings"
        >
          Clear All
        </generic-button>
      </div>
      <generic-button
        variation="red"
        :disabled="!hasWritePermision"
        @click="updateSettings"
      >
        Update
      </generic-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import get from "lodash/get";
import GenericButton from "../../../shared/buttons/GenericButton";
import { hasWritePermission } from "../../../helpers/permission";
import { wingoCouponsTemplateService } from "../../../services/betshop/wingo-coupons-template";
import { validationBaseMixin } from "../../../mixins/ValidationBaseMixin";

export default {
  name: "WingoCouponsSettings",
  components: {
    GenericButton,
  },
  mixins: [validationBaseMixin],
  props: {
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selectedType: "market",
      additionalTemplates: [],
      selectedTemplateId: null,
      clearAll: false,
      hasWritePermision: false,
      betshopWingoCouponsEnabled: true,
      isDataFetch: false,
      currency: [],
      wingoCnfiguration: {
        dailyLimit: null,
        weeklyLimit: null,
        monthlyLimit: null,
        maxValue: null,
        minValue: null,
        currency: '',
      },
    };
  },

  computed: {
    ...mapGetters("company", [
      "getApiConfigurationsData",
      "getApiMarketConfigurationsData",
      "getApiBetshopConfigurationsData",
    ]),
    ...mapState('company', ['marketName']),
    ...mapGetters("additionalTemplate", ["getAllAdditionalTemplateKeys"]),
  },
  

  async created() {
    if(this.type !== this.selectedType) {
      this.betshopWingoCouponsEnabled = false
    }
    await this.setConfigurations();
    await this.getMarketBredCrumps(this.$route.params.marketId);
    await this.getCurrencyForWingo()
    this.checkPermissions();
    this.isDataFetch = true;
  },

  methods: {
    ...mapActions("response", [
      "handleSuccess",
      "handleError",
      "setGlobalError",
    ]),
    ...mapActions('company', ['getMarketBredCrumps']),
    ...mapGetters("account", ["user", "userHierarchyWriteableSections"]),

    async updateSettings() {
      try {
      this.type === this.selectedType
        ? await this.updateMarket()
        : await this.updateBetshop();
      this.clearAll = false;
      this.$emit("canChangeTab", false);
    } catch (e) {
        this.setGlobalError(e.data.message);
      }
    },

    async updateMarket() {
      if(!this.checkAreAllDataFiled()) {
        this.setGlobalError('All fields are mandatory')
        return;
      }

      if(this.checkMinAndMaxValue()) {
        this.setGlobalError(`Minimum value can't be smaller than or equal to the maximum value.`)
        return;
      }

      const dataForSaving = this.createDataForSave(false); 
      const { data } =
        await wingoCouponsTemplateService.saveWingoMarketConfiguration(
          dataForSaving
        );

      this.wingoCnfiguration = {
        ...data[0],
      };
      this.handleSuccess({ message: "Successfuly updated configurations!" });
    },

    async updateBetshop() {
      if(!this.checkAreAllDataFiled()) {
        this.setGlobalError('All fields are mandatory')
        return;
      }

      if(this.checkMinAndMaxValue()) {
        this.setGlobalError(`Minimum value can't be smaller than or equal to the maximum value.`)
        return;
      }

      const dataForSaving = this.createDataForSave(true);

      const { data } =
        await wingoCouponsTemplateService.saveWingoMarketConfiguration(
          dataForSaving
        );

      this.wingoCnfiguration = {
        ...data[0],
      };
      this.handleSuccess({ message: "Successfuly updated configurations!" });
    },

    createDataForSave(isBetshop) {
     const data = {
        marketId: Number(get(this.$route, "params.marketId")),
        dailyLimit: Number(this.wingoCnfiguration.dailyLimit),
        weeklyLimit: Number(this.wingoCnfiguration.weeklyLimit),
        monthlyLimit: Number(this.wingoCnfiguration.monthlyLimit),
        maxValue: Number(this.wingoCnfiguration.maxValue),
        minValue: Number(this.wingoCnfiguration.minValue),
        currency: this.wingoCnfiguration.currency,
        marketName: this.marketName,
        betshopWingoCouponsEnabled: this.betshopWingoCouponsEnabled
      };

      if(isBetshop) {
        data['betshopId'] = Number(get(this.$route, "params.betshopId"));
      }

      return data;
    },

    onChangeSectionOrTamplate() {
      this.$emit("canChangeTab", true);
    },

    checkMinAndMaxValue() {
      return Number(this.wingoCnfiguration.maxValue) <  Number(this.wingoCnfiguration.minValue);
    },

    checkPermissions() {
      this.hasWritePermision = hasWritePermission(
        this.user(),
        this.userHierarchyWriteableSections(),
        `additional-template-print-settings-${this.type}`
      );
    },

    async setConfigurations() {
      this.type === this.selectedType
        ? await this.getMarketConfigurations()
        : await this.getBetshopConfigurations();
    },

    async clearSettings() {
      this.type === this.selectedType
        ? await this.deleteMarketConfiguration()
        : await this.deleteBetshopConfiguration();
        this.$emit("canChangeTab", false);
    },

    async getCurrencyForWingo() {
      const results = await wingoCouponsTemplateService.getWingoCurrencies();
      this.currency = get(results, 'data.currencyRates');
    },
    
    async deleteMarketConfiguration() {
      try {
        const { data } =
          await wingoCouponsTemplateService.deleteMarketConfiguration(
            get(this.$route, "params.marketId")
          );

        if (!data.affected) {
          return;
        }
        this.restartDataForWingoConfig();
        this.handleSuccess({ message: "Successfuly updated configurations!" });
      } catch (e) {
        this.setGlobalError(e.data.message);
      }
    },

    async deleteBetshopConfiguration() {
      try {
        const { data } =
          await wingoCouponsTemplateService.deleteBetshopConfiguration(
            get(this.$route, "params.marketId"),
            get(this.$route, "params.betshopId")
          );

        if (!data.affected) {
          return;
        }
        this.restartDataForWingoConfig();
        this.handleSuccess({ message: "Successfuly updated configurations!" });
      } catch (e) {
        this.setGlobalError(e.data.message);
      }
    },

    async getMarketConfigurations() {
      const { data } = await wingoCouponsTemplateService.getConfiguration(
        get(this.$route, "params.marketId")
      );
      if (!data.length) {
        return;
      }
      this.wingoCnfiguration = {
        ...data[0],
      };

      this.betshopWingoCouponsEnabled = data[0].betshopWingoCouponsEnabled;
    },

    async getBetshopConfigurations() {
      const { data } =
        await wingoCouponsTemplateService.getBetshopConfiguration(
          get(this.$route, "params.marketId"),
          get(this.$route, "params.betshopId")
        );
      if (!data.length) {
        return;
      }
      this.wingoCnfiguration = {
        ...data[0],
      };

      this.betshopWingoCouponsEnabled = data[0].betshopWingoCouponsEnabled;
    },   

    checkAreAllDataFiled() {
      return this.wingoCnfiguration.dailyLimit > 0 
      && this.wingoCnfiguration.weeklyLimit > 0 
      && this.wingoCnfiguration.monthlyLimit > 0 
      && this.wingoCnfiguration.maxValue > 0
      && this.wingoCnfiguration.minValue > 0
      && this.wingoCnfiguration.currency
    },

    restartDataForWingoConfig() {
      this.wingoCnfiguration = {
        dailyLimit: null,
        weeklyLimit: null,
        monthlyLimit: null,
        maxValue: null,
        minValue: null,
        currency: ''
      };
    },
  },
};
</script>
