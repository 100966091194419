import Vue from 'vue'
import Router from 'vue-router'
import { authService } from '../services/auth'
import HomePage from '../pages/home'
import ActivatePage from '../pages/activate'
import LoginPage from '../pages/login'
import Companies from '../pages/companies'
import Markets from '../pages/markets'
import Betshops from '../pages/betshops'
import SingleBetshop from '../pages/single-betshop'
import AllUsers from '../pages/user'
import EditUSer from '../pages/user/update-user'
import AllTemplatesPage from '../pages/templates'

import AllSectionPage from '../pages/translation/section/';
import AllLanguage from '../pages/translation/language'
import TranslationPage from '../pages/translation'
import AllTranslationsPage from '../pages/translation/all-translations'
import ConfigurationsPage from '../pages/configurations'
import ConfigurationsAllPages from '../pages/configurations/pages'
import ConfigurationsShiftKeys from '../pages/configurations/shift-keys';
import ConfigurationsReceiptKeys from '../pages/configurations/receipt-keys';
import ConfigurationsTicketKeys from '../pages/configurations/ticket-keys';
import ConfigurationsHQSettings from '../pages/configurations/hq-settings';
import WingoCouponsSettings from '../pages/configurations/wingo-coupons-settings';
import ConfigurationsAdditionalTemplateKeys from '../pages/configurations/additional-template-keys';
import AppMangerContainerComponent from "../pages/app-manager/"
import ManagePrintersContainerComponent from "../pages/manage-printers/"
import ManageVersionsContainerComponent from "../pages/manage-versions/"
import ManageBetshopVersionsContainerComponent from "../pages/betshop-versions/"

Vue.use(Router)

const router = new Router({
  mode: 'history',
  routes: [
    { path: '/', redirect: '/home/companies'},
    { path: '/home', name: 'home', component: HomePage, meta: { authRequired: true },
      children: [
        { name: 'users', path: 'all-users', component: AllUsers},
        { name: 'users-edit', path: 'all-users/:id', component: EditUSer},
        { name: 'templates', path: 'all-templates', component: AllTemplatesPage },
        { name: 'companies', path: 'companies', component: Companies },
        { name: 'markets', path: 'company/:companyId/markets', component: Markets },
        { name: 'betshops', path: 'company/:companyId/markets/:marketId/betshops', component: Betshops },
        { name: 'betshop', path: 'company/:companyId/markets/:marketId/betshops/:betshopId', component: SingleBetshop },
        { name: 'translation', path: 'translation', component: TranslationPage,
          children: [
            {
              name: 'sections', path: 'section', component: AllSectionPage,
            },
            {
              name: 'all-translations', path: 'all-translations', component: AllTranslationsPage,
            },
            {
              name: 'languages', path: 'language', component: AllLanguage,
            },
          ]
        },
        { name: 'configurations', path: 'configurations', component: ConfigurationsPage,
          children: [
            {
              name: 'pages', path: 'pages', component: ConfigurationsAllPages,
            },
            {
              name: 'shift-keys', path: 'shift-keys', component: ConfigurationsShiftKeys,
            },
            {
              name: 'ticket-keys', path: 'ticket-keys', component: ConfigurationsTicketKeys,
            },
            {
              name: 'receipt-keys', path: 'receipt-keys', component: ConfigurationsReceiptKeys,
            },
            {
              name: 'hq-settings', path: 'hq-settings', component: ConfigurationsHQSettings,
            },
            {
              name: 'wingo-settings', path: 'wingo-settings', component: WingoCouponsSettings,
            },
            {
              name: 'additional-template-keys', path: 'additional-template-keys', component: ConfigurationsAdditionalTemplateKeys,
            },
          ]
        },
        { name: 'manage-drivers', path: 'manage-drivers', component: AppMangerContainerComponent },
        { name: 'manage-printers', path: 'manage-printers', component: ManagePrintersContainerComponent },
        { name: 'manage-versions', path: 'manage-versions', component: ManageVersionsContainerComponent },
        { name: 'betshop-versions', path: 'betshop-versions', component: ManageBetshopVersionsContainerComponent },
      ]
    },
    { path: '/login', name: 'login', component: LoginPage, meta: { unauthenticated: true }},
    { path: '/activate', name: 'activate', component: ActivatePage, meta: { unauthenticated: true }}
  ]
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = authService.isLoggedIn();

  if (to.name === from.name) {
    return;
  }

  if (to.meta.authRequired && !isLoggedIn) {
    return next({ name: 'login' })
  }

  if (isLoggedIn && from.name !== 'login' && to.name !== 'login') {
    return authService.hasPermission(to, next);

  }

  if (isLoggedIn && to.name === 'login') {
    return next({name: 'companies'})
  }

  next();
})

export default router
