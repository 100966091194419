<template>
  <div class="l-main">
    <div class="l-main__header u-box">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a
            class="c-breadcrumbs__link"
            @click="goToCompanies"
          >
            <md-icon>home</md-icon>
          </a>
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link">Templates</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <md-tabs class="c-tabs c-tabs--large" :md-active-tab="selectedTab">
        <md-tab
          v-for="tab in templatesTabs"
          :id="tab.tab"
          :key="tab.tab"
          :md-label="tab.label"
          @click="changeActiveTab(tab.tab)"
        />
      </md-tabs>
      <component 
        :is="currentTab"
        v-if="userCanSee(currentTab)" 
        @canChangeTab="canChangeTab"
      />
    </div>
  </div>
</template>

<script>
import { templatesTabs } from '../../const';
import TicketTemplate from './ticket-templates';
import OpenShiftTemplate from './open-shift-templates';
import CloseShiftTemplate from './close-shift-templates';
import AdditionalTemplate from './additional-templates.vue';
import { mapGetters } from 'vuex';
import each from 'lodash/each';
import ConfirmationModalComponent from '../../shared/confirmation-modal/ConfirmationModalComponent';
import WingoCouponsTemplate from './wingo-coupons-template.vue';
import WingoLastDrawingsTemplates from './wingo-last-drawings-templates.vue';

export default {
  components: {
    TicketTemplate,
    OpenShiftTemplate,
    CloseShiftTemplate,
    AdditionalTemplate,
    WingoCouponsTemplate,
    WingoLastDrawingsTemplates
  },
  data() {
    return {
      currentTab: 'ticket-template',
      selectedTab: 'ticket-template',
      previousTab: 'ticket-template',
      canOpenModal: false
    };
  },
  beforeRouteLeave(to, from, next) {
    if (this.canOpenModal) {
      this.openConfirmationModal(() => { next() });
      return;
    }
    return next();
  },
  computed: {
    ...mapGetters('account', ['userReadableSections', 'userWriteableSections', 'userAllowedRoutes', 'user']),
    templatesTabs() {
      return templatesTabs.filter(
        tab =>
          this.userCanSee(tab.tab)
      );
    },
  },
  created() {
    each(templatesTabs, tab => {
      if (this.userCanSee(tab.tab)) {
        this.currentTab = tab.tab;
        return false;
      }
    })
  },
  methods: {
    changeActiveTab(tab) {
      this.selectedTab = tab;
      if (this.canOpenModal) {
        this.openConfirmationModal(() => { this.currentTab = tab; this.previousTab = tab; this.canOpenModal = false; });
        return;
      }
      this.previousTab = tab;
      this.currentTab = tab;
    },
    goToCompanies() {
      if (this.user.role === 1) {
        return this.$router.push({ name: 'companies' });
      }

      if (this.$route.name === this.userAllowedRoutes[0]) {
        return;
      }
      
      this.userAllowedRoutes.includes('companies') ? this.$router.push({ name: 'companies' }) : this.$router.push({ name: this.userAllowedRoutes[0] });
    },
    userCanSee(tab) {
      return this.userWriteableSections.includes(tab) ||
        this.userReadableSections.includes(tab)
    },
    canChangeTab(canOpenModal) {
      this.canOpenModal = canOpenModal;
    },
    openConfirmationModal(onAccept) {
      this.$modal.show(
          ConfirmationModalComponent,
          {
            confirmationMsg: `
              Your changes will be lost,
              are you sure you want to continue?`,
            onAccept: onAccept,
            onClose: () => { 
              setTimeout(() => {
                this.selectedTab = this.previousTab;
              });
            },
          },
          { height: "auto", scrollable: false, width: "400px" }
        );
    },
  },
};
</script>

<style></style>
