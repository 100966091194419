<template>
  <div class="l-main__ctn-inner">
    <div class="c-table-wrapper">
      <base-table-component :prop-data="keysToShow">
        <template v-slot:default="slotProps">
          <span class="c-table__btn-wrap">
            <generic-button
              v-if="userCanEdit"
              icon
              transparent
              text-inherit
              @click="createOrUpdateShiftKey(slotProps.selected.rowData)"
            >
              <md-tooltip
                md-direction="top"
              >
                Edit
              </md-tooltip>
              <md-icon>edit</md-icon>
            </generic-button>
          </span>
          <span class="c-table__btn-wrap">
            <generic-button
              v-if="userCanEdit"
              icon
              transparent
              text-inherit
              @click="openDeleteSectionModal(slotProps.selected.rowData)"
            >
              <md-tooltip md-direction="top">
                Delete
              </md-tooltip>
              <md-icon>delete</md-icon>
            </generic-button>
          </span>
        </template>
      </base-table-component>
      <div class="add-button u-color-white">
        <generic-button
          v-if="userCanEdit"
          text-inherit
          transparent
          fab
          variation="red"
          @click="createOrUpdateShiftKey()"
        >
          <md-icon>add</md-icon>
        </generic-button>
      </div>
    </div>
    <paginate
      v-if="shouldShowPagination"
      :page-count="getShiftKeysPaginationData.pageCount"
      :page-range="paginationData.limitPerPage"
      :force-page="paginationData.currentPage"
      :click-handler="getDataPerPage"
      :container-class="'c-pagination'"
      :prev-link-class="'md-button prev md-elevation-1'"
      :next-link-class="'md-button next md-elevation-1'"
      :page-link-class="'md-button md-elevation-1'"
      :active-class="'active'"
      :disabled-class="'disabled'"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GenericButton from "../../../../shared/buttons/GenericButton";
import BaseTableComponent from "../../../../shared/table/BaseTableComponent";
import CreateUpdateModal from "../components/create-update/index";
import DeleteModalComponent from "../../../../shared/delete-modal/DeleteModalComponent";
import { shiftService } from '../../../../services/shift';
import { PAGE_INFO } from '../../../../const/pagination';

export default {
  name: "OpenShiftKeys",
  components: {
    GenericButton,
    BaseTableComponent,
  },

  data() {
    return {
      paginationData: {
        limitPerPage: PAGE_INFO.LIMIT_PER_PAGE,
        currentPage: PAGE_INFO.INITIAL_PAGE,
      },
    };
  },
  
  computed: {
    ...mapGetters("shift", ["getAllShiftKeys", "getShiftKeysPaginationData"]),
    ...mapGetters('account', ['userWriteableSections']),
    keysToShow: function () {
      return this.getAllShiftKeys.map(({ id, value }) => ({
        rowData: {
          id,
          name: value || `Ticket template ${id}`,
        },
      }));
    },
    shouldShowPagination() {
      return this.getShiftKeysPaginationData.totalItems > this.paginationData.limitPerPage;
    },

    userCanEdit() {
      return !!this.userWriteableSections.includes('close-shift-keys');
    }
  },

  created() {
    this.fetchShiftKeys();
  },

  methods: {
    ...mapActions("shift", ["fetchAllShiftKeys"]),

    createOrUpdateShiftKey(data = {}) {
      this.$modal.show(
        CreateUpdateModal,
        {
          data,
          type: 'close',
          paginationData: this.paginationData
        },
        { height: "auto", scrollable: true }
      );
    },

    openDeleteSectionModal(selected) {
      if (this.getShiftKeysPaginationData.totalItems % 10 === 1) {
        this.paginationData = {
          limitPerPage: 10,
          currentPage: this.paginationData.currentPage - 1 || 0
        }
      }

      this.$modal.show(
        DeleteModalComponent,
        {
          deleteMsg: `key "${selected.name}`,
          deleteMsgAlert: `It will be deleted on all other configurations.`,
          propData: selected.id,
          deleteFunction: this.deleteShiftKey,
          getDataFunction: this.fetchShiftKeys,
          pageData: this.paginationData
        },
        { height: "auto", scrollable: false, width: "400px" }
      )
    },

    deleteShiftKey(id) {
      return shiftService.deleteShiftKey(id);
    },

    fetchShiftKeys() {
      this.fetchAllShiftKeys({
        currentPage: this.paginationData.currentPage,
        limit: this.paginationData.limitPerPage,
        type: "close",
      });
    },

    getDataPerPage(
      currentPage = 1,
      limit = PAGE_INFO.LIMIT_PER_PAGE,
    ) {
      this.paginationData.currentPage = currentPage;
      this.fetchShiftKeys(currentPage, limit);
    },
  },
};
</script>

<style lang="scss" scoped>
</style>