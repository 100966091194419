import * as io from 'socket.io-client';
import config from '../../../config/local'

class SocketsService {
  connect() {
    this.client = io.connect(config.WEB_SOCKETS_URL, {reconnect: true});
  }

  subscribe(room, callback) {
    if (!this.client) {
      return;
    }

    this.client.on(room, callback);
  }

  unsubscribe(room) {
    this.client.off(room);
    this.client.emit('unsubscribe', room);
  }

  handleEmit(room, payload) {
    this.client.emit(room, payload);
}
}
export const socketsService = new SocketsService()
