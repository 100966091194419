import { apiService } from '../api';
import axios from 'axios'
import config from '../../../config/local'

const ENDPOINTS = {
  CREATE: '/app-manager/printer/save',
  GET: '/app-manager/printers',
  DELETE: '/app-manager/printer',
  UPDATE: '/app-manager/printer/update',
  SAVE_UPLOADED_APPS: '/app-manager/apps/save',
  GET_ALL_VERSIONS: '/app-manager/apps',
  DELETE_VERSION: '/app-manager/app',
  UPDATE_STATUS: '/app-manager/app',
  NOTIFY_NEW_VERSION: '/app-manager/notify-new-version',
  SWITCH_ACTIVE_VERSIONS: '/app-manager/switch-active-version',
  UPLOAD_VERSION: '/app-manager/upload',
  GET_APP: '/app-manager/apps',
  UPDATE_VERSION: '/app-manager/update',
  NOT_UPDATED_APPS: '/app-manager/not-updated-apps',
}

class AppManagerService {
  async create(data) {
    return await apiService.post(ENDPOINTS.CREATE, data)
  }

  async update(data) {
    return await apiService.put(ENDPOINTS.UPDATE, data)
  }

  async getAll() {
    return await apiService.get(ENDPOINTS.GET);
  }

  async delete(id) {
    return await apiService.delete(`${ENDPOINTS.DELETE}/${id}`);
  }

  async uploadScript(uploadFile) {
    return await axios.post(`${config.MANAGE_APP_URL}/script-upload`, uploadFile);
  }

  async getAppByStatus(status, platform) {
    return await apiService.get(`${ENDPOINTS.GET_APP}/platform/${platform}/status/${status}`);
  }

  formatData(selected) {
    if (selected) {
      return selected;
    }

    return { name: null, windowsLink: null, linuxLink: null, iOSLink: null, setupScriptName: null };
  }

  async getAllVersions() {
    return await apiService.get(ENDPOINTS.GET_ALL_VERSIONS);
  }

  async uploadApps(uploadFile, uploadingConfig, version, platform) {
    return await apiService.uploadFile(`${ENDPOINTS.UPLOAD_VERSION}?version=${version}&platform=${platform}`, uploadFile, uploadingConfig);
  }

  async switchActiveVersion(type) {
    return await apiService.get(`${ENDPOINTS.SWITCH_ACTIVE_VERSIONS}?type=${type}`);
  }

  async switchVersion(type) {
    return await axios.get(`${config.MANAGE_APP_URL}/switch-version?type=${type}`);
  }

  async saveUploadedApps(data) {
    return await apiService.post(ENDPOINTS.SAVE_UPLOADED_APPS, data);
  }

  async deleteAppData(id) {
    return await apiService.delete(`${ENDPOINTS.DELETE_VERSION}/${id}`);
  }

  async updateStatus(data) {
    return await apiService.put(`${ ENDPOINTS.UPDATE_STATUS }/${ data.id }/status`, data);
  }

  async removeApp(appPath) {
    return await axios.post(`${ config.MANAGE_APP_URL }/remove`, { appPath })
  }

  async notifyNewVersion(operatingSystemArray = [], updateType = '', version = '') {
    return await apiService.get(ENDPOINTS.NOTIFY_NEW_VERSION, { os: operatingSystemArray, updateType, version });
  }

  async updateVersion(currentApp) {
    return await apiService.post(ENDPOINTS.UPDATE_VERSION, currentApp)
  }

  async getAllActiveBetshopVersions(page, limit, term) {
    return await apiService.get(`${ENDPOINTS.NOT_UPDATED_APPS}?page=${page}&limit=${limit}&term=${term}`);
  }

  prepareDataFromResponse(items) {
    const data = [];

    items.forEach(item => {
      data.push({
        rowData: {
          name: item.name,
          windowsLink: item.windowsLink,
          linuxLink: item.linuxLink,
          iOSLink: item.iOSLink,
          setupScriptName: item.setupScriptName
        },
        name: item.name,
        windowsLink: item.windowsLink,
        linuxLink: item.linuxLink,
        iOSLink: item.iOSLink,
        id: item.id,
        setupScriptName: item.setupScriptName
      });
    });

    return data;
  }
}

export const appManagerService = new AppManagerService()
