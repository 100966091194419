<template>
  <base-modal-component>
    <template v-slot:header>
      <h4 class="md-title u-typography-color">
        {{ createOrUpdate }} Page
      </h4>
    </template>

    <template v-slot:body>
      <p
        v-if="error && error.messages"
        class="md-error"
      >
        {{ error.messages }}
      </p>
      <form
        novalidate
        @submit.prevent="validateForm"
      >
        <md-field :class="getValidationClass($v.configurationsPageData.title)">
          <label>Title</label>
          <md-input
            v-model="configurationsPageData.title"
            v-focus
            type="title"
          />
          <span
            v-if="!$v.configurationsPageData.title.required"
            class="md-error"
          >The title is required</span>
        </md-field>
        <error-response-messages :field-name="'title'" />
        <md-field :class="getValidationClass($v.configurationsPageData.key)">
          <label for="key">Key</label>
          <md-input
            v-model="configurationsPageData.key"
            name="key"
            type="key"
          />
          <span
            v-if="!$v.configurationsPageData.key.required"
            class="md-error"
          >The key is required</span>
          <span
            v-if="$v.configurationsPageData.key.required && !$v.configurationsPageData.key.emptySpace"
            class="md-error"
          >The key cannot have empty spaces</span>
        </md-field>
        <error-response-messages :field-name="'key'" />
      </form>
    </template>
    <template v-slot:footer>
      <div class="u-mr-xs">
        <generic-button
          variation="grey"
          @click="close"
        >
          Close
        </generic-button>
      </div>
      <generic-button
        variation="red"
        @click="validateForm"
      >
        {{ createOrUpdate }}
      </generic-button>
    </template>
  </base-modal-component>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationBaseMixin } from '../../../../../mixins/ValidationBaseMixin';
import GenericButton from "../../../../../shared/buttons/GenericButton";
import ErrorResponseMessages from "../../../../../shared/error-response-messages/ErrorResponseMessages";
import { configurationsPageService } from "../../../../../services/configurations-page/"
import { responseService } from "../../../../../services/response";
import { checkEmptySpace } from "../../../../../bundleRenderer.renderToStream/../const/validator";
import BaseModalComponent from "../../../../../shared/modal/BaseModalComponent";
import { required } from "vuelidate/lib/validators";

export default {
  name: 'SettingPageModal',
  components: {
    GenericButton,
    ErrorResponseMessages,
    BaseModalComponent
  },
  mixins: [validationBaseMixin],
  props: {
    currentPage: {
      type: Number,
      required: true
    },
    fetchConfigurationsPage: {
      type: Function,
      required: true
    },
    configurationsPageData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("response", ["success", "error"]),

    createOrUpdate() {
      return !this.configurationsPageData.id ? 'Create' : 'Update';
    },
  },
  validations: {
    configurationsPageData: {
      title: {
        required
      },
      key: {
        required,
        checkEmptySpace
      }
    }
  },
  methods: {
    ...mapActions("response", ["handleSuccess", "handleError", "resetError"]),

    async createOrUpdatePage() {
      try {
        await this.updateOrCreate();
        this.resetError();
        this.handleSuccess({ message: `Page ${this.createOrUpdate}d!` });
        this.fetchConfigurationsPage(this.currentPage)
        this.close();
      } catch (err) {
        this.handleError(responseService.getErrorMessage(err.data.message));
      }
    },

    async updateOrCreate() {
      return this.configurationsPageData.id ?
        await configurationsPageService.update(this.configurationsPageData) :
        await configurationsPageService.create(this.configurationsPageData);
    },

    close() {
      this.$emit("close");
      this.resetError();
    },

    validateForm() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
       this.createOrUpdatePage();
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>