<template>
  <div class="l-main__ctn-inner">
    <div class="c-table-wrapper">
      <base-table-component :prop-data="keysToShow">
        <template v-slot:default="slotProps">
          <span class="c-table__btn-wrap">
            <generic-button
              v-if="userCanEdit"
              icon
              transparent
              text-inherit
              @click="createOrUpdateReceiptKey(slotProps.selected.rowData)"
            >
              <md-tooltip
                md-direction="top"
              >
                Edit
              </md-tooltip>
              <md-icon>edit</md-icon>
            </generic-button>
          </span>
          <span class="c-table__btn-wrap">
            <generic-button
              v-if="userCanEdit"
              icon
              transparent
              text-inherit
              @click="openDeleteSectionModal(slotProps.selected.rowData)"
            >
              <md-tooltip md-direction="top">
                Delete
              </md-tooltip>
              <md-icon>delete</md-icon>
            </generic-button>
          </span>
        </template>
      </base-table-component>
      <div class="add-button u-color-white">
        <generic-button
          v-if="userCanEdit"
          text-inherit
          transparent
          fab
          variation="red"
          @click="createOrUpdateReceiptKey()"
        >
          <md-icon>add</md-icon>
        </generic-button>
      </div>
    </div>
    <paginate
      v-if="shouldShowPagination"
      :page-count="getReceiptKeysPaginationData.pageCount"
      :page-range="paginationData.limitPerPage"
      :force-page="paginationData.currentPage"
      :click-handler="getDataPerPage"
      :container-class="'c-pagination'"
      :prev-link-class="'md-button prev md-elevation-1'"
      :next-link-class="'md-button next md-elevation-1'"
      :page-link-class="'md-button md-elevation-1'"
      :active-class="'active'"
      :disabled-class="'disabled'"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import { PAGE_INFO } from '../../../../const/pagination';
import DeleteModalComponentVue from '../../../../shared/delete-modal/DeleteModalComponent.vue';
import CreateUpdateModal from "../components/create-update/index";
import { receiptService } from '../../../../services/receipt';
import GenericButton from "../../../../shared/buttons/GenericButton";
import BaseTableComponent from "../../../../shared/table/BaseTableComponent";

export default {

  name: "WithdrawReceiptKeys",
  components: {
    GenericButton,
    BaseTableComponent,
  },

  data() {
    return {
      paginationData: {
        limitPerPage: PAGE_INFO.LIMIT_PER_PAGE,
        currentPage: PAGE_INFO.INITIAL_PAGE,
      },
    };
  },
  computed: {
    ...mapGetters("receipt", ["getAllReceiptKeys", "getReceiptKeysPaginationData"]),
    ...mapGetters('account', ['userWriteableSections']),
    keysToShow: function () {
      return this.getAllReceiptKeys.map(({ id, value }) => ({
        rowData: {
          id,
          name: value || `Ticket template ${id}`,
        },
      }));
    },
    shouldShowPagination() {
      return this.getReceiptKeysPaginationData.totalItems > this.paginationData.limitPerPage;
    },

    userCanEdit() {
      return !!this.userWriteableSections.includes('withdraw-receipt-keys');
    }
  },
  created() {
    this.fetchReceiptKeys();
  },
  methods: {
    ...mapActions("receipt", ["fetchAllReceiptKeys"]),
    createOrUpdateReceiptKey(data = {}) {
      this.$modal.show(
        CreateUpdateModal,
        {
          data,
          type: 'withdraw',
          paginationData: this.paginationData
        },
        { height: "auto", scrollable: true }
      );
    },
    openDeleteSectionModal(selected) {
      if (this.getReceiptKeysPaginationData.totalItems % 10 === 1) {
        this.paginationData = {
          limitPerPage: 10,
          currentPage: this.paginationData.currentPage - 1 || 0
        }
      }

      this.$modal.show(
        DeleteModalComponentVue,
        {
          deleteMsg: `key "${selected.name}`,
          deleteMsgAlert: `It will be deleted on all other configurations.`,
          propData: selected.id,
          deleteFunction: this.deleteReceiptKey,
          getDataFunction: this.fetchReceiptKeys,
          pageData: this.paginationData
        },
        { height: "auto", scrollable: false, width: "400px" }
      )
    },
    deleteReceiptKey(id) {
      return receiptService.deleteReceiptKey(id);
    },
    fetchReceiptKeys() {
      this.fetchAllReceiptKeys({
        currentPage: this.paginationData.currentPage,
        limit: this.paginationData.limitPerPage,
        type: "withdraw",
      });
    },
    getDataPerPage(
      currentPage = 1,
      limit = PAGE_INFO.LIMIT_PER_PAGE,
    ) {
      this.paginationData.currentPage = currentPage;
      this.fetchReceiptKeys(currentPage, limit);
    },
  }
}
</script>
<style lang="scss" scoped>

</style>