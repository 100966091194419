<template>
  <base-modal-component>
    <template v-slot:header>
      <h4 class="md-title u-typography-color">
        {{ createOrUpdate }} ticket key
      </h4>
    </template>

    <template v-slot:body>
      <form novalidate @submit.prevent="validateForm">
        <md-field :class="getValidationClass($v.data.name)">
          <label>Key</label>
          <md-input 
            v-model="data.name"
            v-focus
            type="name"
          />
          <span 
            v-if="!$v.data.name.required" 
            class="md-error"
          >The title is required</span>
        </md-field>
      </form>
    </template>
    <template v-slot:footer>
      <div class="u-mr-xs">
        <generic-button variation="grey" @click="close">
          Close
        </generic-button>
      </div>
      <generic-button variation="red" @click="validateForm">
        {{ createOrUpdate }}
      </generic-button>
    </template>
  </base-modal-component>
</template>

<script>
import GenericButton from "../../../../../shared/buttons/GenericButton";
import BaseModalComponent from "../../../../../shared/modal/BaseModalComponent";
import { validationBaseMixin } from "../../../../../mixins/ValidationBaseMixin";
import { required } from "vuelidate/lib/validators";
import { mapActions } from 'vuex';
import { ticketService } from '../../../../../services/ticket';

export default {
  name: "CreateUpdateShiftKey",
  components: {
    GenericButton,
    BaseModalComponent,
  },
  mixins: [validationBaseMixin],
  props: {
    data: {
      type: Object,
      required: true,
    },
    paginationData: {
      type: Object,
      required: true,
    },
  },
  computed: {
    createOrUpdate() {
      return !this.data.id ? "Create" : "Update";
    },
  },
  validations: {
    data: {
      name: {
        required,
      },
    },
  },
  methods: {
    ...mapActions("response", ["handleSuccess", "setGlobalError",]),
    ...mapActions("ticket", ["fetchAllTicketKeys"]),

    async createOrUpdateShiftKey() {
      try {
        await this.updateOrCreate();
        this.handleSuccess({ message: `Shift key ${this.createOrUpdate}d!` });
        this.close();
        this.fetchAllTicketKeys({
          currentPage: this.paginationData.currentPage,
          limit: this.paginationData.limitPerPage,
        });
      } catch (err) {
        this.setGlobalError(err.data.message);
      }
    },

    async updateOrCreate() {
      return this.data.id ?
        await ticketService.update({id: this.data.id, value: this.data.name.replace(/\s/g,''), type: this.type}):
        await ticketService.create({value: this.data.name.replace(/\s/g,''), type: this.type});
    },

    close() {
      this.$emit("close");
    },

    validateForm() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.createOrUpdateShiftKey();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
</style>