<template>
  <div class="l-main">
    <md-tabs class="c-tabs--small">
      <md-tab
        v-for="tab in tabs"
        :id="tab.tab"
        :key="tab.tab"
        :md-label="tab.label"
        @click="changeActiveTab(tab.tab)"
      />
    </md-tabs>
    <component :is="currentTab" :type="type" />
  </div>
</template>

<script>
import Published from './sections/published';
import Previous from './sections/previous';
import Test from './sections/test';

export default {
  name: "OsPage",

  components: {
    Published,
    Previous,
    Test
  },

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      currentTab: 'published',
      tabs: [
        {
          tab: 'published',
          label: 'Published'
        },
        {
          tab: 'previous',
          label: 'Previous'
        },
        {
          tab: 'test',
          label: 'Test'
        }
      ]
    };
  },

  methods: {
    changeActiveTab(tab) {
      this.currentTab = tab;
    },
  },
};

</script>
