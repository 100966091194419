import { render, staticRenderFns } from "./ticket-templates.vue?vue&type=template&id=306b0cc2&"
import script from "./ticket-templates.vue?vue&type=script&lang=js&"
export * from "./ticket-templates.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports