import { receiptService } from '../../services/receipt/index';

function getInitialState() {
  return {
    receiptKeys: [],
    receiptKeysPaginationData: {}
  };
}

const state = getInitialState();

const actions = {
  async fetchAllReceiptKeys({ commit, dispatch }, { currentPage, limit, type }) {
    try {
      dispatch('response/setGlobalError', null, { root: true });
      const savedReceiptKeys = await receiptService.fetchAllReceiptKeys(currentPage, limit, type)
      commit('SET_RECEIPT_KEYS',
      {
        receiptKeys: savedReceiptKeys.data.items,
        paginationData: {
          totalItems: savedReceiptKeys.data.totalItems,
          pageCount: savedReceiptKeys.data.pageCount
        }
      }
    )
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with WSBO', { root: true });
    }
  },
};

const getters = {
  getAllReceiptKeys: state => state.receiptKeys,
  getReceiptKeysPaginationData: state => state.receiptKeysPaginationData,
};

const mutations = {
  SET_RECEIPT_KEYS(state, data) {
    state.receiptKeys = data.receiptKeys
    state.receiptKeysPaginationData = data.paginationData
  },
};

export const receipt = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
