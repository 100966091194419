<template>
  <div>
    <div>
      <md-tabs class=" c-tabs--small" :md-active-tab="selectedTab">
        <md-tab
          v-for="tab in shiftSettings"
          :id="tab.tab"
          :key="tab.tab"
          :md-label="tab.label"
          @click="changeActiveTab(tab.tab)"
        />
      </md-tabs>
      <component
        :is="currentTab"
        :api-configurations-data="apiConfigurationsData"
        :api-selected-configurations-data="apiSelectedConfigurationsData"
        :type-id="typeId"
        :type="type"
        @canChangeTab="canChangeTab"
      />
    </div>
  </div>
</template>
<script>
import { shiftSettings } from "../../../const";
import OpenShiftTemplateConfiguration from './open-shift-template-configuration';
import CloseShiftTemplateConfiguration from './close-shift-template-configuration';
import ConfirmationModalComponent from '../../../shared/confirmation-modal/ConfirmationModalComponent';

export default {
  name: "ShiftSettings",
  components: {
    OpenShiftTemplateConfiguration,
    CloseShiftTemplateConfiguration,
  },

  props: {
    apiConfigurationsData: {
      type: Array,
      default: () => [],
    },
    apiSelectedConfigurationsData: {
      type: Array,
      default: () => [],
    },
    typeId: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      currentTab: "open-shift-template-configuration",
      previousTab: "open-shift-template-configuration",
      selectedTab: "open-shift-template-configuration",
      canOpenModal: false
    };
  },

  computed: {
    shiftSettings() {
      return shiftSettings;
    },
  },

  beforeRouteLeave(to, from, next) {
    if (this.canOpenModal) {
      this.openConfirmationModal(() => { next() });
      return;
    }
    next();
  },
  methods: {
    changeActiveTab(tab) {
      this.selectedTab = tab;
      if (this.canOpenModal) {
        this.openConfirmationModal(() => { this.currentTab = tab; this.selectedTab = tab; this.canOpenModal = false; });
        return;
      }
      this.currentTab = tab;
      this.previousTab = tab;
    },

    canChangeTab(canOpenModal) {
      this.canOpenModal = canOpenModal;
      this.$emit('canChangeTab', this.canOpenModal);
    },

    openConfirmationModal(onAccept) {
      this.$modal.show(
          ConfirmationModalComponent,
          {
            confirmationMsg: `
              Your changes will be lost,
              are you sure you want to continue?`,
            onAccept: onAccept,
            onClose: () => {
              setTimeout(() => {
                this.selectedTab = this.previousTab;
              })
            },
          },
          { height: "auto", scrollable: false, width: "400px" }
        );
    },
  },
};
</script>
