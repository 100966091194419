<template>
  <div class="l-main u-ph-md u-pv-xs">
    <div>
      <h5 class="u-mb-md">
        Rollback <span class="u-text-capitalized">{{ type }}</span> versions
      </h5>
      <div class="c-table c-table--versions c-table--versions--sm">
        <div class="c-table__head">
          <div v-for="field in publishedFields" :key="field" class="c-table__cell">
            {{ field }}
          </div>
        </div>
        <div
          v-if="rollbackedApps.length"
          class="c-table__body"
        >
          <div
            v-for="(rollbackedApp, index) in rollbackedApps"
            :key="`rollbacked-${index}`"
            class="c-table__row"
          >
            <div class="c-table__cell">
              {{ rollbackedApp.name }}
            </div>
            <div class="c-table__cell">
              {{ rollbackedApp.version }}
            </div>
            <div class="c-table__cell">
              {{ formatTime(rollbackedApp) }}
            </div>
            <div class="c-table__cell">
              {{ assignedByEmail(rollbackedApp) }}
            </div>

            <div class="c-table__cell">
              <span class="c-table__btn-wrap">

                <generic-button v-if="userCanEdit" icon transparent text-inherit @click="rollbackApp(rollbackedApp)">
                  <md-tooltip md-direction="top">
                    Rollback
                  </md-tooltip>
                  <md-icon>done</md-icon>
                </generic-button>

              </span>
              <span class="c-table__btn-wrap">
                <generic-button
                  icon
                  transparent
                  text-inherit
                  @click="downloadApp(rollbackedApp)"
                >
                  <md-tooltip md-direction="top">
                    Download
                  </md-tooltip>
                  <md-icon>download</md-icon>
                </generic-button>
              </span>
              <span class="c-table__btn-wrap">
                <generic-button
                  v-if="userCanEdit"
                  icon
                  transparent
                  text-inherit
                  @click="openDeleteModal(rollbackedApp)"
                >
                  <md-tooltip md-direction="top">
                    Delete
                  </md-tooltip>
                  <md-icon>delete</md-icon>
                </generic-button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="u-mt-lg u-flex-full-height">
      <h5 class="u-mb-md">
        Previous <span class="u-text-capitalized">{{ type }}</span> versions
      </h5>
      <div class="c-table c-table--versions">
        <div class="c-table__head">
          <div v-for="field in publishedFields" :key="field" class="c-table__cell">
            {{ field }}
          </div>
        </div>
        <div
          v-if="previouslyPublishedApps"
          class="c-table__body c-table__body--previous-versions"
        >
          <div
            v-for="(previouslyPublishedApp, index) in previouslyPublishedApps"
            :key="`previous-${index}`"
            class="c-table__row"
          >
            <div class="c-table__cell">
              {{ previouslyPublishedApp.name }}
            </div>
            <div class="c-table__cell">
              {{ previouslyPublishedApp.version }}
            </div>
            <div class="c-table__cell">
              {{ formatTime(previouslyPublishedApp) }}
            </div>
            <div class="c-table__cell">
              {{ assignedByEmail(previouslyPublishedApp) }}
            </div>

            <div class="c-table__cell">
              <span class="c-table__btn-wrap">
                <generic-button
                  v-if="userCanEdit"
                  icon
                  transparent
                  text-inherit
                  @click="rollbackApp(previouslyPublishedApp)"
                >
                  <md-tooltip md-direction="top">
                    Rollback
                  </md-tooltip>
                  <md-icon>done</md-icon>
                </generic-button>
              </span>
              <span class="c-table__btn-wrap">
                <generic-button
                  icon
                  transparent
                  text-inherit
                  @click="downloadApp(previouslyPublishedApp)"
                >
                  <md-tooltip md-direction="top">
                    Download
                  </md-tooltip>
                  <md-icon>download</md-icon>
                </generic-button>
              </span>
              <span class="c-table__btn-wrap">
                <generic-button
                  v-if="userCanEdit"
                  icon
                  transparent
                  text-inherit
                  @click="openDeleteModal(previouslyPublishedApp)"
                >
                  <md-tooltip md-direction="top">
                    Delete
                  </md-tooltip>
                  <md-icon>delete</md-icon>
                </generic-button>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { approvedFields, publishedFields } from '../../../../const/systems';
import GenericButton from '../../../../shared/buttons/GenericButton';
import RollbackConfirmation from '../popups/roll-back-confirmation';
import config from '../../../../../config/local'
import { appManagerService } from '../../../../services/app-manager';
import DeleteModalComponent from '../../../../shared/delete-modal/DeleteModalComponent';
import {mapActions, mapGetters} from 'vuex';
import moment from 'moment';

export default {
  name: "Published",

  components: {
    GenericButton
  },

  props: {
    type: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      uploadProgress: 0,
      dataIsSubmiting: false,
      previouslyPublishedApps: [],
      rollbackedApps: [],
      publishedFields: publishedFields,
      approvedFields: approvedFields
    }
  },
  computed: {
    ...mapGetters('account', ['userWriteableSections']),
    userCanEdit() {
      return !!this.userWriteableSections.includes('manage-versions');
    }
  },
  watch: {
    type() {
      this.fetchApp();
    }
  },

  async created() {
    await this.fetchApp();
  },

  methods: {
    ...mapActions('response', ['handleSuccess', 'setGlobalError']),

    formatTime(app) {
      return moment(app.updatedAt).format('DD/MM/YYYY HH:mm')
    },

    assignedByEmail(app) {
      return app.updatedBy ? app.updatedBy.email : ''
    },

    async fetchApp() {
      try {
        const previouslyPublishedApps = await appManagerService.getAppByStatus('previous-published', this.type);
        const rollbackedApps = await appManagerService.getAppByStatus('rollback', this.type);
        this.previouslyPublishedApps = previouslyPublishedApps.data;
        this.rollbackedApps = rollbackedApps.data;
      } catch (error) {
        this.setGlobalError(error.data.message);
      }
    },

    async downloadApp(app) {
      const link = document.createElement('a');
      link.setAttribute('download', app.name);
      link.style.display = 'none';
      document.body.appendChild(link);
      link.setAttribute('href', `${ config.BASE_DOWNLOAD_URL }/${ app.downloadUrl }`);
      link.click();
      link.remove();
    },

    rollbackApp(app) {
      this.$modal.show(
        RollbackConfirmation,
        {
          onRollback: this.onRollback,
          app
        },
        { height: "auto", scrollable: true }
      );
    },

    async onRollback(app) {
      try {
        await appManagerService.updateVersion(app)
        this.handleSuccess({ message: 'App has been roll-backed.' })
        await appManagerService.notifyNewVersion([app.platform], 'mandatory-blocking', app.version)
        await this.fetchApp();
      } catch (error) {
        console.log(error, 'error')
        this.setGlobalError(error.data.message);
      }
    },

    async deleteApp(app) {
      try {
        await appManagerService.deleteAppData(app.id)
        app.status === 'previous-published' ? this.removePreviouslyPublishedApp(app) : this.removeRollbackedApp(app);
        this.handleSuccess({ message: 'App has been removed.' })
      } catch (error) {
        this.setGlobalError(error.data.message);
      }
    },

    removePreviouslyPublishedApp(app) {
      this.previouslyPublishedApps = this.previouslyPublishedApps.filter(a => a.id !== app.id)
    },

    removeRollbackedApp(app) {
      this.rollbackedApps = this.rollbackedApps.filter(a => a.id !== app.id)
    },
    openDeleteModal(selected) {
      this.$modal.show(
        DeleteModalComponent,
        {
          deleteMsg: `"${selected.name}`,
          propData: selected,
          deleteFunction: this.deleteApp,
          getDataFunction: this.fetchApp,
          pageData: {currentPage: 1, limit: 1 }
        },
        { height: "auto", scrollable: false, width: "400px" }
      )
    },
  },
};

</script>
