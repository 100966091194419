<template>
  <div :class="['c-collapsible', { 'is-open': visible }]">
    <div class="c-collapsible__action">
      <generic-button
        variation="red"
        @click="$emit('click')"
      >
        {{ getLabel }}
      </generic-button>
      <slot name="action" />
    </div>
    <transition name="collapsible-fade">
      <div
        v-if="visible"
        class="c-collapsible__ctn"
      >
        <div
          v-if="visible"
          class="c-collapsible__ctn-wrap"
        >
          <slot />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import GenericButton from '../buttons/GenericButton';

export default {
  name: 'GenericCollapsible',
  components: {
    GenericButton
  },
  props: {
    toggleLabel: {
      type: String,
      required: true
    },
    toggleCancel: {
      type: String,
      required: true
    },
    visible: {
      type: Boolean,
      required: true
    }
  },
  computed: {
    getLabel() {
      return this.visible ? this.toggleCancel : this.toggleLabel
    }
  }
};
</script>
