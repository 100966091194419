import { replacePathParams } from '../../helpers/urlHelpers';
import { apiService } from '../api'

const ENDPOINTS = {
  CREATE: '/ticket-keys/save',
  GET: '/ticket-keys',
  UPDATE: '/ticket-keys/:id',
  DELETE: '/ticket-keys/:id'
}

class TicketService {
  async create(data) {
    return await apiService.post(ENDPOINTS.CREATE, data)
  }

  async update(data) {
    return await apiService.put(replacePathParams(ENDPOINTS.UPDATE, { id: data.id }), data)
  }

  async fetchAllTicketKeys(page, limit) {
    return await apiService.get(`${ENDPOINTS.GET}?page=${page}&limit=${limit}`);
  } 

  async deleteTicketKey(id) {
    return await apiService.delete(replacePathParams(ENDPOINTS.DELETE, { id }))
  }
}

export const ticketService = new TicketService()
