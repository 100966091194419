<template>
  <div class="l-main">
    <div class="l-main__header u-box">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a
            class="c-breadcrumbs__link"
            @click="goToCompanies"
          >
            <md-icon>home</md-icon>
          </a>
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link">All translations</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <div class="u-flex-row-end u-ph-md">
        <div class="u-ml-auto u-mr-md">
          <md-field class="u-width-m u-mb-xs">
            <md-select v-model="selectedFilter">
              <md-option
                v-for="section in availableSections"
                :key="section.key"
                :value="section.key"
              >
                {{ section.title }}
              </md-option>
            </md-select>
          </md-field>
        </div>
        <md-field
          md-inline
          class="u-width-m u-mb-xs"
        >
          <label>Search</label>
          <md-input
            v-model="term"
            @keydown.enter="searchTranslation"
          />
          <div style="margin: 4px auto" @click="searchTranslation()">
            <md-icon style="cursor: pointer">
              search
            </md-icon>
          </div>
        </md-field>
        <div class="u-ml-md u-color-white">
          <generic-button
            class="md-mini"
            text-inherit
            fab
            variation="red"
            @click="openModalForExport()"
          >
            <md-tooltip md-direction="top">
              Export CSV
            </md-tooltip>
            <md-icon class="md-size-1x">
              vertical_align_bottom
            </md-icon>
          </generic-button>
        </div>
      </div>
      <div class="c-table-wrapper">
        <base-table-component :prop-data="words">
          <template v-slot:default="slotProps">
            <span class="c-table__btn-wrap">
              <generic-button
                icon
                transparent
                text-inherit
                @click="openListTranslations(slotProps.selected.word)"
              >
                <md-tooltip md-direction="top">
                  View
                </md-tooltip>
                <md-icon>remove_red_eye</md-icon>
              </generic-button>
            </span>
            <span class="c-table__btn-wrap">
              <generic-button
                v-if="userCanEdit"
                icon
                transparent
                text-inherit
                @click="openModal(slotProps.selected.word)"
              >
                <md-tooltip md-direction="top">
                  Edit
                </md-tooltip>
                <md-icon>create</md-icon>
              </generic-button>
            </span>
            <span class="c-table__btn-wrap">
              <generic-button
                v-if="userCanEdit"
                icon
                transparent
                text-inherit
                @click="openModalForDelete(slotProps.selected.word)"
              >
                <md-tooltip md-direction="top">
                  Delete
                </md-tooltip>
                <md-icon>delete</md-icon>
              </generic-button>
            </span>
          </template>
        </base-table-component>
      </div>

      <template v-if="isFetched && words.length === 0">
        <div class="l-empty-container">
          <md-empty-state
            md-icon="announcement"
            md-label="No Data"
          />
        </div>
      </template>
      <div class="add-button u-color-white">
        <generic-button
          v-if="userCanEdit"
          text-inherit
          transparent
          fab
          variation="red"
          @click="openModal()"
        >
          <md-icon>add</md-icon>
        </generic-button>
      </div>
      <paginate
        v-if="shouldShowPagination"
        v-model="currentPage"
        :page-count="paginationData.pageCount"
        :page-range="limitPerPage"
        :click-handler="getDataPerPage"
        :container-class="'c-pagination'"
        :prev-link-class="'md-button prev md-elevation-1'"
        :next-link-class="'md-button next md-elevation-1'"
        :page-link-class="'md-button md-elevation-1'"
        :active-class="'active'"
        :disabled-class="'disabled'"
      />
    </div>
  </div>
</template>

<script>
import { wordService } from "../../../services/words";
import { sectionService } from "../../../services/section/index";
import { languageService } from "../../../services/language/index";
import { translationService } from "../../../services/translation";
import { paginationService } from "../../../services/pagination/index";
import ListTranslationModal from "./components/list-translations/index";
import TranslationModal from "./components/create-update/index";
import BaseTableComponent from "../../../shared/table/BaseTableComponent";
import DeleteModalComponent from "../../../shared/delete-modal/DeleteModalComponent";
import ExportTranslation from "./components/export-translation/index";
import GenericButton from '../../../shared/buttons/GenericButton';
import { mapGetters } from 'vuex';

export default {
  components: {
    BaseTableComponent,
    GenericButton
  },
  data() {
    return {
      words: [],
      sections: [],
      languages: [],
      currentPage: 1,
      limitPerPage: 10,
      paginationData: {},
      selectedFilter: 'all',
      term: '',
      isFetched: false
    };
  },
  computed: {
    ...mapGetters('account', ['userWriteableSections', 'userAllowedRoutes', 'user']),
    shouldShowPagination() {
      return this.paginationData.totalItems > this.limitPerPage
    },
    availableSections() {
      return [{ id: 0, key: 'all', title: "All sections" }, ...this.sections];
    },
    userCanEdit() {
      return !!this.userWriteableSections.includes('all-translations');
    }
  },

  watch: {
    selectedFilter: function () {
      this.getDataPerPage(1, this.limitPerPage, this.term);
    }
  },
  created() {
    this.getDataPerPage(this.currentPage, this.limitPerPage, this.term);

    sectionService.getAllSections(1, 99999999, true).then(response => {
      this.sections = response.data.items;
    });
    languageService.getAllLanguages().then(response => {
      this.languages = response.data.items;
    });
  },

  methods: {
    searchTranslation() {
      this.fetchCurrentPage();
    },
    openModalForExport() {
      this.$modal.show(
        ExportTranslation,
        {
          languages: this.languages,
        },
        { height: "auto", scrollable: false, width: "400px" }
      );
    },
    openListTranslations(word) {
      this.$modal.show(
        ListTranslationModal,
        { translations: word.translation, word },
        { height: "auto", scrollable: true }
      );
    },

    goToCompanies() {
      if (this.user.role === 1) {
        return this.$router.push({ name: 'companies' });
      }

      if (this.$route.name === this.userAllowedRoutes[0]) {
        return;
      }

      this.userAllowedRoutes.includes('companies') ? this.$router.push({ name: 'companies' }) : this.$router.push({ name: this.userAllowedRoutes[0] });
    },

    fetchCurrentPage() {
      this.getDataPerPage(this.currentPage, this.limitPerPage, this.term);
    },

    openModal(word) {
      this.$modal.show(
        TranslationModal,
        {
          languages: this.languages,
          sections: this.sections,
          fetchCurrentPage: this.fetchCurrentPage,
          translationData: translationService.formatData(word, this.languages)
        },
        { height: "auto", scrollable: true, 'name': 'translations' },
      );
    },

    getDataPerPage(currentPage = 1, limit = this.limitPerPage, term = this.term) {
      this.currentPage = currentPage;
      this.fetchTranslations(currentPage, limit, term)
    },

    fetchTranslations(currentPage, limit, term) {
      if (this.words.length !== 0) {
        this.isFetched = false;
      }
      wordService.getAll(currentPage, limit, this.selectedFilter, term).then(response => {
        if (response.data.items.length === 0 && this.currentPage > 1) {
          return this.getDataPerPage(this.currentPage - 1, this.limitPerPage, term);
        }
        this.isFetched = true;
        this.paginationData = paginationService.getPaginationData(response.data);
        this.words = translationService.formatDataForTable(response.data.items)
      });
    },

    openModalForDelete(language) {
      this.$modal.show(
        DeleteModalComponent,
        {
          deleteMsg: `"${language.section.key}.${language.key}`,
          propData: language.id,
          deleteFunction: this.deleteWord,
          getDataFunction: this.getDataPerPage,
          pageData: {currentPage: this.currentPage, limit: this.limitPerPage }
        },
        { height: "auto", scrollable: false, width: "400px" }
      )
    },

    deleteWord(id) {
      return wordService.delete(id);
    }
  }
};
</script>
<style lang="scss">
  .u-width-m {
    width: 250px;
  }
</style>
