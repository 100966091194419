<template>
  <md-app>
    <md-app-toolbar
      class="md-primary home-header"
      md-elevation="0"
    >
      <md-menu
        md-size="medium"
        md-align-trigger
        class="u-ml-auto"
      >
        <generic-button
          icon
          transparent
          md-menu-trigger
        >
          <md-icon>settings</md-icon>
        </generic-button>

        <md-menu-content>
          <div class="u-ph-md u-pv-xs">
            <md-switch
              v-model="nightMode"
              @change="handleThemeToggle"
            >
              Dark mode
            </md-switch>
          </div>
          <md-menu-item @click="changePassword">
            Change password
          </md-menu-item>
          <md-menu-item @click="logout">
            Logout
          </md-menu-item>
        </md-menu-content>
      </md-menu>
    </md-app-toolbar>

    <md-app-drawer
      :md-active="true"
      md-persistent="mini"
      class="c-drawer"
    >
      <button
        class="c-drawer__logo-wrapper"
        @click="goToCompanies"
      >
        <div class="c-drawer__logo">
          <img
            class="u-max-width-15"
            src="../../assets/betty-logo.svg"
          >
        </div>
      </button>
      <div class="c-drawer__main">
        <md-list>
          <md-list-item
            v-for="sidebarItem in userAlowedSidebarItems" 
            :key="sidebarItem.pathName"
            class="md-list-item-router md-list-item-container md-button-clean"
            :to="{ name: sidebarItem.pathName }"
            :md-expand="!!sidebarItem.subItems.length"
            :md-expanded.sync="sidebarItem.expandedSync"
          >
            <md-icon>{{ sidebarItem.icon }}</md-icon>
            <span class="md-list-item-text">{{ sidebarItem.text }}</span>

            <md-list slot="md-expand">
              <md-list-item
                v-for="subItem in sidebarItem.subItems" 
                :key="subItem.pathName"
                class="md-list-item-router md-list-item-container md-button-clean"
                :to="{ name: subItem.pathName }"
              >
                <md-icon>{{ subItem.icon }}</md-icon>
                <span class="md-list-item-text">{{ subItem.text }}</span>
              </md-list-item>
            </md-list>
          </md-list-item>
        </md-list>
      </div>
    </md-app-drawer>

    <md-app-content class="l-home">
      <router-view />
      <snack-bar />
    </md-app-content>
  </md-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import SnackBar from '../../shared/snackebar/SnackeBar';
import { authService } from '../../services/auth';
import ChangePasswordModal from '../change-password';
import GenericButton from '../../shared/buttons/GenericButton';
import includes from 'lodash/includes';

export default {
  components: {
    SnackBar,
    GenericButton,
  },
  data() {
    return {
      translationSelected: false,
      configurationsSelected: false,
      manageAppSelected: false,
    };
  },
  
  computed: {
    ...mapGetters('account', ['user', 'userAlowedSidebarItems', 'userWriteableSections', 'userAllowedRoutes']),
    ...mapGetters('theme', ['isNightMode']),

    nightMode: {
      get() {
        return this.isNightMode;
      },
      set(mode) {
        return mode;
      },
    },
    isRouteForCompanies() {
      return includes(['companies', 'markets', 'betshops', 'betshop'], this.$route.name)
    }
  },

  methods: {
    ...mapActions('account', ['handleLoginSuccess']),
    ...mapActions('theme', ['handleThemeToggle']),

    logout() {
      authService.logout(this.user.email);
    },

    changePassword() {
      this.$modal.show(
        ChangePasswordModal,
        {},
        { height: 'auto', scrollable: true }
      );
    },


    goToCompanies() {
      if (this.$route.name === 'companies') {
        return;
      }
      
      if (this.user.role === 1) {
        return this.$router.push({ name: 'companies' });
      }

      if (this.$route.name === this.userAllowedRoutes[0]) {
        return;
      }
      
      this.userAllowedRoutes.includes('companies') ? this.$router.push({ name: 'companies' }) : this.$router.push({ name: this.userAllowedRoutes[0] });
    },
  },
};
</script>

<style lang="scss">
.l-home {
  height: calc(100vh - 64px);
  overflow: hidden;
  background-color: map-get($content-background, lightTheme) !important;
  padding: 0;

  @include dark-theme {
    background-color: map-get($content-background, darkTheme) !important;
  }

  @media (max-width: 960px){
    height: calc(100vh - 48px);
  }
}
</style>
