<template>
  <div class="l-login u-box">
    <div class="md-toolbar md-app-toolbar md-primary md-theme-default md-elevation-4 login-header">
      <div class="l-login__logo-wrapper">
        <img
          class="u-max-width-15"
          src="../../assets/betty-logo.svg"
        >
      </div>
    </div>
    <div class="full-height md-layout md-alignment-top-center ">
      <md-card>
        <md-card-header>
          <div class="md-title">
            Login page
          </div>
          <p
            v-if="loginError"
            class="md-error"
          >
            {{ loginError }}
          </p>
        </md-card-header>

        <md-card-content>
          <form
            novalidate
            @submit.prevent="validateUser"
          >
            <div class="l-login-form__row">
              <md-field
                :class="['c-input', getValidationClass($v.user.email)]"
              >
                <md-input
                  v-model="user.email"
                  placeholder="Email"
                  name="email"
                  type="email"
                  @keyup.enter="validateUser"
                />
                <span
                  v-if="!$v.user.email.required"
                  class="md-error"
                >The email is required</span>
                <span
                  v-else-if="!$v.user.email.email"
                  class="md-error"
                >Invalid email</span>
              </md-field>
            </div>
            <div class="l-login-form__row">
              <md-field :class="['c-input', getValidationClass($v.user.password)]">
                <md-input
                  v-model="user.password"
                  placeholder="Password"
                  name="password"
                  type="password"
                  @keyup.enter="validateUser"
                />
                <span
                  v-if="!$v.user.password.required"
                  class="md-error"
                >The password is required</span>
              </md-field>
            </div>
          </form>
        </md-card-content>

        <md-card-actions>
          <generic-button
            variation="red"
            @click="validateUser"
          >
            Login
          </generic-button>
        </md-card-actions>
      </md-card>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import GenericButton from "../../shared/buttons/GenericButton";
import { validationBaseMixin } from '../../mixins/ValidationBaseMixin'
import { email, required, } from 'vuelidate/lib/validators'
import router from '../../router/router'

export default {
  components: {
    GenericButton
  },
  mixins: [validationBaseMixin],
  data: () => {
    return {
      user: {
        email: null,
        password: null,
      },
    }
  },
  validations: {
    user: {
      password: {
        required
      },
    email: {
      required,
        email
      }
    }
  },
  computed: {
    ...mapState('account', ['loginError', 'needsActivation'])
  },
  methods: {
    ...mapActions('account', ['handleLogin']),

    validateUser () {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        this.login()
      }
    },

    async login () {
      try {
        const response = await this.handleLogin({username: this.user.email, password: this.user.password})

        if (!response.data) {
          await router.push({name: 'activate', query: {email: this.user.email}});
        }

      } catch (error) {
        throw error
      }
    }

  }
}
</script>
<style lang="scss" scoped>
  .login-header {
    margin-bottom: 100px;
  }

  .md-title {
    text-align: center;
  }

  .md-card {
    width: 370px;
    margin-top: 40px;
    padding: 15px;
  }

  .md-card-content {
    padding: 8px;
  }

  .c-input {
     button {
      transform: translate(0px, -50%);
     }
  }

  .md-error {
    color: #ff1744;
    text-align: center;
    margin: 10px 0 0 0;
    font-size: 16px;
  }
</style>
