<template>
  <base-modal-component>
    <template v-slot:header>
      <span class="md-subheading u-typography-color">
        Are you sure you want to update choosen version?
      </span>
    </template>

    <template v-slot:footer>
      <div class="u-mr-xxs">
        <generic-button
          variation="grey"
          @click="handleClose()"
        >
          Cancel
        </generic-button>
      </div>
      <div class="u-mr-xxs">
        <generic-button
          variation="grey"
          @click="handleUpdate('regular')"
        >
          Update
        </generic-button>
      </div>
      <div class="u-mr-xxs">
        <generic-button
          variation="red"
          @click="handleUpdate('mandatory')"
        >
          Mandatory Update
        </generic-button>
      </div>
      <generic-button
        variation="red"
        @click="handleUpdate('mandatory-blocking')"
      >
        Mandatory Blocking Update
      </generic-button>
    </template>
  </base-modal-component>
</template>

<script>

import BaseModalComponent from '../../../../shared/modal/BaseModalComponent';
import GenericButton from "../../../../shared/buttons/GenericButton";

export default {
  components: {
    BaseModalComponent,
    GenericButton
  },
  props: {
    onUpdate: {
      required: true,
      type: Function
    },
    onMandatoryUpdate: {
      required: true,
      type: Function
    },
    onMandatoryBlockingUpdate: {
      required: true,
      type: Function
    },
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    handleUpdate(mandatory = '') {
      this.$emit('close');
      switch(mandatory) {
        case 'mandatory': 
          this.onMandatoryUpdate();
          break;
        case 'mandatory-blocking':
          this.onMandatoryBlockingUpdate();
          break;
        default:
          this.onUpdate();
          break;
      }
    },
  },
};
</script>
