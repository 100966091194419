import router from '../../router/router';

function getInitialState() {
  return {
    error: null,
    success: null,
    globalError: null,
    isSuccess: false,
  }
}

const state = getInitialState()

const actions = {
  handleSuccess({ commit }, data) {
    commit('SUCCESS', data)
  },
  handleError({ commit }, data) {
    commit('ERROR', data)
  },
  setGlobalError({ commit }, data = null) {
    commit('GLOBAL_ERROR', data)
  },
  resetSuccess({ commit }) {
    commit('RESET_SUCCESS')
  },
  resetError({ commit }) {
    commit('RESET_ERROR')
  }
}

const getters = {
  success: state => state.success,
  isSuccess: state => !!state.success,
  error: state => state.error,
}

const mutations = {
  SUCCESS (state, data) {
    state.error = null;
    if (data.message) {
      state.success = data.message;
      state.isSuccess = !!data.message;
    }
    if (data.url) {
      router.push({ name: data.url });
    }
  },
  ERROR (state, data) {
    state.error = data;
  },
  RESET_SUCCESS (state) {
    state.success = null;
    state.isSuccess = false;
  },
  RESET_ERROR (state) {
    state.error = null;
  },
  GLOBAL_ERROR(state, data) {
    state.globalError = data
  }
}

export const response = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
