<template>
  <div>
    <div class="c-form-wrapper--small u-overflow-auto">
      <div class="c-form">
        <div class="c-form__row">
          <div class="c-form-group u-flex-row-center">
            <div class="c-form-group__action">
              <span v-if="!allMarketLanguages.length">No languages found for this market.</span>
              <md-field class="c-select-field c-select">
                <label for="languages">Languages</label>
                <md-select
                  id="languages"
                  :value="selectedLanguages"
                  name="languages"
                  :disabled="!allMarketLanguages.length"
                  @md-selected="handleLanguageChange"
                >
                  <md-option
                    v-for="(lang, index) in allMarketLanguages"
                    :key="index"
                    :value="lang"
                  >
                    {{ lang }}
                  </md-option>
                </md-select>
              </md-field>

              <md-field v-if="choosenTicketTopSectionLanguage" class="c-select-field c-select">
                <label for="types">Type</label>
                <md-select
                  id="types"
                  :value="selectedSectionType"
                  name="types"
                  @md-selected="handleSectionTypeChange"
                >
                  <md-option
                    v-for="(sectionType, index) in allSectionTypes"
                    :key="index"
                    :value="sectionType.key"
                  >
                    {{ sectionType.name }}
                  </md-option>
                </md-select>
              </md-field>

              <span v-if="!choosenTicketTopSectionLanguage">Select languages to start editing.</span>
              <transition v-if="choosenTicketTopSectionLanguage">
                <div class="c-add-template">
                  <div class="c-add-template__ctn">
                    <div class="c-code-editor-group">
                      <div class="c-code-editor-wrapper u-mt-md">
                        <label>HTML code</label>
                        <prism-editor
                          v-model="topSection.html"
                          :highlight="highlighter"
                          class="c-code-editor c-code-editor--lg"
                        />
                      </div>
                      <div class="c-code-editor-wrapper u-mt-md">
                        <label>CSS code</label>
                        <prism-editor
                          v-model="topSection.css"
                          :highlight="highlighter"
                          class="c-code-editor c-code-editor--lg"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="u-flex-justify-end u-mr-md u-mb-md">
      <generic-button
        class="u-ml-auto"
        variation="red"
        :disabled="!hasWritePermision || !choosenTicketTopSectionLanguage"
        @click="updateCurrentTicketTopSection"
      >
        Update
      </generic-button>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from "vuex";
import GenericButton from "../../../shared/buttons/GenericButton";
import { PrismEditor } from "vue-prism-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import { hasWritePermission } from '../../../helpers/permission';
import ConfirmationModalComponent from '../../../shared/confirmation-modal/ConfirmationModalComponent';
import find from 'lodash/find';

export default {
  name: 'TicketTopSection',
  components: {
    GenericButton,
    PrismEditor
  },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      initTicketTopSectionHtml: null,
      ticketTopSectionHtml: null,
      allMarketLanguages: [],
      choosenTicketTopSectionLanguage: "",
      selectedType: "market",
      changedLanguage: null,
      selectedLanguages: '',
      previousLanguage: '',
      canHandleLanguageChange: true,
      initLanguageChanged: false,
      initTopSectionLanguageChanged: false,
      isTopSectionLanguageChanged: false,
      previousTopSectionLanguage: '',
      submitedChanges: false,
      hasWritePermision: false,
      selectedSectionType: 'default',
      allSectionTypes: [
        {
          name: "Default",
          key: "default",
        },
        {
          name: "Keno",
          key: "keno",
        },
        {
          name: "Lucky Six",
          key: "luck",
        },
      ],
      topSection: {
        html: '',
        css: ''
      },
    };
  },
  computed: {
    ...mapGetters("company", [
      "getDefaultMarketLanguage",
      "getAdditionalMarketLanguages",
      "getAllBetshopTicketTopSection",
      "getAllMarketTicketTopSection"
    ]),
  },
  async created() {
    this.allMarketLanguages = [
      this.getDefaultMarketLanguage,
      ...this.getAdditionalMarketLanguages,
    ];
    this.checkPermissions();
    await this.fetchAllSectionsData();
  },
  methods: {
    ...mapActions("company", [
      "saveMarketTicketTopSection",
      "saveBetshopTicketTopSection",
      "fetchAllBetshopTicketTopSection",
      "fetchAllMarketTicketTopSection"
    ]),
    ...mapActions("response", ["handleSuccess", "handleError"]),
    ...mapGetters('account', ['user', 'userHierarchyWriteableSections']),
    async updateCurrentTicketTopSection() {
      const dataForSubmitting = {
        language: this.selectedLanguages,
        ticketTopSection: this.topSection.html,
        ticketTopSectionCss: this.topSection.css,
        type: this.selectedSectionType
      };

      this.type === this.selectedType
        ? await this.saveMarketTicketTopSection({
            marketId: this.$route.params.marketId,
            ...dataForSubmitting,
          })
        : await this.saveBetshopTicketTopSection({
            betshopId: this.$route.params.betshopId,
            ...dataForSubmitting,
          });

      this.previousFooterLanguage = '';
      this.initFooterLanguageChanged = false;
      this.$emit('canChangeTab', false);
      await this.fetchAllSectionsData();
    },

    async handleLanguageChange(language) {
      if (!this.canHandleLanguageChange && this.previousLanguage !== this.changedLanguage) {
        return this.canHandleLanguageChange = true;
      }

      this.canHandleLanguageChange = false;
      this.changedLanguage = language;
      
      if (!this.initFooterLanguageChanged) {
        return this.handleChangeLanguage();
      }
      this.openConfirmationModal(this.handleChangeLanguage, this.onClose);
    },

    async handleSectionTypeChange(type) {
      this.selectedSectionType = type;

      this.type === this.selectedType
        ? await this.handleMarketSectionTypeChange(type)
        : await this.handleBetShopSectionTypeChange(type);
    },

    handleBetShopSectionTypeChange(type) {
      const findBetshopTicketTopSection = find(this.getAllBetshopTicketTopSection, { 
        betshopId: Number(this.$route.params.betshopId),
        language: this.selectedLanguages,
        type: type
      });

      this.updateCurrentEditor(findBetshopTicketTopSection);
    },

    handleMarketSectionTypeChange(type) {
      const findMarketTicketTopSection = find(this.getAllMarketTicketTopSection, { 
        marketId: Number(this.$route.params.marketId),
        language: this.selectedLanguages,
        type: type
      });

      this.updateCurrentEditor(findMarketTicketTopSection);
    },

    async fetchAllSectionsData() {
      this.type === 'betshop' ? 
        await this.fetchAllBetshopTicketTopSection({
          betshopId: this.$route.params.betshopId,
        }) : 
        await this.fetchAllMarketTicketTopSection({
          marketId: this.$route.params.marketId,
      });
    },

    async handleBetShopLanguageChange(language) {
      this.choosenTicketTopSectionLanguage = language;

      const findBetshopTicketTopSection = find(this.getAllBetshopTicketTopSection, { 
        betshopId: Number(this.$route.params.betshopId),
        language: language,
        type: this.selectedSectionType
      });

      this.updateCurrentEditor(findBetshopTicketTopSection);
    },

    async handleMarketLanguageChange(language) {
      this.choosenTicketTopSectionLanguage = language;

      const findMarketTicketTopSection = find(this.getAllMarketTicketTopSection, { 
        marketId: Number(this.$route.params.marketId),
        language: language,
        type: this.selectedSectionType
      });

      this.updateCurrentEditor(findMarketTicketTopSection);
    },

    updateCurrentEditor(ticketSectionData) {
      if (!ticketSectionData) {
        this.topSection.html = "";
        this.topSection.css = "";

        return;
      }

      this.topSection.html = ticketSectionData.ticketTopSection;
      this.topSection.css = ticketSectionData.ticketTopSectionCss;
    },

    async handleChangeLanguage() {
      this.selectedLanguages = this.changedLanguage;
      this.previousLanguage = this.changedLanguage;

      this.type === this.selectedType
        ? await this.handleMarketLanguageChange(this.changedLanguage)
        : await this.handleBetShopLanguageChange(this.changedLanguage);

      this.choosenTicketTopSectionLanguage = true;
      this.$emit('canChangeTab', true);
    },

    checkPermissions() {
      this.hasWritePermision = hasWritePermission(
        this.user(),
        this.userHierarchyWriteableSections(),
        `ticket-top-section-${this.type}`
      );
    },

    highlighter(code) {
      return highlight(code, languages.js);
    },

    openConfirmationModal(handleChangeLanguage, onClose) {
      this.$modal.show(
        ConfirmationModalComponent,
        {
          confirmationMsg: `
            Your changes will be lost,
            are you sure you want to continue?`,
          onAccept: handleChangeLanguage,
          onClose: onClose,
        },
        { height: "auto", scrollable: false, width: "400px" }
      )
    },
  },
}
</script>
