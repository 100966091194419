import { apiService } from '../api'
import { map } from 'lodash';

const ENDPOINTS = {
  CREATE: '/language/save',
  UPDATE: '/language/update',
  GET: '/language',
  DELETE: '/language',
  UPLOAD: '/import-csv'
}

class LanguageService {
  async create(data) {
    return await apiService.post(ENDPOINTS.CREATE, data)
  }

  async update(data) {
    return await apiService.post(ENDPOINTS.UPDATE, data)
  }

  async getAllWithPagination(page, limit) {
    return await apiService.get(`${ENDPOINTS.GET}?page=${page}&limit=${limit}`)
  }

  async getAllLanguages() {
    return await apiService.get(ENDPOINTS.GET)
  }

  async delete(id) {
    return await apiService.delete(`${ENDPOINTS.DELETE}/${id}`);
  }

  async uploadCSVFile(data, id) {
    return await apiService.upload(`${ENDPOINTS.UPLOAD}?id=${id}`, data);
  }

  formatDataForTable(response) {
    return map(response, data => {
      return {
        word: data,
        rowData: {
          name: data.name,
          code: data.code,
        }
      }
    });
  }
}

export const languageService = new LanguageService()
