<template>
  <base-modal-component>
    <template v-slot:header>
      <h4 class="md-title u-typography-color">
        {{ createOrUpdate }} Printer Info
      </h4>
    </template>

    <template v-slot:body>
      <p
        v-if="error && error.messages"
        class="md-error"
      >
        {{ error.messages }}
      </p>
      <form
        novalidate
        @submit.prevent="validateForm"
      >
        <md-field :class="getValidationClass($v.data.name)">
          <label>Name</label>
          <md-input
            v-model="data.name"
            v-focus
            type="name"
          />
          <span
            v-if="!$v.data.name.required"
            class="md-error"
          >The Name is required</span>
        </md-field>
        <error-response-messages field-name="name" />
        <md-field>
          <label for="windowsLink">Windows link</label>
          <md-input
            v-model="data.windowsLink"
            name="windowsLink"
            type="windowsLink"
          />
        </md-field>
        <error-response-messages field-name="windowsLink" />
        <md-field>
          <label for="linuxLink">Linux link</label>
          <md-input
            v-model="data.linuxLink"
            name="linuxLink"
            type="linuxLink"
          />
        </md-field>
        <error-response-messages field-name="linuxLink" />
        <md-field>
          <label for="iOSLink">iOS link</label>
          <md-input
            v-model="data.iOSLink"
            name="iOSLink"
            type="iOSLink"
          />
        </md-field>
        <error-response-messages field-name="iOSLink" />
        <md-field>
          <label>Setup script</label>
          <md-file :ref="data.setupScriptName" placeholder="Setup script url" @md-change="selectedFile($event, data)" />
        </md-field>
      </form>
    </template>
    <template v-slot:footer>
      <div class="u-mr-xs">
        <generic-button
          variation="grey"
          @click="close"
        >
          Close
        </generic-button>
      </div>
      <generic-button
        variation="red"
        @click="validateForm"
      >
        {{ createOrUpdate }}
      </generic-button>
    </template>
  </base-modal-component>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { validationBaseMixin } from '../../../../mixins/ValidationBaseMixin';
import GenericButton from "../../../../shared/buttons/GenericButton";
import ErrorResponseMessages from "../../../../shared/error-response-messages/ErrorResponseMessages";
import { responseService } from "../../../../services/response";
import BaseModalComponent from "../../../../shared/modal/BaseModalComponent";
import { required } from "vuelidate/lib/validators";
import { appManagerService } from '../../../../services/app-manager';

export default {
  name: 'PrinterInfoModal',
  components: {
    GenericButton,
    ErrorResponseMessages,
    BaseModalComponent
  },
  mixins: [validationBaseMixin],
  props: {
    fetchPrinters: {
      type: Function,
      required: true
    },
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters("response", ["success", "error"]),

    createOrUpdate() {
      return !this.data.id ? 'Create' : 'Update';
    },
  },
  validations: {
    data: {
      name: {
        required
      }
    }
  },
  methods: {
    ...mapActions("response", ["handleSuccess", "handleError", "resetError"]),

    async createOrUpdatePrintInfo() {
      try {
        this.prepareData();
        await this.updateOrCreate();
        this.resetError();
        this.handleSuccess({ message: `Printer info ${this.createOrUpdate}d!` });
        this.fetchPrinters()
        this.close();
      } catch (err) {
        this.handleError(responseService.getErrorMessage(err.data.message));
      }
    },

    selectedFile(file, data) {
      data.setupScriptName = file;
    },

    async updateOrCreate() {
      return this.data.id ?
        await this.updateDriverInfo():
        await this.createDriverInfo();
    },

    async updateDriverInfo() {
      const formData = new FormData();
      formData.append('setupScriptName', this.data.setupScriptName[0]);
      await appManagerService.uploadScript(formData);

      await appManagerService.update({
        ...this.data,
        setupScriptName: this.data.setupScriptName[0].name
      });
    },

    async createDriverInfo() {
      const formData = new FormData();
      formData.append('setupScriptName', this.data.setupScriptName[0]);
      await appManagerService.uploadScript(formData);

      await appManagerService.create({
        ...this.data,
        setupScriptName: this.data.setupScriptName[0].name
      });

    },

    close() {
      this.$emit("close");
      this.resetError();
    },

    validateForm() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
       this.createOrUpdatePrintInfo();
      }
    },

    prepareData() {
      if (this.data.rowData) {
        delete this.data.rowData;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>