import { shiftService } from '../../services/shift/index';

function getInitialState() {
  return {
    shiftKeys: [],
    shiftKeysPaginationData: {}
  };
}

const state = getInitialState();

const actions = {
  async fetchAllShiftKeys({ commit, dispatch }, { currentPage, limit, type }) {
    try {
      dispatch('response/setGlobalError', null, { root: true });
      const savedShiftKeys = await shiftService.fetchAllShiftKeys(currentPage, limit, type)
      commit('SET_SHIFT_KEYS',
      {
        shiftKeys: savedShiftKeys.data.items,
        paginationData: {
          totalItems: savedShiftKeys.data.totalItems,
          pageCount: savedShiftKeys.data.pageCount
        }
      }
    )
    } catch (error) {
      dispatch('response/setGlobalError', 'Problem with WSBO', { root: true });
    }
  },
};

const getters = {
  getAllShiftKeys: state => state.shiftKeys,
  getShiftKeysPaginationData: state => state.shiftKeysPaginationData,
};

const mutations = {
  SET_SHIFT_KEYS(state, data) {
    state.shiftKeys = data.shiftKeys
    state.shiftKeysPaginationData = data.paginationData
  },
};

export const shift = {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
