<template>
  <div>
    <div class="c-form-wrapper u-overflow-auto">
      <form class="c-form">
        <div>
          <div class="c-form__avatar-wrapper u-mb-lg">
            <div>
              <div class="c-form__betshop-avatar">
                <div :class="['c-avatar', { 'u-pointer-none': !hasWritePermision }]">
                  <img
                    v-if="marketLogoUrl || previewLogoUrl"
                    class="c-avatar__img"
                    :src="getLogoImgSrc"
                    alt="betshop-logo"
                  >
                  <input type="file" :disabled="!hasWritePermision" class="c-avatar__input" @change="handleMarketLogoChange($event.target.files)">
                  <generic-button
                    class="c-avatar__button"
                    variation="red"
                    :disabled="!hasWritePermision || !logoToUpload"
                    @click="handleMarketLogoUpdate"
                  >
                    Upload
                  </generic-button>
                </div>
              </div>
            </div>
          </div>
          <div
            v-for="configuration in generalSettingsConfiguration"
            :key="configuration.id"
            class="c-form__row"
          >
            <generic-form-group v-if="configuration.section === 'betshop' || configuration.section === 'pages'" :label="configuration.label">
              <multiple-selections
                v-if="configuration.type === 'multiple'"
                :id="configuration.label"
                :disabled="!hasWritePermision"
                :label="allPagesEnabled(configuration) ? 'All pages are enabled.' : ''"
                :name="configuration.label"
                :selected-text="configuration.label"
                :selection-data="configuration.values"
                :default-value="apiSelectedConfigurationsData[findIndexByKey(configuration)] ? apiSelectedConfigurationsData[findIndexByKey(configuration)].value : configuration.defaultValue"
                @clicked="(value) => updateArrayConfigValues(apiSelectedConfigurationsData[findIndexByKey(configuration)], value)"
              />
              <md-field
                v-if="configuration.type === 'input'"
                class="c-input c-input--icon"
              >
                <md-input
                  v-model="apiSelectedConfigurationsData[findIndexByKey(configuration)].value"
                  :name="configuration.label"
                  :type="`${configuration.defaultValue}`"
                  :disabled="!hasWritePermision"
                />
              </md-field>
              <div
                v-if="configuration.type === 'checkbox'"
                class="c-input c-input--icon"
              >
                <md-checkbox 
                  v-model="apiSelectedConfigurationsData[findIndexByKey(configuration)].value" 
                  class="c-form-checkbox__checkmark u-margin-top-20"
                />
              </div>
            </generic-form-group>
          </div>
        </div>
      </form>
    </div>
    <div class="u-flex-justify-end u-mr-md u-mb-md">
      <generic-button
        variation="red"
        :disabled="!hasWritePermision"
        @click="updateSettings"
      >
        Update
      </generic-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import get from 'lodash/get';

import MultipleSelections from "../../../shared/multiple-selection/MultipleSelection";
import GenericFormGroup from '../../../shared/form-group/GenericFormGroup';
import { configurationsService } from '../../../services/configurations';
import GenericButton from '../../../shared/buttons/GenericButton';
import config from '../../../../config/local';
import { companyService } from '../../../services/company';
import { checkFileExtensions } from '../../../const/validator';
import { logoFileExtensions } from '../../../const/index';
import { hasWritePermission } from '../../../helpers/permission';
import isEmpty from 'lodash/isEmpty';

export default {
  name: 'PageConfigurations',

  components: {
    MultipleSelections,
    GenericFormGroup,
    GenericButton
  },

  props: {
    apiConfigurationsData: {
      type: Array,
      default: () => ([])
    },
    apiSelectedConfigurationsData: {
      type: Array,
      default: () => ([])
    }
  },

  data() {
    return {
      configurations: this.apiSelectedConfigurationsData,
      previewLogoUrl: '',
      logoToUpload: null,
      logoUploadFileExtensions: logoFileExtensions,
      logoFileError: null,
      hasWritePermision: false
    }
  },

  computed: {
    ...mapGetters('company', ['getMarketLogo']),
    marketLogoUrl() {
      const logoUrl = get(this.getMarketLogo, 'url');

      return logoUrl && `${config.BASE_URL}/${logoUrl}`;
    },

    getLogoImgSrc() {
      return this.previewLogoUrl ? this.previewLogoUrl : this.marketLogoUrl
    },

    generalSettingsConfiguration() {
      return this.apiConfigurationsData.filter(conf => conf.section === 'betshop' || conf.section === 'pages')
    }
  },

  created() {
    this.getMarketLogoFromBe(this.$route.params.marketId);
    this.checkPermissions();
  },

  methods: {
    ...mapActions('response', ['handleSuccess', 'handleError', 'setGlobalError']),
    ...mapActions('company', ['getMarketLogoFromBe']),
    ...mapGetters('account', ['user', 'userHierarchyWriteableSections']),

    updateArrayConfigValues(array, value) {
      array.value = value;
    },

    async updateSettings() {
      await configurationsService.updateMultipleMarketConfigurations(
        get(this.$route, 'params.marketId'),
        this.configurations.map((config) => ({
          ...config,
          value: this.handleValueFormat(config)
        }))
      );
      this.$emit('canChangeTab', false);
      this.handleSuccess({ message: 'Successfuly updated configurations!' });
    },

    handleValueFormat(config) {
      switch (config.key) {
        case 'delete_interval_licence_key':
          return get(config, 'value');
      
        default:
          return JSON.stringify(get(config, 'value'))
      }
    },

    handleMarketLogoChange(files) {
      this.validateLogoFile(files.item(0));

      if (this.logoFileError) {
        this.setGlobalError('Only image files are alowed.');
        return;
      }
      
      this.logoToUpload = files.item(0);
      const reader = new FileReader();
      reader.readAsDataURL(this.logoToUpload);
      reader.onload = (event) => (this.previewLogoUrl = event.target.result);
      this.$emit('canChangeTab', true);
    },

    async handleMarketLogoUpdate() {
      const formData = new FormData();
      formData.append('file', this.logoToUpload, this.logoToUpload.name);

      try {
        await companyService.saveMarketLogo(
          this.$route.params.marketId,
          formData
        );
        this.handleSuccess({ message: 'Market logo uploaded successfully.' });
        this.getMarketLogoFromBe(this.$route.params.marketId);
        this.logoToUpload = null;
      } catch (error) {
        this.handleError(error.data.message);
      }
      this.$emit('canChangeTab', false);
    },

    validateLogoFile(value) {
      this.logoFileError = checkFileExtensions(
        value,
        this.logoUploadFileExtensions
      );
    },

    findIndexByKey(configuration) {
      return this.configurations.findIndex(
        (config) => config.configurationId === configuration.id
      );
    },

    allPagesEnabled(configuration) {
      return isEmpty(this.apiSelectedConfigurationsData[this.findIndexByKey(configuration)].value);
    },

    checkPermissions() {
      this.hasWritePermision = hasWritePermission(
        this.user(),
        this.userHierarchyWriteableSections(),
        'settings-market'
      );
    }
  },
};
</script>
