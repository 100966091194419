<template>
  <div>
    <div class="c-form-wrapper--small">
      <div class="c-form">
        <div v-if="selectedConfigurationData.length" class="c-form__row">
          <generic-form-group 
            :label="wingoPreviousDrawingsTemplateConfiguration.label"
            :disabled="!hasWritePermision || !selectedConfigurationData[findIndexByKey(wingoPreviousDrawingsTemplateConfiguration)].enabled && type !== selectedType"  
          >
            <span v-if="!previousDrawingsTemplates.length">No templates found, in order to choose template, first create one.</span>
            <md-field class="c-select-field c-select">
              <label>{{ selectedTemplateId ? '' : wingoPreviousDrawingsTemplateConfiguration.label }}</label>
              <md-select 
                v-model="selectedTemplateId" 
                :disabled="!previousDrawingsTemplates.length"
                @md-selected="onChangeSectionOrTamplate"
              >
                <md-option
                  v-for="previousDrawingsTemplate in previousDrawingsTemplates"
                  :key="previousDrawingsTemplate.id"
                  :value="previousDrawingsTemplate.id"
                  @md-selected="onChangeSectionOrTamplate"
                >
                  {{ previousDrawingsTemplate.name }}
                </md-option>
              </md-select>
            </md-field>
          </generic-form-group>
          <div v-if="type !== selectedType" class="c-form__checkbox">
            <md-checkbox v-model="selectedConfigurationData[findIndexByKey(wingoPreviousDrawingsTemplateConfiguration)].enabled" :disabled="!hasWritePermision" class="c-form-checkbox__checkmark" /><span>Enable Configuration </span>
          </div>
        </div>
      </div>
    </div>
    <div class="u-flex-justify-end u-mr-md u-mb-md">
      <div class="u-mr-md">
        <generic-button variation="red" :disabled="!hasWritePermision" @click="updateSettings(true)">
          Clear All
        </generic-button>
      </div>
      <generic-button variation="red" :disabled="!hasWritePermision" @click="updateSettings">
        Update
      </generic-button>
    </div>
  </div>
</template>
  <script>
  import { mapActions, mapGetters } from "vuex";
  import get from "lodash/get";
  import findIndex from 'lodash/findIndex';
  import cloneDeep from 'lodash/cloneDeep';
  import GenericFormGroup from "../../../shared/form-group/GenericFormGroup";
  import { configurationsService } from "../../../services/configurations";
  import { wingoPreviousDrawingsTemplateService } from "../../../services/betshop/wingo-previous-drawings-template/index";
  import GenericButton from "../../../shared/buttons/GenericButton";
  import { hasWritePermission } from '../../../helpers/permission';
  import { PAGE_INFO } from '../../../const/pagination';
  
  export default {
    name: "WingoPreviousDrawingsPrintSettings",
    components: {
      GenericButton,
      GenericFormGroup,
    },
  
    props: {
      type: {
        type: String,
        required: true,
      },
    },
  
    data() {
      return {
        selectedType: "market",
        previousDrawingsTemplates: [],
        selectedTemplateId: null,
        clearAll: false,
        hasWritePermision: false,
        selectedConfigurationData: [],
        paginationData: {
          limitPerPage: 999,
          currentPage: PAGE_INFO.INITIAL_PAGE,
        },
      };
    },
  
    computed: {
      ...mapGetters("company", [
        "getApiConfigurationsData",
        "getApiMarketConfigurationsData",
        "getApiBetshopConfigurationsData",
      ]),
  
      getSelectedTemplateId() {

        const index = findIndex(this.selectedConfigurationData, { key: 'wingo_previous_drawings_template'} );

        return this.selectedConfigurationData[index].value;
      },
  
      wingoPreviousDrawingsTemplateConfiguration() {
        const index = findIndex(this.getApiConfigurationsData, { key: 'wingo_previous_drawings_template' });
  
        return this.getApiConfigurationsData[index]
      },
  
    },
  
    async created() {
      this.setConfigurations();
      this.selectedTemplateId = this.getSelectedTemplateId;
      this.fetchPreviousDrawingsTemplates();
      this.checkPermissions();
    },
  
    methods: {
      ...mapActions("response", [
        "handleSuccess",
        "handleError",
        "setGlobalError",
      ]),
      ...mapActions("company", [
        "updateMarketConfigurations",
        "updateBetshopConfigurations",
      ]),
      ...mapGetters('account', ['user', 'userHierarchyWriteableSections']),
  
      async updateSettings(clearAll) {
        if (clearAll) {
          this.selectedTemplateId = null;
          this.clearAll = true;
        }
  
        this.type === this.selectedType
          ? await this.updateMarket()
          : await this.updateBetshop();
        this.handleSuccess({ message: "Successfuly updated configurations!" });
        this.clearAll = false;
        this.$emit('canChangeTab', false);
      },
  
      async updateMarket() {
        const { data } = await configurationsService.updateMultipleMarketConfigurations(
          get(this.$route, "params.marketId"),
          this.selectedConfigurationData.map((config) => ({
            ...config,
            value: this.prepareValues(config)
          }))
        );
        this.updateMarketConfigurations(data);
      },
  
      async updateBetshop() {
        const { data } = await configurationsService.updateMultipleBetshopConfigurations(
          get(this.$route, "params.betshopId"),
          this.selectedConfigurationData.map((config) => ({
            ...config,
            value: this.prepareValues(config)
          }))
        );
        this.updateBetshopConfigurations(data);
      },
  
      prepareValues(config) {
        if (config.key === 'wingo_previous_drawings_template' ) {
          return JSON.stringify(this.clearAll ? '' : this.selectedTemplateId);
        }
  
        return JSON.stringify(get(config, 'value'))
      },
  
      async fetchPreviousDrawingsTemplates() {
        try {
          const { data } = await wingoPreviousDrawingsTemplateService.getAllWingoPreviousDrawingsTemplates();
          this.previousDrawingsTemplates = data;
        } catch (error) {
          this.setGlobalError(get(error, "data.message"));
        }
      },

  
      findIndexByKey(configuration) {
        return this.selectedConfigurationData.findIndex(
          (config) => config.configurationId === configuration.id
        );
      },
  
      onChangeSectionOrTamplate() {
        this.$emit('canChangeTab', true);
      },
  
      checkPermissions() {
        this.hasWritePermision = hasWritePermission(
          this.user(),
          this.userHierarchyWriteableSections(),
          `wingo-previous-drawings-template-${this.type}`
        );
      },
  
      setConfigurations() {
        this.selectedConfigurationData = 
          this.type === this.selectedType ? 
            cloneDeep(this.getApiMarketConfigurationsData) : 
            cloneDeep(this.getApiBetshopConfigurationsData);
      },
    },
  };
  </script>
  