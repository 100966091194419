<template>
  <div class="l-main">
    <div class="l-main__header u-box">
      <ul class="c-breadcrumbs">
        <li class="c-breadcrumbs__item">
          <a class="c-breadcrumbs__link">
            <md-icon>home</md-icon>
          </a>
        </li>
        <li class="c-breadcrumbs__item is-current">
          <a class="c-breadcrumbs__link">Wingo Settings</a>
        </li>
      </ul>
    </div>
    <div class="l-main__ctn u-box">
      <div class="c-table-wrapper">
        <form
          novalidate
          @submit.prevent="validateForm"
        >
          <md-field :class="getValidationClass($v.wingoApiUrl)">
            <label>Api Url</label>
            <md-input
              v-model="wingoApiUrl"
              :disabled="!canUserEdit"
              name="wingoApiUrl"
              type="wingoApiUrl"
            />
            <span
              v-if="!$v.wingoApiUrl.required"
              class="md-error"
            >Wingo api url is required</span>
          </md-field>

          <md-field :class="getValidationClass($v.wingoApiKey)">
            <label>Wingo Api Key</label>
            <md-input
              v-model="wingoApiKey"
              :disabled="!canUserEdit"
              name="wingoApiKey"
              type="wingoApiKey"
            />
            <span
              v-if="!$v.wingoApiKey.required"
              class="md-error"
            >Wingo Api Key is required</span>
          </md-field>
        </form>
      </div>

      <div class="u-flex-justify-end u-mr-md u-mb-md">
        <div class="u-mr-md">
          <generic-button
            v-if="canUserEdit"
            variation="red"
            @click="validateForm"
          >
            Save
          </generic-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import GenericButton from '../../../shared/buttons/GenericButton';
import { wingoSettingsService } from '../../../services/wingo-settings';
import { validationBaseMixin } from '../../../mixins/ValidationBaseMixin';
import { required } from "vuelidate/lib/validators";

export default {
  name: "WingoCouponsSettings",
  
  components: {
    GenericButton,
  },

  mixins: [validationBaseMixin],

  data() {
    return {
      wingoApiUrl: '',
      wingoApiKey: '',
    };
  },

  validations: {
    wingoApiUrl: {
      required
    },
    wingoApiKey: {
      required,
    },
  },

  computed: {
    ...mapGetters('wingoSettings', ['getApiKey', 'getApiUrl']),
    ...mapGetters('account', ['userWriteableSections', ]),

    canUserEdit() {
      return this.userWriteableSections.includes('wingo-coupons-template');
    }
  },

  created() {
    this.fetchWingoSettings().then(() => {
      this.wingoApiUrl = this.getApiKey;
      this.wingoApiKey = this.getApiUrl;
    });
  },

  methods: {
    ...mapActions("response", ["handleSuccess", "setGlobalError"]),
    ...mapActions("wingoSettings", ["fetchWingoSettings"]),

    validateForm() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
       this.save();
      }
    },

    async updateOrCreate() {
      return await wingoSettingsService.create({
        wingoApiUrl: this.wingoApiUrl,
        wingoApiKey: this.wingoApiKey,
      });
    },

    async save() {
       try {
        await this.updateOrCreate();
        this.handleSuccess({ message: `HQ settings updated!` });
      } catch (err) {
        this.setGlobalError(err.data.message);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
</style>